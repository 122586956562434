@import 'variable.scss';

// /* export with hide possible coverage*/
.pdf-bg {
  background-color: #f0f2f5;
}
// For Normal PDF
.k-pdf-export {
  //background-color: #f0f2f5;
  background-color: #fff;
  .valuation-needed-icon {
    font-size: 18px;
  }

  .ant-table-column-sorter {
    display: none;
  }

  .boldtext span,
  .btext {
    font: 700 15px/18px 'Arial', sans-serif !important;
    font-weight: 700 !important;
  }

  .boldtext div,
  .ant-table-row-level-0 {
    .cvalue span {
      font: 700 15px/18px 'Arial' !important;
      font-weight: 700 !important;
    }
  }

  .our-team-member {
    .pdf-matter-tbl {
      .ant-table-thead tr th.ant-table-cell,
      .ant-table-tbody tr td {
        position: static !important;
        width: 20% !important;
        max-width: 350px !important;
        min-width: 20% !important;
        color: #373c5d !important;

        .ant-table-column-sorter {
          display: none !important;
        }
        &:first-child {
          padding-left: 24px !important;
        }

        &:first-child,
        &:last-child {
          width: 20% !important;
          max-width: 350px !important;
          min-width: 20% !important;
        }
      }
    }

    .ant-table-thead {
      tr th span {
        font-weight: 700 !important;
      }

      tr th:last-child {
        span {
          padding-left: 20px !important;
        }
      }
    }
  }

  .matter-contact {
    .ant-table-thead tr th,
    .ant-table-tbody tr td {
      position: static !important;
      width: 14% !important;
      max-width: 14% !important;
      min-width: 14% !important;

      .ant-table-column-sorter,
      .ant-table-cell-scrollbar {
        display: none !important;
      }

      &:first-child {
        padding-left: 24px !important;
      }

      &:first-child,
      &:last-child {
        width: 14% !important;
        max-width: 14% !important;
        min-width: 14% !important;
      }
    }

    .ant-table-tbody tr td {
      &:last-child {
        padding-left: 10px !important;
      }
    }
  }

  body {
    *,
    table,
    td,
    tr {
      // font: 400 15px/18px 'Arial' !important;
      font-family: 'Arial', sans-serif !important;
    }
  }

  h1 {
    font: bold 28px/30px 'Arial';
    font-weight: 700 !important;
    font-size: 28px;
    line-height: 30px;
  }

  h2 {
    font: bold 24px/26px 'Arial';
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
  }

  h3 {
    font: bold 22px/24px 'Arial';
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
  }

  h4 {
    font: 500 15px/18px 'Arial';
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }

  h5 {
    font: 300 14px/18px 'Arial';
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }

  .kp-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .kpl-20 {
    padding-left: 20px !important;
  }

  .kmt-0 {
    margin-top: 0 !important;
  }

  .card-tbl {
    .ellipsis-text {
      width: 100%;
      display: inline-block;
      overflow: visible;
      text-overflow: clip;
      white-space: normal !important;
      word-break: break-word;
    }
  }

  .txt-right,
  .edetail,
  .damages-tbl .ant-table-tbody > tr > td.cvalue,
  .tfoot-cell span,
  .ant-table-thead .ant-table-cell.cvalue {
    text-align: left !important;
  }

  .labels .leg-val {
    text-align: left;
  }

  .tfoot-cell span {
    text-align: left;
    float: left;
    width: 100%;
  }

  .flex-end {
    justify-content: flex-start !important;
  }

  .pdfshow {
    display: block;
  }

  .tblrow {
    margin-bottom: 0 !important;
  }

  .liens-table .ant-table-expanded-row p {
    font: 500 14px/16px 'Arial';
  }

  .syz-head-text.normal-text {
    display: flex;
    justify-content: flex-start !important;
  }

  .quick-links {
    height: auto;
    min-height: min-content;
  }

  .liens-pdf {
    .ant-card-body {
      padding: 10px 24px;
    }

    .cellhead {
      max-width: max-content;
      padding-right: 20px;
    }

    .cellprice {
      .plain-input {
        height: 30px;
        line-height: 30px;
      }
    }

    .card-tbl {
      padding: 0;
      margin: 0;
    }
  }

  .settle-neg {
    .ad-control {
      &:first-of-type {
        padding-left: 0;
      }
    }

    .form-group {
      .lbl-form {
        label {
          font: 500 15px/16px 'Arial' !important;
        }
      }
    }
  }

  label,
  .lg-tooltip span,
  .chead {
    font: 400 13px/16px 'Arial' !important;
  }

  .pmt {
    margin: 0;
  }

  .ppt {
    padding: 0;
  }

  .pmlr {
    margin-left: -25px;
    margin-right: -25px;
  }

  .half-card-row.masonry {
    .scard {
      .emptydata {
        height: 50px;
      }
    }
  }

  .card-block {
    .card-col {
      .emptydata {
        margin: 90px 0;
      }
    }
  }

  .crow {
    .absolute-ccell {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .cellprice {
    .ellipsis-text {
      text-overflow: inherit !important;
      word-break: break-all !important;
      white-space: normal !important;
    }
  }

  .totality {
    .cellprice {
      .ellipsis-text {
        text-overflow: inherit !important;
        word-break: break-all !important;
        white-space: normal !important;
      }

      .fa-equals {
        color: #fff !important;
      }

      .fico {
        font: 600 8px;
      }
    }

    .cellhead,
    .cellprice > span {
      font: 700 15px/18px 'Arial';
      font-weight: 700 !important;
    }
  }

  .ant-table-expanded-row-level-1 {
    .cvalue span {
      font: 500 15px/18px 'Arial' !important;
      font-weight: 500 !important;
    }
  }

  .cellhead,
  .cellprice {
    b,
    b span,
    strong {
      font: 700 15px/18px 'Arial' !important;
      font-weight: 700 !important;
    }
  }

  .ant-tabs-tab-active {
    border-bottom: 2px solid #5463d2;
  }

  .card-tbl .chead {
    border-bottom: 1px solid #e7e8eb;
    padding-bottom: 10px;
  }

  .btxt,
  .ant-card-head-title .cheading span,
  ul li,
  .my-anchor-css-class,
  sup,
  .page-heaing small,
  .ddown.sm-ddown .ant-select,
  label,
  td,
  table {
    font: 13px/16px 'Arial';
  }

  table tr th {
    font: 600 15px/16px 'Arial' !important;
  }

  .grey-box {
    span {
      color: #11173ebd;
      font: 13px/16px 'Arial';
    }

    h3 {
      span {
        font: bold 20px/22px 'Arial';
      }
    }
  }

  .closing-info {
    .scard {
      flex: 1;
    }

    .hleftt .etitle {
      max-width: 170px !important;
      width: 170px !important;
    }

    &.hcard {
      .espan {
        display: inline-block;
        vertical-align: middle;
        height: 45px;
      }
    }

    .edetail {
      max-width: max-content !important;
      width: auto !important;

      &.eflex {
        max-width: max-content !important;
        width: calc(100% - 180px) !important;
        display: inline-block;
      }
    }

    .scard {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .fa-info-circle {
    font: bold 11px/14px;
  }

  .claimdetails {
    small {
      font-size: 13px;
    }
  }

  .settlement {
    width: 100%;

    .legends {
      width: 100%;

      .labels {
        width: 100%;

        .lrow {
          width: 100%;
        }
      }
    }
  }

  .under-text {
    text-decoration: none;
  }

  .ltxt {
    padding-left: 3px;
  }

  p,
  span,
  a,
  div {
    font: 400 15px/22px 'Arial';
  }

  .white-box {
    > small {
      word-spacing: -1px;
    }
  }

  .claim-numbers {
    u {
      text-decoration: none !important;
    }

    .cellprice {
      div,
      a,
      u.normal-text {
        display: inline-block !important;
        line-height: 18px !important;
        padding-right: 0;

        u {
          text-decoration: none !important;
        }

        a {
          display: none !important;
        }
      }
    }
  }

  .claim-info {
    .card-tbl {
      .cellprice {
        height: 22px;
      }
    }
  }

  .notesscroll {
    &.scrollblock {
      overflow-y: visible !important;
      max-height: min-content !important;
    }

    .scrollbar-track {
      display: none !important;
    }

    .content-wrap {
      overflow-x: hidden;
      padding: 0 !important;

      p {
        width: 100% !important;
      }
    }
  }

  .grey-controls {
    .lbl-form {
      label {
        width: 200px;
      }
    }
  }

  .noExport,
  .ant-select-arrow {
    display: none !important;
    text-decoration: none;
  }

  .syz-head {
    &.normal-text {
      display: inline-block;
    }
  }

  .pdfcell {
    border-bottom: 2px solid $primary;
  }

  .cvalue {
    width: 140px;
    text-align: left;
  }

  .ant-table-cell {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
  }

  .pdf-select {
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    font: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding: 10px;
    background-color: #fafafb;
  }

  .info-header {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-tabs-nav {
    display: none;
    width: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
  }

  .ant-tabs-left > .ant-tabs-nav,
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-left > div > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    min-width: 0;
  }

  .page-tab-detail.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-right: 0px !important;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    display: none;
  }

  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 0;
  }

  .normal-text {
    display: block;

    &.flex {
      display: flex;
      justify-content: space-between;
    }
  }

  .settle-neg {
    .sm-scard {
      .ant-card-body {
        .crow {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .infotext {
    p {
      font: 500 12px/14px 'Arial' !important;
    }
  }

  .plain-select {
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none !important;
        background: transparent !important;
        padding: 0 !important;
        height: auto !important;
      }
    }

    span.ant-select-selection-placeholder {
      color: rgba(17, 23, 62, 0.84) !important;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      opacity: 1;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-select-selection-item {
      font: 600 16px/18px 'Arial' !important;
      height: 35px !important;
    }
  }

  .ltxt {
    font: 500 12px/15px 'Arial' !important;
  }

  .settlement .labels {
    margin-left: 15px;
  }

  .cellhead {
    color: #11173ebd;
    font: 300 14px/18px 'Arial' !important;
  }

  .cellprice {
    font: 600 14px/18px 'Arial' !important;
    color: rgba(17, 23, 62, 0.84);

    &.comma {
      font: 600 15px/18px 'Arial';
      display: inline-block;
      pointer-events: none;

      u {
        font: 600 15px/18px 'Arial';
        display: inline-block;
      }
    }
  }

  .claimdetails {
    p {
      font: 600 15px/18px 'Arial' !important;
    }
  }

  .white-box {
    h3 {
      span {
        font: 600 16px/20px 'Arial';
        font-weight: 600;
      }
    }
  }

  .lrow {
    p {
      font: 600 14px/18px 'Arial';
    }
  }

  .card-block {
    .card-sm-col {
      width: 320px;
      max-width: 320px;
      min-width: 320px;
    }

    .card-col {
      max-width: calc(100% - 340px);
      width: calc(100% - 340px);
    }
  }

  .pdf-view {
    padding: 0;

    .ant-card-body {
      padding: 0;
    }

    .dashtbl .ant-table-thead > tr > th:first-child,
    .dashtbl .ant-table-tbody > tr > td:first-child,
    .dashtbl .ant-table-thead > tr > th.address-cell,
    .dashtbl .ant-table-tbody > tr > td.address-cell {
      width: 150px !important;
      min-width: 150px !important;
    }

    .dashtbl .ant-table-thead > tr > th,
    .dashtbl .ant-table-tbody > tr > td {
      width: 100px;
      min-width: 100px !important;
    }

    .ant-avatar {
      min-width: 25px !important;
      max-width: 25px !important;
    }

    .ant-table-tbody > tr > td .avatar-info .ant-avatar {
      margin-right: 5px;
    }

    .dashtbl .ant-table-thead > tr > th {
      vertical-align: bottom;
    }

    .ellipsis-text {
      white-space: normal !important;
      word-wrap: break-word;
      word-break: break-word;
    }

    td.ant-table-cell.ant-table-cell-ellipsis div {
      white-space: normal;
    }

    .ant-table-body {
      overflow: visible !important;
      max-height: max-content !important;
    }
  }

  .date-paddingpdf {
    padding-left: 0 !important;
  }

  .disclaimer {
    h4 {
      font: 700 15px/18px 'Arial';
      font-weight: 700 !important;
    }

    span {
      font: 14px;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .pdftab {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e7e8eb;

    .pdfcell {
      width: 115px;
      background-color: rgba(243, 244, 252, 0.5);
      color: $primary;
      font-weight: 600;
      text-align: center;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        margin-bottom: 10px;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    display: none;
  }

  .card-tbl {
    padding: 0 25px 0px;

    .crow {
      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }

    .border-bottom {
      .crow {
        &:nth-child(8) {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  .hbox {
    padding: 15px;
    display: flex;
    margin-bottom: 20px;
  }

  .hrow {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    line-height: 15px;

    align-items: flex-start;
  }

  .edetail {
    font: 500 14px/25px 'Arial';
    display: block;
  }

  .hdays {
    border: none;

    .etitle {
      width: 100%;
    }

    .edetail,
    span {
      font: 600 16px/24px 'Arial';
    }
  }

  .hleftt {
    column-count: 2;

    .etitle {
      display: inline-block;
      vertical-align: middle;
    }

    .hrow {
      display: inline-block;
      width: 100%;
      vertical-align: middle;
      padding-bottom: 12px;
      margin-bottom: 12px;
      padding-left: 0;
      padding-right: 0;
    }

    .etitle {
      width: 170px;
      max-width: 170px;
      font-size: $font-size-base;
      margin-right: 20px;
      color: #11173ebd;
    }

    .edetail {
      text-align: left;
      display: inline-block;

      width: auto;
      max-width: auto !important;
      position: relative;
      left: 14px;
    }
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    width: 100%;
  }

  .setchart {
    canvas {
      width: 500px !important;
    }
  }

  .negtbl {
    tr.ant-table-row:nth-child(even) {
      background-color: #fafafb !important;
    }
  }

  .form-group {
    .overflow-multiselect {
      .ant-select-selector {
        height: auto !important;
      }

      .ant-select-selection-overflow {
        flex-wrap: wrap-reverse;
        overflow: visible;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-selector:after,
      .ant-select-selector:after {
        display: none;
      }
    }

    .ant-select-multiple {
      .ant-select-selection-item,
      .css-1rhbuit-multiValue,
      .tree-tag {
        border: none;
        border-radius: 0;
        background-color: transparent;
        color: rgba(17, 23, 62, 0.84);
        line-height: 20px !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      .ant-select-selection-overflow-item {
        height: auto;
        line-height: normal !important;

        .ant-select-selection-item-content {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            right: 7px;
            top: 0px;
          }
        }

        .ant-select-selection-item-content,
        .ant-tag {
          font: 500 15px/25px 'Arial';
        }
      }

      .ant-select-selection-item-remove {
        display: none;
      }
    }
  }

  a.sec-link,
  .sec-link {
    &.noExport,
    .noExport {
      display: none !important;
    }
  }

  .liens-table {
    &.budget-tbls {
      text-align: left !important;

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: left !important;
      }

      .ant-table-thead > tr > th {
        &.amounts-cell {
          width: 120px !important;
          max-width: 120px !important;
        }

        &.notes-cell {
          max-width: 400px !important;
          width: 400px !important;
        }
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        padding: 15px 10px !important;

        &:nth-child(2) {
          padding-left: 0 !important;
        }
      }

      .ant-table-expanded-row p {
        padding-left: 50px !important;
      }
    }

    .ant-table-thead > tr > th {
      &.amounts-cell {
        width: 120px !important;
        max-width: 120px !important;
      }

      &.notes-cell {
        max-width: 400px !important;
        width: 400px !important;
      }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 15px 10px !important;

      &:nth-child(2) {
        padding-left: 0 !important;
      }
    }

    .ant-table-expanded-row p {
      padding-left: 50px !important;
    }
  }

  .budget-valuation .liens-table.ECodeColumns .ant-table-expanded-row p {
    padding-left: 65px !important;
  }

  .liens-table.ECodeColumns + .add-claim-damage .drow .dcell:first-child {
    padding-left: 70px !important;
  }

  .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
    background-size: 10px !important;
  }

  /*settlement negotiation*/
  .settle-neg {
    .mt-25 {
      margin-top: 0;
    }
  }

  .negtbl {
    .up-downprice {
      text-align: left;
    }

    .prop-info {
      overflow: visible;
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
    }

    .ant-table-tbody > tr.ant-table-row td {
      color: rgba(17, 23, 62, 0.74);
    }

    .prop-info .comma > span,
    .comma > span {
      display: inline-block;
    }

    td {
      font: 400 13px/16px 'Arial';
    }
  }

  .percentage {
    canvas {
      margin: 0 auto;
    }
  }

  // tfoot.ant-table-summary {
  //   b {
  //     font-size: 12px;
  //     font-weight: bolder;
  //     font-family: 'ArialBold';
  //   }
  // }

  /*damages table*/
  .damages-tbl {
    .ant-table-tbody {
      .ant-table-tbody {
        tr {
          background: transparent !important;

          td {
            background: transparent !important;
          }
        }
      }

      tr.ant-table-row {
        &:nth-child(even) td {
          background: #fafafb !important;
        }

        td {
          background: #fff !important;
        }
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      &:first-child {
        display: none;
      }
      &:nth-child(2) {
        padding-left: 25px !important;
      }
    }
    .ant-table-tbody,
    .ant-table-thead,
    .ant-table-summary {
      > tr {
        > td.cvalue,
        th.cvalue,
        .tfoot-cell {
          padding-left: 5px !important;
        }
      }
    }

    .ant-table-summary {
      tr.normal-text {
        display: table-row;
      }
    }

    .tfoot-cell {
      h5 {
        span {
          font: 700 14px/20px 'Arial';
          color: $primary;
        }
      }

      span {
        font: 400 14px/20px 'Arial';
        color: #11173ebd;
      }
    }

    .tfoot-head {
      vertical-align: top;
      font: 600 15px/20px 'Arial';

      .lg-tooltip,
      br {
        display: none;
      }

      span {
        color: #282e51;
        font: 600 15px/20px 'Arial';
      }
    }
  }

  .tfoot-info {
    span {
      font: 600 15px/20px 'Arial';
      color: #282e51;
    }

    .ant-table-cell > div {
      @media (max-width: 1699px) {
        padding-left: 5px !important;
      }
    }

    p {
      font: 500 13px/16px 'Arial';
      padding-left: 35px;
    }
  }

  .fico {
    font: 500 8px/10px 'Arial';
  }

  .our-team-member {
    .ant-table {
      overflow-x: visible;
      background-color: #fff !important;
    }

    .ant-table-thead,
    .dashtbl {
      background-color: #fff !important;
    }

    .ant-table-body {
      overflow: visible !important;
      max-height: max-content !important;
    }
  }

  .pt--10 {
    margin-top: -15px !important;
  }

  .sm-tbl-pdf {
    padding: 0 !important;
  }

  .percentage {
    .chart-tooltip {
      text-align: center;
      justify-content: center;

      > div {
        position: relative;
      }
    }
  }

  .liability-analysis {
    .ant-card-body {
      padding-top: 10px;
    }
  }

  .info-header {
    .ant-tag {
      margin-left: 5px !important;
    }
  }

  .neg-summary .ellipsis-text {
    max-width: 100%;
    vertical-align: middle;
    width: auto;
    word-break: break-word;
    white-space: normal !important;
    overflow: visible;
  }
  .sm-empty-data {
    margin: 0 auto;

    .emptydata {
      margin: 50px 0 !important;
    }
  }

  .cheading {
    span {
      word-break: break-word !important;
      white-space: normal !important;
    }
  }

  .liens-summry-text-wrap {
    .cheading {
      span {
        word-break: break-word !important;
        white-space: normal !important;
      }

      h3 {
        span {
          word-break: break-word !important;
          white-space: break-spaces !important;
        }
      }
    }
  }

  .avatar-info {
    .avatar-img {
      img {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 25px;
      }
    }
  }

  // .damages-tbl .tfoot-head span {
  //   left: -60px;

  //   @media (max-width: 1699px) {
  //     left: -45px;
  //   }
  // }

  .matter-tbl {
    .dashtbl {
      .ant-table-header {
        overflow: visible !important;
        position: static !important;

        table {
          table-layout: auto !important;
          overflow: visible !important;

          .ant-table-cell.cvalue {
            text-align: left !important;
          }

          .tfoot-cell span {
            text-align: left;
            float: left;
            width: 100%;
          }
          tr th {
            position: static !important;
            width: 220px !important;
            max-width: 220px !important;
            min-width: 220px !important;
            color: #373c5d !important;
            font-weight: 700 !important;
            text-align: left;

            &:first-child {
              padding-left: 24px !important;
            }

            &:first-child,
            &:last-child {
              width: 220px !important;
              max-width: 220px !important;
              min-width: 220px !important;
            }
          }

          .ant-table-cell-fix-left,
          .ant-table-cell-fix-right {
            position: relative !important;
            left: auto !important;
          }

          .ant-table-cell-fix-left-first::after,
          .ant-table-cell-fix-left-last::after {
            display: none;
          }

          .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
            display: none !important;
          }
        }
      }
    }
  }

  .matter-tbl {
    .alert-count {
      small {
        white-space: nowrap;
      }
    }

    &.pdf-matter-tbl {
      opacity: 1 !important;
      height: auto;
      overflow: visible !important;
      position: static !important;
      pointer-events: all !important;
    }

    &.contacttbl {
      .ant-table-thead tr th.ant-table-cell,
      .ant-table-thead tr th,
      .ant-table-tbody tr td {
        position: static !important;
        width: 425px !important;
        max-width: 425px !important;
        min-width: 425px !important;
        .ant-table-column-sorter {
          display: none !important;
        }

        &:first-child {
          padding-left: 24px !important;
        }

        &:first-child,
        &:last-child {
          width: 425px !important;
          max-width: 425px !important;
          min-width: 425px !important;
        }
      }
    }

    .ant-table {
      overflow: visible !important;
    }

    .ant-table-header {
      overflow: visible !important;
      position: static !important;
    }

    .ant-table-thead tr th.ant-table-cell,
    .ant-table-thead tr th,
    .ant-table-tbody tr td {
      position: static !important;
      width: 220px !important;
      max-width: 220px !important;
      min-width: 220px !important;

      .ant-table-column-sorter {
        display: none !important;
      }

      &:first-child {
        padding-left: 24px !important;
      }

      &:first-child,
      &:last-child {
        width: 220px !important;
        max-width: 220px !important;
        min-width: 220px !important;
      }
    }

    .iGTbIm {
      .ant-table {
        overflow-x: visible;
      }
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      position: relative !important;
      left: auto !important;
    }

    .ant-table-cell-fix-left-first::after,
    .ant-table-cell-fix-left-last::after {
      display: none;
    }

    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
      display: none !important;
    }

    .iGTbIm .ant-table-thead > tr > th,
    .iGTbIm .ant-table-tbody > tr > td {
      overflow-wrap: break-word;
    }

    .ant-table-body {
      max-height: max-content !important;
      overflow: visible !important;
    }
  }
}

// For 'Letter' Size PDF
.pdf-expo {
  background-color: #fff !important;
  font-family: 'Arial' !important;
  .ql-size-small {
    font-size: 10px;
  }
  .ql-size-large {
    font-size: 16px;
  }
  .ql-size-huge {
    font-size: 20px;
  }
  h1 {
    &.mt-30 {
      margin-top: 15px !important;
    }
  }
  .chart-flex {
    display: block !important;
  }
  .scard {
    .ant-card {
      line-height: 16px !important;
      border: 1px solid #f0f0f0 !important;
      border-radius: 4px !important;
    }
  }

  .scroll-content {
    display: block !important;
    transform: initial;
  }

  .ant-card-head {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .half-card-row {
    &.hcard {
      .edetail {
        font: 600 12px/16px 'Arial';
      }
    }
  }
  .half-card-row.masonry {
    display: block;
    column-count: auto;
    .scard {
      display: block;
    }
  }
  .ant-tabs-content {
    display: block !important;
  }
  .scard {
    .ant-card-head-title {
      display: block !important;
    }
  }
  .ant-card-head-wrapper {
    display: block !important;
  }
  .ant-card-head-title {
    white-space: normal !important;
    overflow: visible !important;
    flex: auto;
    display: block !important;
  }
  .ant-card-extra {
    display: none !important;
  }

  .content-wrap {
    white-space: initial !important;
    word-wrap: normal !important;
  }
  .ant-card-body {
    padding: 12px 10px;
    height: auto !important;
    &:after,
    &:before {
      display: none;
    }
  }
  .valuation-needed-icon {
    font-size: 18px;
  }

  .ant-table-column-sorter {
    display: none;
  }

  // .special-notes {
  //   margin: 25px;
  //   // width: 1200px;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   padding-left: 15px;
  // }

  .boldtext span,
  .btext {
    font: 700 13px/18px 'Arial' !important;
    font-weight: 700 !important;
  }

  .boldtext div,
  .ant-table-row-level-0 {
    .cvalue span {
      font: 700 13px/18px 'Arial' !important;
      font-weight: 700 !important;
    }
  }

  .our-team-member {
    .pdf-matter-tbl {
      .ant-table-thead tr th.ant-table-cell,
      .ant-table-tbody tr td {
        position: static !important;
        width: 20% !important;
        max-width: 350px !important;
        min-width: 20% !important;
        color: #373c5d !important;

        .ant-table-column-sorter {
          display: none !important;
        }
        &:first-child {
          padding-left: 24px !important;
        }

        &:first-child,
        &:last-child {
          width: 20% !important;
          max-width: 350px !important;
          min-width: 20% !important;
        }
      }
    }

    .ant-table-thead {
      tr th span {
        font-weight: 700 !important;
      }

      tr th:last-child {
        span {
          padding-left: 20px !important;
        }
      }
    }
  }

  .matter-contact {
    .ant-table-thead tr th,
    .ant-table-tbody tr td {
      position: static !important;
      width: 14% !important;
      max-width: 14% !important;
      min-width: 14% !important;

      .ant-table-column-sorter,
      .ant-table-cell-scrollbar {
        display: none !important;
      }

      &:first-child {
        padding-left: 24px !important;
      }

      &:first-child,
      &:last-child {
        width: 14% !important;
        max-width: 14% !important;
        min-width: 14% !important;
      }
    }

    .ant-table-tbody tr td {
      &:last-child {
        padding-left: 10px !important;
      }
    }
  }

  body {
    *,
    table,
    td,
    tr {
      font-family: 'Arial' !important;
    }
  }

  h1 {
    // font: bold 28px/30px 'Arial', ;
    font: bold 18px/24px 'Arial';
  }

  h2 {
    font: bold 16px/26px 'Arial';
  }

  h3 {
    font: bold 14px/24px 'Arial';
  }

  h4 {
    font: 500 12px/18px 'Arial';
  }

  h5 {
    // font: 300 14px/18px 'Arial', ;
  }

  .kp-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .kpl-20 {
    padding-left: 20px !important;
  }

  .kmt-0 {
    margin-top: 0 !important;
  }

  .card-tbl {
    .ellipsis-text {
      width: 100%;
      display: inline-block;
      overflow: visible;
      text-overflow: clip;
      white-space: normal !important;
      word-break: break-word;
    }
  }

  .txt-right,
  .edetail,
  .damages-tbl .ant-table-tbody > tr > td.cvalue,
  .tfoot-cell span,
  .ant-table-thead .ant-table-cell.cvalue {
    text-align: left !important;
  }

  .labels .leg-val {
    text-align: left;
  }

  .tfoot-cell span {
    text-align: left;
    float: left;
    width: 100%;
  }

  .flex-end {
    justify-content: flex-start !important;
  }

  .pdfshow {
    display: block;
  }
  .ant-tag-closed {
    font-size: 10px !important;
    svg,
    i {
      font-size: 10px !important;
    }
  }
  .tblrow {
    margin-bottom: 0 !important;
    width: 100%;
    .tblcell {
      // padding-right: 0 !important;
      // margin-right: 8px !important;
      // width: 100%;
      &:after {
        top: 4px;
        transform: none !important;
      }
      span {
        font-size: 10px;
      }
      h5 {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .liens-table .ant-table-expanded-row p {
    font: 500 12px/16px 'Arial';
  }
  .block-row {
    padding: 0;
  }
  .syz-head-text.normal-text {
    display: flex;
    justify-content: flex-start !important;
  }

  .quick-links {
    height: auto;
    min-height: min-content;
  }

  .liens-pdf {
    .ant-card-body {
      padding: 10px 24px;
    }

    .cellhead {
      max-width: max-content;
      padding-right: 20px;
    }

    .cellprice {
      .plain-input {
        height: 30px;
        line-height: 30px;
      }
    }

    .card-tbl {
      padding: 0;
      margin: 0;
    }
  }

  .settle-neg {
    .ad-control {
      // min-width: 270px !important;
      // width: 270px !important;
      &:first-of-type {
        padding-left: 0;
      }
    }

    .form-group {
      .lbl-form {
        label {
          font: 500 13px/16px 'Arial' !important;
        }
      }
    }
  }

  label,
  .lg-tooltip span,
  .chead {
    font: 400 13px/16px 'Arial' !important;
  }

  .pmt {
    margin: 0;
  }

  .ppt {
    padding: 0;
  }

  .pmlr {
    margin-left: -25px;
    margin-right: -25px;
  }

  // .ant-table-column-sorter {
  //     display: none;
  // }
  .emptydata,
  .emptynodata {
    margin: 40px 0 !important;
    min-height: 20px !important;
    height: auto !important;
    h3 {
      color: $color-base;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .half-card-row.masonry {
    .scard {
      .emptydata {
        height: auto;
        margin: 0;
      }
    }
  }

  .card-block {
    .card-col {
      .emptydata {
        // margin: 90px 0;
      }
    }
  }

  .crow {
    padding-left: 0 !important;
    .absolute-ccell {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .cellprice {
    .ellipsis-text {
      text-overflow: inherit !important;
      word-break: break-all !important;
      white-space: normal !important;
    }
  }

  .totality {
    .cellprice {
      .ellipsis-text {
        text-overflow: inherit !important;
        word-break: break-all !important;
        white-space: normal !important;
      }

      .fa-equals {
        color: #fff !important;
      }

      .fico {
        font: 600 8px;
      }
    }

    .cellhead,
    .cellprice > span {
      font: 700 13px/18px 'Arial';
      font-weight: 700 !important;
    }
  }

  .ant-table-expanded-row-level-1 {
    .cvalue span {
      font: 500 13px/18px 'Arial' !important;
      font-weight: 500 !important;
    }
  }

  .cellhead,
  .cellprice {
    b,
    b span,
    strong {
      font: 700 13px/18px 'Arial' !important;
      font-weight: 700 !important;
    }
  }

  .ant-tabs-tab-active {
    border-bottom: 2px solid #5463d2;
  }

  .card-tbl .chead {
    border-bottom: 1px solid #e7e8eb;
    padding-bottom: 10px;
  }

  .btxt,
  .ant-card-head-title .cheading span,
  ul li,
  .my-anchor-css-class,
  sup,
  .page-heaing small,
  .ddown.sm-ddown .ant-select,
  label,
  td,
  table {
    font: 13px/16px 'Arial';
  }

  table tr th {
    font: 600 13px/16px 'Arial' !important;
  }

  .grey-box {
    span {
      color: #11173ebd;
      font: 11px/16px 'Arial';
    }

    h3 {
      font-size: 14px !important;
      font-family: 'Arial';
      span {
        font: bold 14px/22px 'Arial';
      }
    }
  }

  .closing-info {
    .scard {
      flex: 1;
    }

    .hleftt .etitle {
      max-width: 170px !important;
      width: 170px !important;
    }

    &.hcard {
      .espan {
        display: inline-block;
        vertical-align: middle;
        height: 45px;
        min-width: 80px;
        span {
          font-size: 10px !important;
        }
      }
    }

    .edetail {
      max-width: max-content !important;
      width: auto !important;

      &.eflex {
        max-width: max-content !important;
        width: calc(100% - 180px) !important;
        display: inline-block;
      }
    }

    .scard {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .fa-info-circle {
    font: bold 11px/14px;
  }

  .claimdetails {
    margin-bottom: 20px;
    .cardbody {
      &.mb-30 {
        margin-bottom: 0 !important;
      }
    }
    .card-tbl {
      margin-top: 10px !important;
    }
    small {
      font-size: 10px;
    }
  }

  .settlement {
    width: 100%;
    padding: 15px 10px;
    .legends {
      width: 100%;

      .labels {
        width: 100%;

        .lrow {
          width: 100%;
          padding: 8px 0;
        }
      }
    }
  }

  .under-text {
    text-decoration: none;
  }

  .ltxt {
    padding-left: 3px;
  }

  p,
  span,
  a,
  div {
    font: 400 12px/18px 'Arial';
  }

  .white-box {
    > small {
      word-spacing: -1px;
    }
  }

  .claim-numbers {
    u {
      text-decoration: none !important;
    }

    .cellprice {
      div,
      a,
      u.normal-text {
        display: inline-block !important;
        line-height: 18px !important;
        padding-right: 0;

        u {
          text-decoration: none !important;
        }

        a {
          display: none !important;
        }
      }
    }
  }

  .claim-info {
    .card-tbl {
      .cellprice {
        height: 22px;
      }
    }
  }
  .notesscroll {
    &.scrollblock {
      overflow-y: visible !important;
      max-height: min-content !important;
    }

    .scrollbar-track {
      display: none !important;
    }

    .content-wrap {
      overflow-x: hidden;
      padding: 0 !important;

      p {
        width: 100% !important;
      }
    }
  }

  .grey-controls {
    .lbl-form {
      label {
        width: 200px;
      }
    }
  }

  .noExport,
  .ant-select-arrow {
    display: none !important;
    text-decoration: none;
  }

  .syz-head {
    &.normal-text {
      display: inline-block;
    }
  }

  .pdfcell {
    border-bottom: 2px solid $primary;
  }

  .cvalue {
    width: 140px;
    text-align: left;
  }

  .ant-table-cell {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
  }

  .pdf-select {
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    font: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding: 10px;
    background-color: #fafafb;
  }

  .info-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-tabs-nav {
    display: none;
    width: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
  }

  .ant-tabs-left > .ant-tabs-nav,
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-left > div > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    min-width: 0;
  }

  .page-tab-detail.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-right: 0px !important;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    display: none;
  }

  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 0;
  }

  .normal-text {
    display: block;

    &.flex {
      display: flex;
      justify-content: space-between;
    }
  }

  .settle-neg {
    .sm-scard {
      .ant-card-body {
        .crow {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .infotext {
    p {
      font: 500 12px/14px 'Arial' !important;
    }
  }

  .plain-select {
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none !important;
        background: transparent !important;
        padding: 0 !important;
        height: auto !important;
      }
    }

    span.ant-select-selection-placeholder {
      color: rgba(17, 23, 62, 0.84) !important;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      opacity: 1;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-select-selection-item {
      font: 700 12px/18px 'Arial' !important;
      height: 35px !important;
    }
  }

  .ltxt {
    font: 500 12px/15px 'Arial' !important;
  }

  .settlement .labels {
    margin-left: 15px;
  }

  .cellhead {
    color: #11173ebd;
    font: 300 12px/18px 'Arial' !important;
  }

  .cellprice {
    font: 600 12px/18px 'Arial' !important;
    color: rgba(17, 23, 62, 0.84);

    &.comma {
      font: 600 13px/18px 'Arial';
      display: inline-block;
      pointer-events: none;

      u {
        font: 600 13px/18px 'Arial';
        display: inline-block;
      }
    }
  }

  .claimdetails {
    p {
      font: 600 13px/18px 'Arial' !important;
    }
  }

  .white-box {
    h3 {
      span {
        font: 600 16px/20px 'Arial';
      }
    }
  }

  .lrow {
    p {
      font: 600 12px/18px 'Arial';
    }
  }

  .card-block {
    .card-sm-col {
      width: 320px;
      max-width: 320px;
      min-width: 320px;
      // .card-tbl {
      //     padding: 0;
      // }
    }

    .card-col {
      max-width: calc(100% - 340px);
      width: calc(100% - 340px);
    }
  }

  .pdf-view {
    padding: 0;

    .ant-card-body {
      padding: 0;
    }

    .dashtbl .ant-table-thead > tr > th:first-child,
    .dashtbl .ant-table-tbody > tr > td:first-child,
    .dashtbl .ant-table-thead > tr > th.address-cell,
    .dashtbl .ant-table-tbody > tr > td.address-cell {
      width: 150px !important;
      min-width: 150px !important;
    }

    .dashtbl .ant-table-thead > tr > th,
    .dashtbl .ant-table-tbody > tr > td {
      width: 100px;
      min-width: 100px !important;
    }

    .ant-avatar {
      min-width: 25px !important;
      max-width: 25px !important;
    }

    .ant-table-tbody > tr > td .avatar-info .ant-avatar {
      margin-right: 5px;
    }

    .dashtbl .ant-table-thead > tr > th {
      vertical-align: bottom;
    }

    .ellipsis-text {
      white-space: normal !important;
      word-wrap: break-word;
      word-break: break-word;
    }

    td.ant-table-cell.ant-table-cell-ellipsis div {
      white-space: normal;
    }

    .ant-table-body {
      overflow: visible !important;
      max-height: max-content !important;
    }
  }

  .date-paddingpdf {
    padding-left: 0 !important;
  }

  .disclaimer {
    h4 {
      font: 700 13px/18px 'Arial';
      font-weight: 700 !important;
    }

    span {
      font: 14px;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .pdftab {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none;
    margin-bottom: 20px;
    .pdfcell {
      width: 115px;
      background-color: rgba(243, 244, 252, 0.5);
      color: $primary;
      font-weight: 600;
      text-align: center;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        margin-bottom: 10px;
      }
    }
    .mor-info {
      padding-left: 16px;
      font-size: 12px;
      flex: 1;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    display: none;
  }

  .card-tbl {
    //padding: 15px 25px 0px;
    //padding: 0 25px 0px;
    padding: 0;

    .crow {
      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }

    .border-bottom {
      .crow {
        &:nth-child(8) {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  .hbox {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    .hrow {
      width: 100%;
      max-width: 33.33%;
      flex: 0 0 33.33%;
    }
  }

  .hrow {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
    line-height: 15px;
  }

  .edetail {
    font: 500 12px/16px 'Arial';
    display: block;
  }

  .hdays {
    border: none;

    .etitle {
      width: 100%;
    }

    .edetail,
    span {
      font: 600 12px/16px 'Arial';
    }
  }

  .hleftt {
    column-count: auto;
    padding-right: 0;
    .etitle {
      display: inline-block;
      vertical-align: middle;
    }

    .hrow {
      display: inline-block;
      width: 100%;
      vertical-align: middle;
      padding-bottom: 12px;
      margin-bottom: 12px;
      padding-left: 0;
      padding-right: 0;
    }

    .etitle {
      width: 230px;
      max-width: 230px;
      font-size: 12px;
      margin-right: 0;
      color: rgba(17, 23, 62, 0.74);
      font-weight: 600 !important;
      line-height: 21px;
    }

    .edetail {
      text-align: left;
      display: inline-block;
      // width: 60%;
      // max-width: 60%;
      width: auto;
      max-width: auto !important;
      position: relative;
      left: 14px;
      font-weight: 500;
    }
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    width: 100%;
  }

  .setchart {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  .negtbl {
    tr.ant-table-row:nth-child(even) {
      background-color: #fafafb !important;
    }
  }

  .form-group {
    .overflow-multiselect {
      .ant-select-selector {
        height: auto !important;
      }

      .ant-select-selection-overflow {
        flex-wrap: wrap-reverse;
        overflow: visible;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-selector:after,
      .ant-select-selector:after {
        display: none;
      }
    }

    .ant-select-multiple {
      .ant-select-selection-item,
      .css-1rhbuit-multiValue,
      .tree-tag {
        border: none;
        border-radius: 0;
        background-color: transparent;
        color: rgba(17, 23, 62, 0.84);
        line-height: 20px !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      .ant-select-selection-overflow-item {
        height: auto;
        line-height: normal !important;

        .ant-select-selection-item-content {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            right: 7px;
            top: 0px;
          }
        }

        .ant-select-selection-item-content,
        .ant-tag {
          font: 500 13px/25px 'Arial';
        }
      }

      .ant-select-selection-item-remove {
        display: none;
      }
    }
  }

  // .sm-scard {
  //     .ant-card-body {
  //         padding-left: 0;
  //         padding-right: 0;
  //     }
  // }
  a.sec-link,
  .sec-link {
    &.noExport,
    .noExport {
      display: none !important;
    }
  }

  .liens-table {
    &.budget-tbls {
      text-align: left !important;

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: left !important;
      }

      .ant-table-thead > tr > th {
        &.amounts-cell {
          width: 120px !important;
          max-width: 120px !important;
        }

        &.notes-cell {
          max-width: 400px !important;
          width: 400px !important;
        }
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        padding: 15px 10px !important;

        &:nth-child(2) {
          padding-left: 0 !important;
        }
      }

      .ant-table-expanded-row p {
        padding-left: 50px !important;
      }
    }

    .ant-table-thead > tr > th {
      &.amounts-cell {
        width: 120px !important;
        max-width: 120px !important;
      }

      &.notes-cell {
        max-width: 400px !important;
        width: 400px !important;
      }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 15px 10px !important;

      &:nth-child(2) {
        padding-left: 0 !important;
      }
    }

    .ant-table-expanded-row p {
      padding-left: 50px !important;
    }
  }

  .budget-valuation .liens-table.ECodeColumns .ant-table-expanded-row p {
    padding-left: 65px !important;
  }

  .liens-table.ECodeColumns + .add-claim-damage .drow .dcell:first-child {
    padding-left: 70px !important;
  }

  .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
    background-size: 10px !important;
  }

  /*settlement negotiation*/
  .settle-neg {
    .mt-25 {
      margin-top: 0;
    }
  }

  .negtbl {
    .up-downprice {
      text-align: left;
    }

    .prop-info {
      overflow: visible;
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
    }

    .ant-table-tbody > tr.ant-table-row td {
      color: rgba(17, 23, 62, 0.74);
    }

    .prop-info .comma > span,
    .comma > span {
      // margin-right: 0;
      display: inline-block;
      // background: #ccc;
    }

    td {
      font: 400 13px/16px 'Arial';
    }
  }

  .percentage {
    canvas {
      margin: 0 auto;
    }
  }

  /*damages table*/
  .damages-tbl {
    .ant-table-tbody {
      .ant-table-tbody {
        tr {
          background: transparent !important;

          td {
            background: transparent !important;
          }
        }
      }

      tr.ant-table-row {
        &:nth-child(even) td {
          background: #fafafb !important;
        }

        td {
          background: #fff !important;
        }
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      &:first-child {
        display: none;
      }
      &:nth-child(2) {
        padding-left: 25px !important;
      }
    }
    .ant-table-tbody,
    .ant-table-thead,
    .ant-table-summary {
      > tr {
        > td.cvalue,
        th.cvalue,
        .tfoot-cell {
          padding-left: 5px !important;
        }
      }
    }

    .ant-table-summary {
      tr.normal-text {
        display: table-row;
      }
    }

    .tfoot-cell {
      h5 {
        span {
          font: 700 12px/18px 'Arial';
          color: $primary;
        }
      }

      span {
        font: 400 12px/18px 'Arial';
        color: #11173ebd;
      }
    }

    .tfoot-head {
      vertical-align: top;
      font: 600 13px/20px 'Arial';

      .lg-tooltip,
      br {
        display: none;
      }

      span {
        color: #282e51;
        font: 600 13px/20px 'Arial';
      }
    }
  }

  .tfoot-info {
    span {
      font: 600 13px/20px 'Arial';
      color: #282e51;
    }

    .ant-table-cell > div {
      @media (max-width: 1699px) {
        padding-left: 5px !important;
      }
    }

    p {
      font: 500 13px/16px 'Arial';
      padding-left: 35px;
    }
  }

  .fico {
    font: 500 8px/10px 'Arial';
  }

  .our-team-member {
    .ant-table {
      overflow-x: visible;
      background-color: #fff !important;
    }

    .ant-table-thead,
    .dashtbl {
      background-color: #fff !important;
    }

    .ant-table-body {
      overflow: visible !important;
      max-height: max-content !important;
    }
  }

  .pt--10 {
    margin-top: -15px !important;
  }

  .sm-tbl-pdf {
    padding: 0 !important;
  }

  .percentage {
    .chart-tooltip {
      text-align: center;
      justify-content: center;

      > div {
        position: relative;
      }
    }
  }

  .liability-analysis {
    .ant-card-body {
      padding-top: 10px;
    }
  }

  .info-header {
    border: 1px solid #f0f0f0 !important;
    border-radius: 4px !important;
    .ant-tag {
      margin-left: 5px !important;
    }
  }

  .neg-summary .ellipsis-text {
    max-width: 100%;
    vertical-align: middle;
    width: auto;
    word-break: break-word;
    white-space: normal !important;
    overflow: visible;
  }

  .sm-empty-data {
    margin: 0 auto;

    .emptydata {
      //margin: 50px 0 !important;
    }
  }

  .cheading {
    span {
      word-break: break-word !important;
      white-space: normal !important;
    }
  }

  .liens-summry-text-wrap {
    .cheading {
      span {
        word-break: break-word !important;
        white-space: normal !important;
      }

      h3 {
        span {
          word-break: break-word !important;
          white-space: break-spaces !important;
        }
      }
    }
  }

  .avatar-info {
    .avatar-img {
      img {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 25px;
      }
    }
  }

  // .damages-tbl .tfoot-head span {
  //   left: -60px;

  //   @media (max-width: 1699px) {
  //     left: -45px;
  //   }
  // }

  .matter-tbl {
    .dashtbl {
      .ant-table-header {
        overflow: visible !important;
        position: static !important;

        table {
          table-layout: auto !important;
          overflow: visible !important;

          .ant-table-cell.cvalue {
            text-align: left !important;
          }

          .tfoot-cell span {
            text-align: left;
            float: left;
            width: 100%;
          }
          tr th {
            position: static !important;
            width: 220px !important;
            max-width: 220px !important;
            min-width: 220px !important;
            color: #373c5d !important;
            font-weight: 700 !important;
            text-align: left;

            &:first-child {
              padding-left: 24px !important;
            }

            &:first-child,
            &:last-child {
              width: 220px !important;
              max-width: 220px !important;
              min-width: 220px !important;
            }
          }

          .ant-table-cell-fix-left,
          .ant-table-cell-fix-right {
            position: relative !important;
            left: auto !important;
          }

          .ant-table-cell-fix-left-first::after,
          .ant-table-cell-fix-left-last::after {
            display: none;
          }

          .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
            display: none !important;
          }
        }
      }
    }
  }

  .matter-tbl {
    .alert-count {
      small {
        white-space: nowrap;
      }
    }

    &.pdf-matter-tbl {
      // display: block !important;
      opacity: 1 !important;
      height: auto;
      overflow: visible !important;
      position: static !important;
      pointer-events: all !important;
    }

    &.contacttbl {
      .ant-table-thead tr th.ant-table-cell,
      .ant-table-thead tr th,
      .ant-table-tbody tr td {
        position: static !important;
        width: 425px !important;
        max-width: 425px !important;
        min-width: 425px !important;

        .ant-table-column-sorter {
          display: none !important;
        }

        &:first-child {
          padding-left: 24px !important;
        }

        &:first-child,
        &:last-child {
          width: 425px !important;
          max-width: 425px !important;
          min-width: 425px !important;
        }
      }
    }

    .ant-table {
      overflow: visible !important;
    }

    .ant-table-header {
      overflow: visible !important;
      position: static !important;
    }

    .ant-table-thead tr th.ant-table-cell,
    .ant-table-thead tr th,
    .ant-table-tbody tr td {
      position: static !important;
      width: 220px !important;
      max-width: 220px !important;
      min-width: 220px !important;

      .ant-table-column-sorter {
        display: none !important;
      }

      &:first-child {
        padding-left: 24px !important;
      }

      &:first-child,
      &:last-child {
        width: 220px !important;
        max-width: 220px !important;
        min-width: 220px !important;
      }
    }

    .iGTbIm {
      .ant-table {
        overflow-x: visible;
      }
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      position: relative !important;
      left: auto !important;
    }

    .ant-table-cell-fix-left-first::after,
    .ant-table-cell-fix-left-last::after {
      display: none;
    }

    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
      display: none !important;
    }

    .iGTbIm .ant-table-thead > tr > th,
    .iGTbIm .ant-table-tbody > tr > td {
      overflow-wrap: break-word;
    }

    .ant-table-body {
      max-height: max-content !important;
      overflow: visible !important;
    }
  }
  .halfitem {
    .grey-box {
      margin: 0 6px;
    }
  }
  .no-border {
    .ant-card-head {
      border: none !important;
    }
  }
  .cbody {
    .card-container {
      .ant-card-head {
        padding-bottom: 0;
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
}
// new pdf end

.pdf-matter-tbl {
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
}

.pdf-view {
  .iGTbIm {
    .ant-table {
      overflow-x: visible;
    }
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    display: none !important;
  }

  .dashtbl {
    .ant-table-body {
      max-height: max-content !important;
      overflow: visible !important;
    }
  }

  .ant-table-thead,
  .dashtbl {
    background-color: #fff !important;
  }

  .ant-table-tbody {
    > tr {
      > td {
        .avatar-info {
          .ant-avatar {
            margin-right: 10px;
          }
        }
      }
    }

    td,
    th {
      div,
      p {
        word-break: break-word;
        word-wrap: break-word;
        white-space: normal;
      }
    }
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    position: relative !important;
    left: auto !important;
  }

  .ant-table-cell-fix-left-first::after,
  .ant-table-cell-fix-left-last::after {
    display: none;
  }

  .dashtbl .ant-table-tbody td:first-child {
    width: 160px !important;
    max-width: 160px !important;
    min-width: 160px !important;
    padding-left: 10px !important;
  }

  .dashtbl .ant-table-tbody th,
  .dashtbl .ant-table-thead th:first-child {
    width: 130px !important;
    max-width: 130px !important;
    min-width: 130px !important;
  }

  .dashtbl .ant-table-thead tr th,
  .dashtbl .ant-table-tbody tr td {
    width: 125px !important;
    max-width: 125px !important;
    min-width: 125px !important;
  }

  .dashtbl .ant-table-tbody tr td {
    padding-left: 20px !important;
  }

  .iGTbIm .ant-table-thead > tr > th,
  .iGTbIm .ant-table-tbody > tr > td {
    overflow-wrap: break-word;
  }

  .avatar-info {
    display: inline-block;
    width: 200px;
  }
}

.pdf-view {
  table {
    width: 100% !important;
    min-width: 100% !important;
  }

  th.ant-table-cell.ant-table-cell-scrollbar {
    display: none !important;
  }
}

.normal-text.pl-10.pb-10 {
  padding-left: 30px;
  padding-bottom: 30px;
}

.pl-10 {
  padding-left: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.fz-n18 {
  font-size: 18px !important;
  font-weight: 700;
}
.stack-data {
  display: block;
  column-count: auto !important;
  width: auto;
}

.page-width-p {
  p {
    word-wrap: break-word;
    white-space: break-all;
    margin-bottom: 10px !important;
    padding-right: 0 !important;
    // margin: 10px !important;
    // padding-right: 10px !important;
    width: 100%;

    // @media (min-width: 1680px) and (max-width: 2000px) {
    //   min-width: 1880px;
    // }

    // @media (min-width: 1280px) and (max-width: 1679px) {
    //   min-width: 1220px;
    // }

    // @media (min-width: 1024px) and (max-width: 1279px) {
    //   min-width: 840px;
    // }

    &:after {
      display: none;
    }

    &:before {
      display: none;
    }

    p {
      margin: 0;
      padding: 0;
    }

    br {
      display: inline;
      padding: 0;
      margin: 0;
    }
  }
}

.triangleexclamation-img {
  color: inherit;
  max-width: 12px;
}

.content-wrap {
  white-space: initial !important;
  word-wrap: initial !important;
  overflow: auto !important;
}
