/* pagination number hide */
.ant-pagination-item {
  display: none !important;
}
.srv-validation-message {
  color: #d83535 !important;
}
.d-block {
  display: flex;
}
.d-none {
  display: none !important;
}
.ant-table-column-sorter-up.active svg,
.ant-table-column-sorter-down.active svg {
  color: #5463d2;
}
.cur-pointer {
  cursor: pointer;
}
.cur-default {
  cursor: default;
}
.active-line {
  background-color: rgba(84, 99, 210, 0.05);
}
.no-data {
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.no-data-icon {
  height: 34px;
  width: 36px;
}
.ant-drawer-mask {
  background-color: transparent !important;
  opacity: 0 !important;
}
.label-left {
  color: red;
}
.red {
  height: 250px;
  width: 500px;
}
.red-text {
  color: #d83535;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

/* common table damage */
.damages-tbl .ant-table-thead > tr > th:first-child {
  min-width: 50px;
}
.damages-tbl .ant-table-tbody > tr > td:first-child {
  min-width: 50px;
}
.damages-tbl .ant-table-thead > tr:first-child > th:first-child {
  text-align: left;
  min-width: 50px;
}
.damages-tbl .ant-table-thead > tr > th:nth-child(2),
.damages-tbl .ant-table-tbody > tr > td:nth-child(2) {
  line-height: 17px;
}
.damages-tbl .ant-table-thead > tr > .damage-cat,
.damages-tbl .ant-table-tbody > tr > td.damage-cat {
  max-width: 260px;
  min-width: 260px;
}
.damages-tbl .ant-table-thead > tr > .damage-cat,
.damages-tbl .ant-table-tbody > tr > td.cvalue {
  min-width: 140px;
  max-width: 140px;
}
.small-box {
  width: 25px;
  height: 25px;
}
.ant-input-number-handler-wrap {
  display: none;
}
.bg-blue {
  background-color: #1c203c !important;
  border-radius: 100% !important;
}
.capitalize {
  text-transform: capitalize;
}
.mbot-24 {
  margin-bottom: 24px !important;
}
.note-area {
  height: 150px;
  overflow: scroll;
}
/* .ant-picker-footer {
  display: none;
} */
.mt-24 {
  margin-top: 24px !important;
}
.empty-round-but {
  border-radius: 100% !important;
}
.mr-5 {
  margin-right: 5px;
}
.ml-10 {
  margin-left: 10px;
}
/* highlight color */
ins {
  text-decoration: none !important;
  background-color: rgba(251, 200, 0, 0.5) !important;
  color: rgba(17, 23, 62, 0.84);
  padding: 4px 4px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-word;
}
#output ins,
#output del {
  margin-bottom: 10px;
}
#output ins img,
#output del img {
  max-height: 195px;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block;
}
#output del img {
  display: block;
}
.lh-45 {
  line-height: 45px !important;
}
del {
  text-decoration: line-through !important;
  margin-right: 5px;
  display: block;
}
del img {
  opacity: 0.4;
}
/* global search highlights */
.highlightMatch {
  background-color: #e3e6f8 !important;
  width: auto;
  color: rgba(17, 23, 62, 0.84);
  font-weight: 600;
  display: inline-block;
  line-height: 15px;
  margin: 0 2px;
  padding: 0.2em;
}
.sendbird-context-menu .sendbird-iconbutton {
  line-height: 30px;
}
.sendbird-theme--light .sendbird-iconbutton--pressed {
  background-color: rgba(84, 99, 210, 0.16) !important;
}
.sendbird-user-message--outgoing .sendbird-user-message__text-balloon {
  text-align: right;
}

/* document move */
.Select-menu-outer {
  z-index: 999 !important;
}
.Select-menu-outer {
  z-index: 1000;
  position: relative;
}
.Select.is-open {
  position: relative;
  z-index: 1000;
}

:global(.Select-menu-outer) {
  z-index: 999 !important;
}
.zClass {
  position: relative;
  z-index: 9;
}
.hRWUsG.remove-top-space {
  padding-bottom: 0 !important;
}
.main-class-no-data {
  height: 250px !important;
}
/* for joint and several */
.price-text {
  font-size: 15px !important;
  color: #5463d2 !important;
  font-weight: 600 !important;
}
