@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

body .normal-text .fullblock .pdf-fontno {
  font-family: 'Arial' !important;

  * {
    font-family: 'Arial' !important;
  }
}

@import 'pdf.scss';
@import 'variable.scss';
@import '~antd/dist/antd.css';
@import 'fonts.css';

/* 1. Clean Base
------------------------------------------------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
a,
nav,
section,
summary,
time,
mark,
audio,
video {
  // margin:0 auto;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;

  //  list-style:none;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

img {
  border: none;
}

input[type='text'],
input[type='submit'],
input[type='button'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='search'],
textarea {
  -webkit-appearance: none;
  outline: none;
}

textarea:focus,
input:focus,
a,
div,
img {
  outline: none;
}

*,
*:focus {
  outline: none !important;
}

body {
  font-family: 'Montserrat', Arial !important;
  font-size: 14px;
  color: $secondary;
  background: #f0f2f5 !important;
  color: $color-base !important;
}

/*Headings*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px !important;
  font-weight: 600 !important;
  color: $color-base !important;
}

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;

  small {
    font-weight: 500;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;

  small {
    font-weight: 500;
  }
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;

  small {
    color: rgba(17, 23, 62, 0.74);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 500;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;

  small {
    font-size: 12px !important;
    font-weight: 500;
    color: rgba(17, 23, 62, 0.74);
  }
}

h5,
.h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;

  small {
    font-weight: 500;
  }
}

.main-head {
  display: flex;
  flex-direction: column;

  .sub-head {
    color: rgba(17, 23, 62, 0.74);
    font-size: $font-size-base;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: normal;
    margin-top: 10px;
  }
}

.btxt {
  font-size: $font-size-base-sm;
  font-weight: 600;
  color: $color-base;
  line-height: 18px;
}

.small-text {
  color: rgba(17, 23, 62, 0.84);
}

.radius-full {
  border-radius: 100%;
}

// h6,.h6{ font-size:$h6-font-size; line-height: $h6-line-height}
hr {
  background: rgba(231, 232, 235, 1);
  height: 1px;
  border: none;
}

small {
  color: $grey-link;
  display: block;
  font-size: 12px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.lh-18 {
  line-height: 18px;
}

.text-capitalize {
  text-transform: capitalize;
}

.transform-none {
  transform: none !important;
}

/*mixins*/
.head-iconn {
  height: 30px;
  width: 30px;
  min-width: 30px;
  border: 1px solid #ced3f9;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  background-color: #f3f4fc;
  align-items: center;
  color: $primary;
  font-size: 16px;
}

@mixin title-ico-head {
  .ant-card-head-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .cheading {
      margin-left: 15px;
      line-height: 18px;

      h3 {
        margin-bottom: 5px !important;
      }

      span {
        color: rgba(17, 23, 62, 0.74);
        font-size: $font-size-base-sm;
        letter-spacing: 0;
        line-height: 16px;
      }

      .ellipses-text {
        max-width: 80%;
      }
    }
  }

  .ant-card-head {
    padding: 15px 25px;

    .ant-card-head-title {
      padding: 0;

      .card-head {
        width: 100%;
        justify-content: flex-start;

        .head-icon {
          height: 40px;
          width: 40px;
          min-width: 40px;
          border: 1px solid #ced3f9;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          background-color: #f3f4fc;
          align-items: center;
          color: $primary;
          font-size: 16px;
        }
      }
    }
  }
}

@mixin lists {
  ul {
    list-style-type: none;

    li {
      color: $grey-link;
      font-size: $font-size-base-sm;
      line-height: 16px;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
        color: $grey-clr;
        font-size: 15px;
      }

      &.success-val {
        svg {
          color: $success;
        }
      }
    }
  }
}

.hr-seperator {
  height: 1px !important;
  border: none;
  margin: 15px -25px;
}

.comma {
  > span {
    margin-right: 5px;

    &:after {
      content: ',';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.pos-relative {
  position: relative;

  .pos-100 {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .ico-dot {
    top: -2px;
    right: -3px;
  }

  .md-dot {
    height: 12px;
    width: 12px;
  }

  .sm-dot {
    height: 10px;
    width: 10px;
  }

  &.sm-btn,
  &.ant-btn.sm-btn {
    padding: 3px 10px !important;
    height: 25px;
    line-height: 18px;
    align-items: center;
    display: flex;
    font-size: 12px !important;
  }

  &.lg-btn {
    font-size: 14px !important;
    height: 40px;
  }

  &.big-btn {
    padding: 10px 12px !important;
    width: 100%;
    height: 48px;
    font-size: 14px !important;
  }

  &.smm-btn {
    padding: 6px 12px !important;
    height: 32px;
    font-size: 12px !important;
  }

  &:disabled {
    background-color: #d0d0d0;
    color: $white;
    border: none;

    &:after {
      display: none;
    }
  }
}

.pos-absolute {
  position: absolute;
}

.cite {
  font-style: normal !important;
}

/*Spacings*/
.ml-40 {
  margin-left: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-65 {
  margin-top: 67px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-12 {
  margin-top: 12px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px;
}

.plr-24 {
  padding-inline: 24px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.plr-25 {
  padding-left: 25px;
  padding-right: 25px;
}

/*alignment*/
.txt-right {
  text-align: right !important;
}

.txt-left {
  text-align: left;
}

.txt-center {
  text-align: center;
}

.txt-decoration {
  text-decoration: underline;
}

.weight-400 {
  font-weight: 400 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

// strong {font-weight: 500 !important;}
.fa-reply {
  transform: rotate(-180deg);
}

.btext {
  color: $primary !important;
  font-weight: 600 !important;
}

.danger {
  color: $danger;
}

.gray-clr {
  color: $grey-clr;
}

.primary-clr {
  color: $primary;
}

.grey-link {
  color: $grey-link;
}

.secondary-gray {
  color: $secondary-gray;
}

.secondary {
  color: $secondary;
}

.secondary-white {
  color: $secondary-white;
}

/*Link*/
.primary-link {
  color: $primary;
  font-size: $font-size-base;
  font-weight: bold;
  cursor: pointer;
  line-height: 16px;

  &:hover,
  &:focus {
    color: $secondary;
  }

  svg {
    margin-left: 5px;
    font-size: 14px;
  }

  &.sm-link {
    font-size: 12px;

    svg {
      font-size: 12px;
      margin-left: 0;
    }
  }

  &.grey-link {
    color: $grey-link;
  }

  &.w400 {
    font-weight: 500;
  }

  &.disabled-link {
    color: rgba(208, 208, 208, 1);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.sec-link {
  color: $secondary;

  &:hover,
  &:focus {
    color: $primary;
  }
}

.blink {
  color: rgba(17, 23, 62, 0.84);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-decoration: underline;
}

.my-anchor-css-class {
  width: 100%;
  display: block;
  text-align: center;
  color: $primary;
  font-size: $font-size-base-sm;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 25px;
  height: 105px;
  position: absolute;
  bottom: -40px;
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

.content-css {
  margin-bottom: 40px;
  position: relative;
}

.bgwhite {
  background-color: $white !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

/*Buttons*/
.ant-input-affix-wrapper:hover {
  border-color: $primary !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-number-focused {
  box-shadow: none !important;
  border-color: $primary !important;
}

.btn,
.ant-btn:not(.ant-dropdown-trigger),
button[type='submit'],
.submit,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  border: 1px solid $primary;
  border-radius: 4px !important;
  background-color: $primary;
  color: #fff;
  position: relative;

  &:hover,
  &:focus {
    color: $primary;
    border: 1px solid $primary;
    background: $white;
  }
}

.no-btn {
  border: 1px solid #d0d0d0;
  padding: 0;
  line-height: 15px;
  border-radius: 100%;
  background: none;
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.plain-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
}

.btn,
.ant-btn:not(.ant-dropdown-trigger) {
  padding: 6px 12px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  &:hover,
  &:focus {
    &:after {
      display: none;
    }
  }

  &.btn-filter {
    border: 1px solid rgba(28, 32, 60, 0.1);
    border-radius: 4px;
    background-color: $disable;
    color: $color-base;
    margin: 0 15px 0 0;

    svg {
      color: $grey-clr;
      margin-right: 10px;
    }

    &:hover,
    &:focus,
    &.active {
      color: $primary;
      border-color: $primary;
      background: #f3f4fc;
      outline: none !important;

      svg {
        color: $primary;
      }
    }

    &:after {
      display: none;
    }
  }

  &.btn-blue {
    border: 1px solid rgba(208, 208, 208, 1);
    margin-left: 15px;
    background-color: rgba(255, 255, 255, 1);
    color: $primary;

    &:hover,
    &:focus {
      border: 1px solid $primary !important;
      background-color: rgba(255, 255, 255, 1) !important;
      color: $primary !important;
    }

    &:after {
      display: none;
    }
  }

  &.btn-bdr {
    border: 1px solid #d0d0d0;
    background-color: #ffffff;
    color: $primary;
    font-size: 12px;

    svg {
      font-size: 14px;
    }

    &:hover {
      border: 1px solid $primary !important;
      background-color: $primary !important;
      color: $white !important;

      svg {
        color: $white;
      }
    }

    &:after {
      display: none;
    }
  }

  &.btn-grey {
    border: 1px solid rgba(28, 32, 60, 0.1);
    border-radius: 4px !important;
    background-color: $disable !important;
    color: $color-base;

    &:after {
      display: none;
    }

    svg {
      color: #868994;
    }
  }

  &.btn-disabled {
    background-color: #d0d0d0;
    color: $white;
    border-color: #d0d0d0;

    &:after {
      display: none;
    }
  }

  &.sm-btn,
  &.ant-btn.sm-btn {
    padding: 3px 10px !important;
    height: 25px;
    line-height: 18px;
    align-items: center;
    display: flex;
    font-size: 12px !important;
  }

  &.lg-btn {
    font-size: 14px !important;
    height: 40px;
  }

  &.big-btn {
    padding: 10px 12px !important;
    width: 100%;
    height: 48px;
    font-size: 14px !important;
  }

  &.smm-btn {
    padding: 6px 12px !important;
    height: 32px;
    font-size: 12px !important;
  }

  &:disabled {
    background-color: #d0d0d0;
    color: $white;
    border: none;

    &:after {
      display: none;
    }
  }
}

.ant-btn {
  &.blue-btn {
    padding: 6px 12px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    position: relative;
    background-color: $primary !important;
    color: $white !important;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    }

    &:hover {
      background-color: $white !important;
      color: $primary !important;
      border: 1px solid $primary !important;

      &:after {
        display: none;
      }
    }
  }
}

.plain-select {
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }

    .ant-select-selection-item {
      color: $color-base;
      font-weight: 600;
      font-size: 20px;
    }

    .ant-select-arrow {
      margin-top: -12px;
    }
  }
}

.sort-dropdown-ico {
  position: relative;

  .ant-select-selector {
    padding-right: 30px !important;
  }

  &:after {
    position: absolute;
    content: '';
    background-image: url(../assets/images/sort-down.png);
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    background-size: 10px;
    margin-top: 5px;
    right: 10px;
    top: 10px;
  }
}

.blue-filter {
  cursor: pointer;

  .ant-select-selector {
    border: 1px solid $primary !important;
    border-radius: 4px !important;
    background: #f3f4fc !important;
    font-weight: 600;
    font-size: 12px !important;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 18px;
  }

  .ant-select-arrow {
    right: auto;
    left: 11px;

    svg {
      color: $primary;
    }
  }

  .ant-searchbtn {
    border-radius: 4px !important;
    background: #fafafb;
  }

  .ant-select-dropdown {
    width: 315px !important;
    min-width: 315px !important;

    input {
      background: #fafafb;
    }
  }

  .action-row {
    border-top: 1px solid #e7e8eb;
  }
}

.btn-block {
  .btn {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-cascader-picker:focus .ant-cascader-input {
  box-shadow: none !important;
}

.ant-filterbtn {
  cursor: pointer;

  .ant-select-selector {
    cursor: pointer;
  }

  &.white {
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-arrow,
    &.ant-cascader-picker input {
      background-color: $white !important;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
    .ant-select-selection-item,
    .ant-select-selection-placeholder,
    .ant-select-arrow,
    .ant-cascader-input {
      color: $primary;
    }

    .ant-select-arrow {
      svg {
        font-size: $font-size-base;
      }
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-cascader-picker-arrow {
      border-color: #d0d0d0 !important;
    }

    &.ant-select-open .ant-select-selection-item {
      color: $primary;
    }
  }

  &.ant-cascader-picker input {
    height: 32px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-cascader-picker input {
    color: $color-base !important;
    font-size: 12px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
    background-color: rgba(244, 244, 246, 1) !important;
    border: 1px solid rgba(28, 32, 60, 0.1) !important;
    border-radius: 4px !important;
    padding: 0 0 0 11px !important;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    cursor: auto;
    color: $color-base;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 32px;
  }

  .ant-select-arrow,
  .ant-cascader-picker-arrow {
    border-left: 1px solid rgba(28, 32, 60, 0.1) !important;
    border-radius: 0 4px 4px 0;
    background-color: rgba(244, 244, 246, 1);
    height: 100%;
    border: none;
    height: 100%;
    top: 7px;
    font-weight: bold;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1px;
    height: 30px;
  }

  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 30px;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 25px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(244, 244, 246, 1);
  }

  .ant-select-arrow {
    svg {
      font-size: 16px;
      margin-top: -5px;
    }
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector .ant-select-selection-item,
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item {
    color: $primary;
  }
}

.tagcell {
  background: #fafafb;
  border: 1px solid #d0d0d0;
  padding: 4px 12px;
  border-radius: 100px;
  color: rgba(17, 23, 62, 0.84);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

sup {
  color: $danger;
  font-size: $font-size-base-sm !important;
  margin-left: 6px;
  top: -2px !important;
}

.column-count-2 {
  column-count: $col-count;
}

/*Page heading*/
.page-heaing {
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.9);
  padding: 12px 50px;
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);
  z-index: 9;
  position: relative;

  .head-icon {
    height: 42px;
    width: 42px;
    min-width: 42px;
    border: 1px solid #ced3f9;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    background-color: #f3f4fc;
    align-items: center;
    color: $primary;
    font-size: 16px;
  }

  h1 {
    color: $color-base;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 0 12px !important;
  }

  small {
    font-size: $font-size-base-sm;
  }
}

.mhead {
  color: $color-base;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;

  small {
    font-size: 14px;
    line-height: 20px;
    color: $grey-link;
  }
}

.page-head {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-weight: bold;
  }
}

/*Header*/
header {
  padding: 12px 15px !important;

  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
}

.bdropdown {
  .ant-dropdown-menu-submenu {
    padding: 0;
    box-shadow: none !important;

    &.ant-dropdown-menu {
      padding: 0 !important;
    }

    .ant-dropdown-menu-submenu-popup {
      ul {
        margin-right: 0;
        margin-left: 0;
        width: 135px;
      }
    }
  }

  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }

  &.ant-dropdown .ant-dropdown-menu-item svg,
  &.ant-dropdown .ant-dropdown-menu-submenu-title svg {
    position: absolute;
    right: 5px;
    top: 12px;
  }
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu {
  padding: 0 !important;
  width: 140px;

  ul {
    margin: 0 !important;
    padding: 0 !important;

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgba(84, 99, 210, 0.16) !important;

      .ant-dropdown-menu-title-content {
        font-weight: 600;
      }
    }

    .ant-dropdown-menu-item {
      padding: 9px 12px;
    }
  }
}

.hleft {
  .logo {
    margin-right: 65px;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $white !important;
  }
}

.btn.ant-btn.blue-btn {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;

  svg {
    height: 18px;
    font-size: 18px;
    margin-top: -5px;
  }
  &.lg-btn {
    span {
      height: 38px;
    }
  }
  span {
    flex: 1;
    display: flex;
    align-items: center;
    height: 32px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    span {
      border-right: 1px solid $primary;
    }
  }
}

.ant-layout-header {
  padding-right: 50px !important;
}

// z-index: 9999 !important;
.trigger-header {
  height: 70px;
}

.feedbackbtn {
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  height: 35px;
  transform: rotate(-90deg) translateY(70px);
  margin: 0 auto;
  width: 166px;
  top: 50%;
  bottom: 0;
  z-index: 9999;
  pointer-events: auto;

  a {
    background-color: #373c5d;
    color: $white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    height: auto;
    width: 165px;
    border-radius: 8px 8px 0 0;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.newmsg {
  position: fixed;
  right: 140px;
  bottom: 15px;
  height: 48px;
  width: 48px;
  box-shadow: 3px 3px 22px 0 rgba(17, 23, 62, 0.3);
  background: #11173e;
  border-radius: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 23px;

  .ndot {
    height: 14px;
    width: 14px;
    border-radius: 100%;
    border: 1px solid $white;
    top: 13px;
    right: 12px;
  }
}

/*Sidebar*/
.ant-layout-sider {
  top: 70px;
  background-color: #1c203c !important;
  transform: translate(0, 0);
  z-index: 9999 !important;
}

.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: none !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent !important;
}

.ant-menu {
  background-color: #1a1d35 !important;
  padding: 0 0 0 !important;

  .ant-menu-item {
    position: relative;

    svg {
      font-size: 19px;
    }

    a {
      color: #868994 !important;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      display: flex;

      span {
        padding-left: 28px;
      }
    }

    &:before {
      transition: all 0.1s ease;
      opacity: 0;
      border-radius: 0 100px 100px 0;
      background-color: $primary;
      width: 4px;
      content: '';
      position: absolute;
      left: 0;
      height: 60px;
      top: 0;
    }

    &:hover,
    &:focus,
    &.ant-menu-item-selected,
    &.ant-menu-item-active,
    &.ant-menu-item:active,
    &.ant-menu-submenu-title:active {
      a {
        color: $white !important;
        font-weight: bold;
      }

      svg {
        color: $primary;
      }

      &:before {
        transition: all 0.1s ease;
        opacity: 1;
      }

      &.quicklink {
        .ant-menu-item {
          &:hover,
          &:focus,
          &.ant-menu-item-selected,
          &.ant-menu-item-active,
          &.ant-menu-item:active,
          &.ant-menu-submenu-title:active {
            a {
              font-weight: normal !important;
            }
          }
        }
      }
    }

    &:after {
      display: none !important;
    }

    &.ant-menu-item:active,
    &.ant-menu-submenu-title:active {
      background-color: rgba(255, 255, 255, 0.08) !important;
    }
  }

  .ant-menu-item.support {
    position: fixed;
    bottom: 90px;
    left: 0;
    background: #1a1d35;
  }

  &.ant-menu-sub {
    padding-top: 0 !important;
    padding-left: 25px !important;

    .ant-menu-item:before {
      display: none;
    }

    .ant-menu-item {
      padding-top: 10px;
      background: rgba(255, 255, 255, 0.08);
      padding-bottom: 10px;

      a {
        color: $white !important;
        font-weight: 400 !important;
        text-transform: capitalize;
        word-break: break-word;
        word-wrap: break-word;
        text-overflow: clip;
        overflow: visible;
        white-space: normal !important;
      }
    }
  }

  .ant-menu-sub.ant-menu-root {
    padding-left: 0 !important;
    margin-top: 20px;
  }
}

.ant-menu-inline {
  .quicklink {
    .ant-menu-item {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}

.ant-menu-root {
  border-right: none !important;

  .quicklink {
    max-width: 100%;
    flex: 1 !important;
  }

  &.ant-menu-root {
    .quicklink {
      .ant-menu-submenu-title {
        padding: 0 !important;
      }
    }
  }

  .ant-menu-item {
    &:last-child {
      bottom: 95px;
      position: fixed;
    }
  }

  .ant-menu-submenu .ant-menu-sub {
    padding-left: 0 !important;
    margin-top: 20px;
    max-height: calc(100vh - 535px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -23px;
    width: 260px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $primary;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      outline: none;
      border-radius: 10px;
    }

    .ant-menu-item {
      &:last-child {
        bottom: 0;
        position: relative;
      }
    }

    .ant-menu-submenu-title {
      margin: 0;
    }
  }
}

.ant-menu-root .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-root .ant-menu-submenu-title {
  height: auto !important;
  line-height: normal !important;
}

.ant-menu-root.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-root.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: none !important;
  width: 100%;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.ant-menu-root > .ant-menu-item {
  height: 56px !important;
  line-height: 56px !important;
}

.ant-menu-root .ant-menu-item:not(:last-child) {
  margin-bottom: 0 !important;
}

.ant-layout-header {
  left: 0;
  height: auto !important;
  line-height: normal !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-root .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-root .ant-menu-submenu-title {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ant-menu-root .ant-menu-item,
.ant-menu-root .ant-menu-submenu-title {
  width: 100% !important;
}

.search-wrapper {
  position: relative;
  margin-right: 40px;

  .ant-input-affix-wrapper {
    height: 40px;
    width: 516px;
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    background-color: $grey;

    input {
      background-color: $grey;
    }
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    z-index: inherit;
  }

  .ant-input-suffix {
    right: 35px;
    top: 10px;
  }

  .ant-input-affix-wrapper {
    .ant-input {
      width: 86%;
    }
  }

  .ant-input-affix-wrapper:hover + .fitem-ricon {
    display: block;
    z-index: 9;
  }

  .btn {
    font-size: 13px;
    font-weight: bolder;
  }

  .ant-dropdown-menu-title-content {
    width: 100%;
  }

  .ant-input-suffix svg {
    color: $primary;
    font-size: 19px;
    font-weight: bolder;
  }
}

.diffmenu {
  svg {
    color: #868994 !important;

    &.fa-pencil {
      font-size: 11px !important;
    }
  }

  span {
    color: #868994 !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 25px;
    padding-right: 10px;
  }

  &:hover,
  &:focus {
    span {
      color: $white !important;
      font-weight: bold;
    }

    svg {
      color: $primary !important;

      &.fa-pencil {
        color: #868994 !important;
      }
    }
  }

  // &:before {transition: all 0.1s ease;opacity: 0;border-radius: 0 100px 100px 0; background-color: $primary; width: 4px;content: ''; position: absolute; left: 0; height: 100%;
  // }
}

.ant-menu-root {
  &.ant-menu-root {
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 18px 0 18px;
      padding-left: 24px !important;
    }

    .ant-menu-submenu {
      .ant-menu-item,
      .ant-menu-submenu-title {
        padding: 12px 0;
      }
    }
  }
}

.ant-menu-submenu-inline .ant-menu-submenu-title {
  padding: 0 !important;
  height: auto;
  line-height: normal;
}

.ant-menu-submenu-arrow,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: rgba(231, 232, 235, 1) !important;

  &:before,
  &:after {
    width: 6px !important;
  }
}

.ant-menu-root > .ant-menu-item {
  height: auto !important;
  line-height: normal !important;
  padding-top: 0;
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 0;
  position: fixed;
  bottom: 70px;
  width: 100%;

  span,
  a {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.84);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $white;
    }

    &:last-child {
      text-decoration: none;
    }
  }
}

.right-sidebar {
  padding-left: 70px !important;
}

.ant-menu .ant-menu-item:hover a,
.ant-menu .ant-menu-item:focus a,
.ant-menu .ant-menu-item.ant-menu-item-selected a,
.ant-menu .ant-menu-item.ant-menu-item-active a,
.ant-menu .ant-menu-item.ant-menu-item:active a,
.ant-menu .ant-menu-item.ant-menu-submenu-title:active a {
  white-space: nowrap;
}

.ant-menu {
  .amt-menu-item {
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }
}

/*Flex*/
.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.flex-between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.ai-start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
}

.ai-stretch {
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
}

.ai-center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.ai-end {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
}

.ai-baseline {
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  -o-align-items: baseline;
  align-items: baseline !important;
}

.flex-start {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

.flex-end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-left,
.flex-right {
  flex: 1;
  max-width: 50%;
}

.flex-left {
  padding-right: 15px;

  &.pr {
    padding-right: 30px;
    max-width: 320px;
  }
}

.flex-right {
  padding-left: 15px;

  &.pl {
    padding-left: 30px;
    max-width: 320px;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.wrap-text {
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.space-between {
  justify-content: space-between;
}

.row-reverse {
  flex-direction: row-reverse;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w100 {
  width: 100px !important;
}

.w-200 {
  min-width: 200px !important;
}

.h-40 {
  height: 40px;
}

.h-100 {
  height: 100%;
}

.no-wrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.disabled-ico {
  color: rgba(112, 116, 139, 1);
  opacity: 0.2;
  cursor: not-allowed;
}

.tp-flex {
  .flex-auto {
    flex: auto;
    min-width: 25px;
    width: 25px;
    max-width: 25px;
  }
}

.fitem-rinner {
  position: relative;

  input {
    padding-right: 30px;
  }

  .fitem-ricon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      display: block;
    }

    .btn {
      max-width: 32px;
      width: 32px;
      padding: 5px 5px;
      text-align: center;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &.licon {
    .fitem-ricon {
      right: auto;
      left: 15px;
    }

    input {
      padding-left: 45px;
    }
  }
}

/*Ant items*/
.ant-input,
.ant-picker,
input {
  &:hover,
  &:focus,
  &.ant-picker-focused {
    // border-color: $primary !important;
    box-shadow: none !important;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bfbfbf;
    font-weight: 400 !important;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #bfbfbf;
    font-weight: 400 !important;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #bfbfbf;
    font-weight: 400 !important;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #bfbfbf;
    font-weight: 400 !important;
  }
}

.ant-picker-dropdown {
  .ant-picker-time-panel-column {
    &:after {
      height: auto;
    }
  }
}

.ant-input-focused {
  box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.fcontrol > .ant-input:focus {
  border-color: $primary !important;
  box-shadow: none !important;
  cursor: pointer;
}

// .ant-picker-clear {right: 25px !important;}
.ant-picker-clear {
  right: 15px !important;

  svg {
    font-size: 13px;
  }
}

.ant-select-dropdown,
.mesg-down,
.ant-dropdown {
  border: 1px solid #e7e8eb;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);

  .ant-select-item,
  .ant-dropdown-menu-item {
    padding: 6px 15px;
    color: $secondary !important;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    &:hover {
      .ant-dropdown-menu-title-content {
        font-weight: 600;
      }
    }
  }
}

.mesg-down {
  .ant-dropdown-menu {
    padding: 12px 0 !important;
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);

    .fitem {
      padding: 0 8px;
    }
  }
}

/*Button dropdown*/
.md-dropdown {
  width: 210px !important;
}

.mdd-dropdown {
  width: 250px !important;
}

.lg-dropdown {
  width: 300px !important;
}

.lg1-dropdown {
  width: 360px !important;
}

.sm-dropdown {
  width: 180px !important;
}

.smm-dropdown {
  width: 150px !important;
}

.xss-dropdown {
  width: 125px !important;
}

.xm-dropdown {
  width: 64px !important;
}

.xmd-dropdown {
  width: 105px !important;
}

.minw {
  min-width: 0;
}

.emdrop {
  .ant-dropdown-menu-item {
    display: block;
  }
}

.ant-row {
  color: $grey-clr;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.ant-dropdown-menu-item {
  padding: 0;
}

.filter-ddown {
  &.ant-dropdown {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ant-select-arrow {
    cursor: pointer;
    top: 13px;
  }

  &.ant-dropdown .ant-dropdown-menu-item svg,
  .ant-dropdown .ant-dropdown-menu-submenu-title svg {
    font-size: 15px;
    margin: 0;
  }

  .ant-select-arrow {
    cursor: pointer;
    top: 10px;
  }

  &.ant-dropdown {
    .ant-select {
      .ant-select-arrow {
        svg {
          color: rgba(17, 23, 62, 1);
          width: 11px;
          height: 17px;
        }
      }
    }
  }
}

.filrow {
  flex-wrap: nowrap;
  padding: 0 10px 10px;

  svg {
    font-size: 13px !important;
    font-weight: bold;
    color: $secondary;
    margin-right: 10px;
  }

  .ant-col {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
    margin: 0 4px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  input[type='text'] {
    border: 1px solid rgba(231, 232, 235, 1);
    border-radius: 4px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  input[type='text'] {
    color: $sub-heading;
  }

  .query {
    color: #11173e;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: left;
    font-weight: 500;
    width: 70px;

    .anticon-close {
      background-image: url(../assets/images/blackclose.png);
      height: 15px;
      width: 15px;
      background-repeat: no-repeat;
      background-size: 10px;
      margin-top: 5px;
      display: inline-block;

      svg {
        display: none;
      }
    }
  }
}

.sort-dropdown {
  .anticon-close {
    background-image: url(../assets/images/blackclose.png);
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    background-size: 10px;
    margin-top: 5px;
    margin-right: 5px;
    display: inline-block;

    svg {
      display: none;
    }
  }
}

.column-ddown,
.filter-ddown {
  .ant-dropdown-menu > li:hover {
    background: transparent;
  }
}

.sort-dropdown {
  .ant-dropdown-menu {
    padding-left: 15px !important;
    padding-right: 15px !important;

    .ant-dropdown-menu-item .ant-row {
      margin-bottom: 10px;
    }
  }
}

.zindex {
  &.ant-dropdown,
  &.ant-select-dropdown {
    z-index: 9;
  }
}

.ant-menu .ant-menu-item button {
  color: #868994 !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.ant-menu .ant-menu-item button span {
  padding-left: 25px;
}

.ant-menu .ant-menu-item:hover button,
.ant-menu .ant-menu-item:focus button,
.ant-menu .ant-menu-item.ant-menu-item-selected button,
.ant-menu .ant-menu-item.ant-menu-item-active button,
.ant-menu .ant-menu-item.ant-menu-item:active button,
.ant-menu .ant-menu-item.ant-menu-submenu-title:active button {
  color: #ffffff !important;
  font-weight: bold;
}

.column-ddown {
  .ant-input {
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    background-color: $grey;
    color: rgba(17, 23, 62, 0.44);
  }

  .ant-list-bordered {
    border: none;
    max-height: 250px;
    overflow: auto;
    margin-bottom: 45px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .action-row {
    .ant-btn {
      display: flex;
      align-items: center;
    }
  }

  .ant-list-item {
    cursor: move;

    &.mitem {
      padding: 0 !important;
      border: none;
    }

    .ant-list-item {
      padding: 12px 0 !important;
      border-bottom: 1px solid #f0f0f0 !important;
      width: 100%;
    }
  }

  .action-row {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    padding: 5px 10px !important;

    .ant-btn.ant-btn-loading {
      display: flex;
    }
  }

  .ant-list-bordered.ant-list-sm .ant-list-item {
    padding: 5px 0;
  }

  .ant-list-items {
    margin-top: 10px;

    .ant-list-item {
      color: $secondary;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
    }

    .ant-switch-small {
      min-width: 18px;
      height: 12px;
      line-height: 12px;
      width: 18px;
      margin-right: 10px;
    }

    .ant-switch-small.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 8px - 2px);
    }

    .ant-switch-small .ant-switch-handle {
      width: 8px !important;
      height: 8px !important;
    }

    .ant-switch {
      background-color: #868994;
    }

    .ant-switch-checked {
      background-color: $primary;
    }
  }
}

.head-ddown {
  &.ant-select-dropdown {
    z-index: 9999;
  }
}

.ant-dropdown {
  .nodata {
    padding: 5px 10px 15px;
  }

  .action-row {
    padding: 10px 12px 0;
    border-top: 1px solid #e7e8eb;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    .dragging {
      font-size: 11px;
      color: #d0d0d0;
      margin-left: 5px;
    }

    &:hover,
    &.ant-dropdown-menu-item-active {
      background-color: rgba(84, 99, 210, 0.16) !important;
    }
  }

  &.mesg-down {
    .ant-dropdown-menu-item-active {
      background-color: transparent !important;
    }

    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      &:not(.ant-select-item-option-disabled),
      &:not(.ant-select-item-option-disabled) {
        background-color: transparent !important;
      }
    }
  }
}

.ant-input-number-disabled {
  border: 1px solid #e7e8eb !important;
}

header {
  .ddown {
    .ant-select-arrow {
      font-size: 16px;
    }
  }
}

.ddown {
  .ant-select.ant-select-single.ant-select-show-arrow {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: $primary;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%) $primary;
    color: $white;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $primary;
    border: 1px solid $primary;
    height: 38px;

    &:hover,
    &:focus {
      background-color: #5c6bda;
      border-color: #5c6bda;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 0 0 11px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 40px;
  }

  .ant-select-arrow {
    color: #fff;
    font-size: 14px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 38px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    top: 4px;
    right: 0;
  }

  .ant-select {
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }

  &.sm-ddown {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 32px;
    }

    .ant-select-arrow {
      height: 32px;
      width: 32px;
    }

    .ant-select {
      font-size: $font-size-base-sm;
    }

    .ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 32px;
    }
  }
}

.tdown,
.timezone {
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }

  // .css-1fhf3k1-control, .css-yk16xz-control {min-height: 32px;}
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    background-image: url('../assets/images/sort-down.png');
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    background-size: 10px;
    margin-top: 5px;

    svg {
      display: none;
    }
  }

  .css-yt9ioa-option {
    font-weight: 500;
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option {
    background: rgba(84, 99, 210, 0.16) !important;
    font-weight: 600 !important;
    color: $secondary;
  }

  // .css-1bwsx9e-Input {margin: 0 !important; padding-bottom: 0 !important; padding-top: 0 !important;}
  // &.timezone  .css-yk16xz-control .css-1uccc91-singleValue {top: 15px !important;}
  .css-b8ldur-Input,
  .css-1bwsx9e-Input,
  .css-1g6gooi,
  .css-mry1rc {
    margin: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .css-yk16xz-control,
  .css-1fhf3k1-control {
    min-height: 32px !important;
  }

  &.ant-select-multiple {
    .css-1rhbuit-multiValue {
      margin-top: 0 !important;

      .css-12jo7m5 {
        padding: 0 3px !important;
        font-weight: 500;
        font-size: 14px;
      }
    }

    &.prefix-ico {
      .css-12jo7m5 {
        padding-left: 35px !important;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: 10px center;
      }
    }

    .css-xb97g8:hover {
      background-color: transparent !important;
      color: #de350b;
    }
  }
}

.ant-select-single {
  &.ant-select-open {
    .ant-select-selection-item {
      color: inherit;
    }
  }

  &.overflow-multiselect {
    &.ant-select-open {
      .ant-select-selection-item {
        color: $color-base;
      }
    }
  }
}

.ant-select-item-option-content {
  white-space: normal !important;
  word-break: break-word;
  word-wrap: break-word;
}

.user-ddown {
  display: flex;
  align-items: center;

  .ant-btn {
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 135px;
    padding: 0;

    &:hover,
    &:focus {
      border-color: transparent;
      color: $primary;
    }

    svg {
      color: rgba(134, 137, 148, 1);
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .username {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: $color-base;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: right;

    small {
      display: block;
      color: rgba(17, 23, 62, 0.74);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: right;
    }

    .ellipsis-text {
      max-width: 125px;
    }
  }

  .profile-wrap {
    margin-left: 20px;
  }
}

/*Notification*/

.notification {
  margin-right: 25px;
  position: relative;

  .ndot {
    right: -2px;
    top: -2px;
  }

  svg {
    color: #373c5d;
    font-size: 17px;
    cursor: pointer;
  }

  &.active {
    svg {
      color: $grey-clr;
      font-size: 24px;
    }

    .ndot {
      right: -6px;
      top: -5px;
      height: 15px;
      width: 15px;
    }
  }
}

.ant-notification {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .ant-notification-notice {
    border: 1px solid rgba(201, 222, 203, 1);
    border-radius: 4px;
    padding: 10px 15px;
    width: 625px;
    background-color: #eff7f0;

    .anticon svg {
      font-size: 15px;
    }
  }

  .ant-notification-notice-icon {
    color: rgba(134, 137, 148, 1);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    line-height: 15px;

    svg {
      font-size: 15px;
      margin-top: 2px;
    }
  }

  .ant-notification-notice-message {
    margin-left: 35px;
    color: $color-base;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .ant-notification-notice-description {
    color: rgba(17, 23, 62, 0.74);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 35px;
  }

  .error-notification {
    background-color: #f5cbcb;

    .ant-notification-notice {
      border: 1px solid #e47676;
      border-radius: 4px;
      padding: 10px 15px;
      width: 625px;
      background-color: rgba(216, 53, 53, 0.4);

      .anticon svg {
        font-size: 15px;
      }
    }

    .ant-notification-notice-icon {
      color: $danger;
    }
  }

  .success-notification {
    background-color: #eff7f0;

    .ant-notification-notice {
      border: 1px solid rgba(201, 222, 203, 1);
      border-radius: 4px;
      padding: 10px 15px;
      width: 625px;
      background-color: #eff7f0;

      .anticon svg {
        font-size: 15px;
      }
    }

    .ant-notification-notice-icon {
      color: $success;
    }
  }
}

.ant-alert {
  border-radius: 4px !important;
  width: auto;
  max-width: 510px;
  padding: 12px 15px 12px 24px !important;
  margin-top: 15px !important;

  .ant-alert-icon {
    font-size: 16px !important;
  }

  .alert-close-icon {
    color: #868994;
    cursor: pointer;
    margin-top: 3px;
  }

  .ant-alert-content {
    padding-right: 20px;

    .ant-alert-message {
      color: rgba(17, 23, 62, 0.74);
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 0 !important;
    }

    .ant-alert-description {
      color: rgba(17, 23, 62, 0.74);
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: 400;

      .ant-alert-icon {
        margin-right: 25px;
      }

      .primary-link {
        font-weight: 400;
      }
    }
  }

  &.ant-alert-error {
    background-color: #fcf1f1;
    border: 1px solid #ffcaca !important;

    .ant-alert-icon {
      color: $danger;
    }
  }

  &.ant-alert-success {
    background-color: rgba(22, 143, 37, 0.1);
    border: 1px solid $success !important;

    .ant-alert-icon {
      color: $success !important;
    }
  }

  &.ant-alert-info {
    background-color: #e3e6f8;
    border: 1px solid $primary !important;

    .ant-alert-icon {
      color: $primary !important;
    }
  }

  &.alert-flex {
    svg {
      margin-top: 5px;
    }

    .ant-alert-content {
      display: flex;

      .ant-alert-message {
        margin-right: 15px;
      }
    }
  }
}

.ndot {
  position: absolute;
  background: $danger;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  border: 1px solid $white;
}

.notification-overview {
  .ant-alert {
    width: 100%;
    max-width: 100%;
  }
}

.md-alert {
  max-width: 560px;
}

.alertmsg {
  b {
    margin: 0 20px 0 15px;
  }
}

.error-control {
  .fitem,
  &.fitem {
    label {
      color: $danger;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-number-input-wrap input {
    border: 1px solid $danger !important;
    box-shadow: 0px 0px 2px 2px rgba(216, 53, 53, 0.15);
  }
}

/*Ant dropdown*/
.ant-dropdown {
  border: 1px solid #e7e8eb;
  border-radius: 4px;
  background-color: #ffffff;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    padding: 5px 10px;

    svg {
      font-size: 18px;
      color: $grey-clr;
    }
  }

  &.mesg-down {
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgba(84, 99, 210, 0.16) !important;

      .ant-dropdown-menu-title-content {
        font-weight: 600;
      }
    }
  }
}

.ant-dropdown-menu {
  > .ant-dropdown-menu-item {
    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }
}

.sort-dropdown {
  .ant-dropdown-menu-item {
    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }
}

button.ant-btn.ant-dropdown-trigger {
  box-shadow: none !important;
}

/*Profile wrap*/
.profile-wrap {
  figure {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-bottom: 0;

    img {
      border-radius: 100%;
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.md-profile {
    figure {
      height: 80px;
      width: 80px;
      margin-bottom: 0;
    }
  }

  &.sm-profile {
    figure {
      height: 30px;
      width: 30px;
      margin-bottom: 0;
    }
  }

  &.xs-profile {
    figure {
      height: 20px;
      width: 20px;
      margin-bottom: 0;
    }
  }
}

.ant-tooltip {
  .profile-wrap {
    img {
      border: 1px solid $white;
    }
  }
}

.lien-input {
  width: 100%;
  padding: 4px 4px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

/*ant card*/
.ant-card {
  border-radius: 6px !important;
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);
  border: none !important;

  .ant-card-body {
    color: rgba(17, 23, 62, 0.74);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;

    ol,
    ul {
      list-style-position: inside;
    }
  }
}

.card-header {
  padding: 25px 25px 30px;

  .left-cap {
    .ant-select .ant-select-selection-item,
    .ant-select {
      cursor: pointer;
    }
  }

  .right-cap {
    display: flex;
    align-items: center;

    .css-1pahdxg-control {
      border-color: $primary !important;
      box-shadow: none !important;
      min-height: 32px !important;
      max-height: 32px !important;
    }

    .css-1gtu0rj-indicatorContainer,
    .css-tlfecz-indicatorContainer {
      width: 20px !important;
    }

    .search-btn {
      .ant-input-suffix {
        margin-left: 4px;
        position: absolute;
        right: 33px;
        z-index: 9;
        top: 9px;
      }

      input.ant-input {
        width: 75%;
      }
    }

    .ddown {
      .ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 32px;
        }
      }

      .ant-select-single {
        .ant-select-selector {
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            line-height: 32px;
          }
        }

        &.ant-select-open {
          .ant-select-selection-item {
            color: $white;
          }
        }
      }

      .ant-select-arrow {
        height: 32px;
      }
    }

    .tdown,
    .timezone {
      .css-26l3qy-menu {
        z-index: 999;
      }
    }
  }
}

.card-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  hr {
    margin: 25px -24px;
    background: rgba(231, 232, 235, 1);
    height: 1px;
    border: none;
  }

  .card-col {
    max-width: calc(100% - 290px);
    width: calc(100% - 290px);
    display: flex;
    flex-direction: column;
  }

  .card-sm-col {
    flex: auto;
    width: 265px;
    max-width: 265px;
    min-width: 265px;
  }

  .scard {
    margin-bottom: 25px;

    .ant-card-head {
      .primary-link {
        font-size: 12px;

        svg {
          font-size: 12px;
        }
      }

      .ant-card-head-title {
        .cheading {
          margin-left: 0;
        }
      }
    }
  }

  .card-tbl {
    .crow:first-child {
      border-top: none;
      padding-top: 0;
    }
  }
}

.summary-col {
  .ant-card-body {
    padding-bottom: 0;
  }
}

/*container*/
.block-row {
  padding: 30px;
}

.ant-input-search .ant-input-affix-wrapper {
  border: 1px solid rgba(231, 232, 235, 1);
  border-radius: 4px !important;
  background-color: rgba(250, 250, 251, 1);
}

.ant-searchbtn {
  .ant-input-group-wrapper {
    border-color: rgba(231, 232, 235, 1);
    border-radius: 4px;

    .ant-input-affix-wrapper > input.ant-input {
      color: rgba(17, 23, 62, 0.44);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
    }

    .ant-input-search-button {
      max-width: 32px;
      width: 32px;
      padding: 5px 5px;
      text-align: center;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid $primary;
      border-radius: 4px;
      background-color: $primary;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%) $primary;
      color: #fff;
    }
  }

  .ant-input-group .ant-input {
    background: rgba(250, 250, 251, 1);
  }

  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    max-width: 32px;
    width: 32px;
    padding: 5px 5px !important;
    text-align: center;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
    background-color: $primary;
    color: #fff !important;

    &:hover,
    &:focus {
      color: $primary !important;
      border-color: $primary !important;
      background: $white !important;
    }

    > .anticon {
      font-size: 12px !important;
    }
  }

  &.ant-input-search {
    > .ant-input-group {
      > .ant-input-group-addon {
        &:last-child {
          position: absolute;
          right: 28px;
          top: 3px;
          left: auto;
          z-index: 9;
        }
      }
    }
  }

  .ant-input-search-button {
    max-width: 25px !important;
    width: 25px !important;
    height: 25px !important;

    .ant-btn-icon-only > * {
      font-size: 12px !important;
    }
  }
}

/*ant tables*/
.no-data {
  svg {
    font-size: 24px;
    color: rgba(231, 232, 235, 1);
  }

  div {
    color: rgba(112, 116, 139, 1);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 15px;
  }
}

.ellipsis-text {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.ant-table-wrapper {
  .no-data {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &.doctbl {
    .no-data {
      height: auto;
    }
  }

  .cellinfo {
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
  }

  // .ant-table-row-expand-icon-cell { width: 45px !important; min-width: 45px !important; padding-right: 10px !important; }
  .ant-table-column-sort {
    background: transparent;
  }
}

.ant-table-thead {
  th {
    background: #fff !important;
    font-weight: 600 !important;
    vertical-align: bottom;

    span {
      color: $color-base;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;

      svg {
        color: rgba(208, 208, 208, 1);
      }

      .anticon {
        line-height: 8px !important;
        display: inline-block;
        font-size: 12px !important;
      }
    }

    &.ant-table-cell {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  tr:first-child > th:first-child {
    background-color: $white !important;
  }

  > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }
}

.ant-table-wrapper {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $primary;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: none;
    border-radius: 10px;
  }

  .ant-table-column-sorters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.ant-table-thead {
  > tr > th {
    border-bottom: 1px solid #e7e8eb !important;
  }
}

.ant-table-tbody {
  > tr {
    &.alphabet-row {
      td {
        height: 20px;
        padding: 0 45px !important;
        background: #f1f1f3 !important;

        .alphabet {
          display: block;
          padding: 0 10px;
        }
      }
    }

    > td {
      border-bottom: 1px solid #f0f0f0 !important;

      .avatar-info {
        display: flex;
        align-items: center;

        .ant-avatar {
          margin-right: 25px;
        }
      }
    }

    &.ant-table-row {
      > td {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:first-child {
          border-left: 2px solid transparent;
          border-radius: 4px 0 0px 4px;
        }

        &:last-child {
          border-right: 2px solid transparent;
          border-radius: 0 4px 4px 0;
        }

        &.cvalue {
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap !important;
        }
      }

      &.active {
        > td {
          background: #eeeffc !important;
          border-top: 2px solid rgba(84, 99, 210, 1);
          border-bottom: 2px solid rgba(84, 99, 210, 1) !important;

          &:first-child {
            border-left: 2px solid rgba(84, 99, 210, 1);
            border-radius: 4px 0 0px 4px;
          }

          &:last-child {
            border-right: 2px solid rgba(84, 99, 210, 1);
            border-radius: 0 4px 4px 0;
          }
        }
      }

      &:hover,
      &:focus {
        > td {
          background: #eeeffc !important;
        }
      }

      td {
        color: $secondary;
        font-family: 'Montserrat', 'Arial' !important;
        font-size: 14px !important;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px !important;
        vertical-align: middle;
      }
    }
  }

  .ant-table-row:nth-child(even) td {
    background-color: $grey !important;
  }

  .ant-table-cell {
    color: $color-base;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;

    * {
      font-style: normal;
    }
  }
}

// .ant-select-borderless {
//   .ant-select-single {
//     .ant-select-show-arrow {
//     }
//   }
// }

.footer-select {
  .ant-select-single {
    .ant-select-selector {
      border: none;

      .ant-select-selection-item {
        color: #5463d2;
        font-weight: bold;
        //width: 120px;
        margin-right: auto;
      }
    }

    &:hover,
    &.ant-select-open {
      .ant-select-selector {
        .ant-select-selection-item {
          color: #11173e;
        }
      }

      .ant-select-arrow {
        svg {
          color: #11173e;
        }
      }
    }
  }

  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding-left: 0;
    }
  }

  .ant-select-arrow {
    svg {
      color: #5463d2;
      font-size: 15px;
    }
  }
}

.alorder {
  display: flex;
  align-items: center;

  .capitalize {
    padding: 0 0 0 15px;
    font-size: 12px;
    color: $grey-link;
  }

  > span {
    color: $brand-color;
    font-weight: 600;
  }
}

.removable-tbl {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    width: 185px;
    min-width: 185px;

    &.rightalign {
      width: 185px;
      min-width: 185px;
    }

    &:first-child {
      width: 340px;
      min-width: 340px !important;
    }
  }

  .ant-table-summary {
    position: sticky;
    bottom: 0;
  }
}

.dashtbl {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-align: left;

    padding-right: 12px !important;
    padding-left: 12px !important;

    &.rightalign {
      //text-align: right !important;
    }

    &:first-child {
      padding-left: 15px !important;
    }

    &:last-child {
      padding-right: 24px !important;
    }
  }

  .ant-table-body {
    max-height: calc(100vh - 420px) !important;
    overflow-y: auto !important;
  }

  // tr.ant-table-measure-row {
  //     display: none !important;
  // }

  .ant-table-column-sorters-with-tooltip {
    vertical-align: bottom;
  }

  // PATCH NOTE: Bellow `.ant-checkbox-checked` class is
  // just added since checking from multiselect table is not adding some css
  // classes, if it makes any issue after version update remove this class
  .ant-checkbox-checked,
  .ant-checkbox-indeterminate {
    position: relative;

    &:after {
      background-image: url('../assets/images/check-mark.svg');
      background-size: 100%;
      height: 10px;
      width: 12px;
      content: '';
      position: absolute;
      left: 2px;
      z-index: 9;
      animation: none;
      background-repeat: no-repeat;
      visibility: visible !important;
      top: 3px;
      border: none !important;
    }
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary !important;
}

.ant-table-column-sorters {
  padding: 0 0 !important;
}

.doctbl {
  &.bg-tbl table {
    background: #fff !important;
  }

  .ant-table-body {
    overflow: auto !important;
    overflow-x: hidden !important;
  }

  .ant-table-thead tr:first-child > th:first-child {
    background-color: transparent !important;
  }

  .form-group .ant-input-affix-wrapper .ant-input-suffix {
    height: 21px;
    padding-right: 0;

    .btn {
      margin-left: 5px;
      padding: 3px 8px !important;
    }
  }

  .ant-table-tbody {
    > tr.ant-table-row {
      td {
        text-transform: capitalize;

        a {
          color: $secondary;
        }
      }

      .doc-name {
        svg {
          color: $primary;
          font-size: $font-size-base;
          margin-right: 10px;

          &.fa-folder {
            color: $grey-clr;
          }
        }
      }
    }
  }

  .ant-table-column-sorters {
    margin-left: 0;
  }

  &.ant-table-wrapper {
    .ant-table-column-sorters {
      justify-content: flex-start;
    }

    .ant-table-thead {
      th {
        &.ant-table-column-has-sorters {
          &:hover {
            background: $white !important;
          }
        }
      }
    }
  }

  .ant-table-thead {
    .maincol {
      position: relative !important;
      left: -40px !important;
    }
  }
}

.maincol {
  .primary-link {
    max-width: calc(100% - 378px);
  }

  .doc-name {
    display: flex;
    align-items: center;
  }
}

.damages-tbl {
  table {
    table-layout: fixed;
  }

  .ant-card-body {
    padding: 24px 0;
  }

  .ant-table-thead {
    > tr {
      > th {
        font-weight: 600;
      }
    }
  }

  .ant-table-expanded-row > td.ant-table-cell {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px 0 -16px 0;
  }

  .ant-table-expanded-row thead.ant-table-thead {
    display: none;
  }

  // .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td:first-child {
  //     width: 15px;
  // }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    width: 20% !important;
    max-width: 20% !important;
    min-width: 20% !important;

    &:first-child {
      // width: 5% !important;
      // max-width: 5% !important;
      // min-width: 5% !important;

      width: 65px !important;
      max-width: 65px !important;
      min-width: 65px !important;
    }

    &:nth-child(2) {
      width: 35% !important;
      max-width: 35% !important;
      min-width: 35% !important;
    }
  }

  .inner-cell {
    p {
      font-size: 12px;
      font-weight: 500;
    }

    svg {
      color: $primary;
    }
  }

  .ant-table-row-expand-icon-cell svg {
    color: $primary;
  }

  .ant-table-expanded-row {
    .ant-table-tbody {
      .ant-table-cell {
        font-weight: 500;
      }
    }
  }

  .ant-table-body {
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }

  .drow {
    padding-bottom: 15px;

    .dcell {
      span {
        white-space: nowrap;
      }

      &:first-child {
        padding-left: 76px;
        min-width: 340px;
        max-width: 340px;
      }
    }
  }
}

.liens-table {
  .ant-table-expanded-row > td.ant-table-cell {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-thead th .ant-table-column-sorter-inner {
    margin-left: 5px;
  }

  .ant-table-body {
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }

  .ant-table-row-expand-icon-cell svg {
    color: $primary;
    cursor: pointer;
  }

  .ant-table-tbody .ant-table-row:nth-child(even) td {
    background-color: transparent !important;
  }

  .ant-table-tbody .ant-table-row.table-row-dark td {
    background-color: $white !important;
  }

  .ant-table-tbody .ant-table-row.table-row-light td {
    background-color: $grey !important;
  }

  .ant-table-expanded-row {
    box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);
    td {
      background-color: $white;
    }

    p {
      color: rgba(17, 23, 62, 0.74);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      padding-left: 50px !important;
      margin-bottom: 0;
    }
  }

  .ant-table-thead th,
  .ant-table-body td {
    padding: 15px 10px !important;
    width: 135px;
    max-width: 135px;
    min-width: 135px;

    &:last-child {
      padding-right: 25px !important;
    }
  }

  tr.ant-table-measure-row {
    display: none !important;
  }

  .ant-table-thead > tr:first-child > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    text-align: left;
    min-width: 50px;
    width: 50px;
  }

  &.ECodeColumns {
    .ant-table-thead,
    .ant-table-tbody {
      > tr {
        > th,
        > td {
          &.amtprice {
            padding-right: 35px !important;
            text-align: right;
          }
        }
      }
    }

    + .add-claim-damage {
      width: 100% !important;

      .drow {
        width: 100% !important;
        display: flex;
        justify-content: space-between;

        .dcell {
          &:first-child {
            padding-left: 60px !important;
          }

          &.sm-cell {
            width: 180px;
            max-width: 125px;
            text-align: right;
          }
        }
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 15px 10px !important;

    &:nth-child(2) {
      padding-left: 0 !important;
    }
  }
}

.negtbl {
  .ant-table-thead {
    th {
      &.ant-table-cell {
        color: rgba(17, 23, 62, 0.9);
        font-size: $font-size-base;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }

  .liens-table .ant-table-expanded-row p {
    padding-left: 55px !important;
  }

  .ant-table-thead,
  .ant-table-tbody {
    > tr.ant-table-ro:not(.ant-table-expanded-row),
    tr:not(.ant-table-expanded-row) {
      > td:first-child,
      th:first-child {
        padding: 0 5px 0 12px;
      }

      td,
      th {
        &:nth-child(2) {
          padding-left: 0 !important;
          overflow-wrap: unset;
        }
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 12px;
  }

  .prop-info {
    margin-bottom: 0;
    width: 250px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .comma {
      @extend .comma;
    }
  }

  .prop-date {
    color: rgba(17, 23, 62, 0.74);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 8px;

    svg {
      color: $grey-clr;
      margin-right: 5px;
    }
  }

  .up-downprice {
    text-align: right;

    svg {
      color: $primary;
      font-size: 11px;
    }
  }
}

.tblpad {
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }

  .form-group {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.ant-modal-body {
  .add-budget {
    &.ECodeColumns {
      + .add-claim-damage {
        .drow {
          .dcell {
            &:first-child {
              padding-left: 0px !important;
            }
          }
        }
      }
    }
  }
}

.reject_req {
  .hrow {
    padding-bottom: 15px;
    margin-bottom: 15px;

    &:last-child {
      border-bottom: 1px solid #e7e8eb !important;
      margin-bottom: 30px;
    }
  }
}

.create-task {
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-content .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;

    &:after {
      left: 25%;
    }
  }

  .ant-checkbox-checked,
  .ant-checkbox-indeterminate {
    &:after {
      display: none;
    }
  }
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: transparent !important;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: transparent !important;
}

th.ant-table-cell.ant-table-cell-scrollbar {
  display: none !important;
}

ul.ant-pagination {
  padding: 10px 25px;
  text-align: right;

  .ant-select-item {
    font-size: 13px !important;
  }
}

.ant-pagination-total-text {
  opacity: 0.74;
  color: #11173e;
  font-size: 14px;
  letter-spacing: 0;
  text-align: right;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: $primary;
  font-weight: bold;
  font-size: 14px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 20px;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: #fff !important;
}

.srv-validation-message {
  font-size: 11px;
}

.ant-input-column {
  padding: 10px;
  display: inline-block;
}

// .choosecol {padding: 0 10px !important}

.ant-table-tbody > tr.ant-table-row:focus,
.ant-table-tbody > tr.ant-table-row:hover,
.ant-table-tbody > tr.ant-table-row.active {
  border: 1px solid rgba(84, 99, 210, 1) !important;
}

.ant-drawer {
  border-radius: 6px;
  background-color: transparent;

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);
    }
  }

  .ant-drawer-content-wrapper {
    border: 1px solid #e7e8eb;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);
  }

  .ant-drawer-body {
    overflow: revert;
    padding-right: 20px;

    .ant-row {
      a {
        color: $primary;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;

        svg {
          font-size: 15px;
        }

        &:hover,
        &:focus {
          color: $secondary;
        }
      }
    }

    hr {
      margin: 25px -24px;
      background: rgba(231, 232, 235, 1);
      height: 1px;
      border: none;
    }
  }

  .ant-drawer-close {
    display: flex;
    font-size: 14px;
    padding: 25px 20px;
    position: absolute;
    z-index: 10;

    p {
      margin-right: 5px;
      margin-bottom: 0;
    }
  }

  .redlink {
    display: flex;
    font-size: 14px;
    color: $color-base;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;

    p {
      margin: 0 5px 0 0;
    }
  }

  .ant-avatar {
    width: 46px !important;
    height: 46px !important;
    line-height: 46px !important;
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    font-weight: 600;
  }

  .drawer-head {
    padding-left: 15px;

    .mhead {
      margin-bottom: 5px !important;
    }
  }

  .hleftt {
    .etitle {
      max-width: 185px;
      font-size: $font-size-base;
      margin-right: 80px;
    }

    .hrow {
      margin-bottom: 15px;
      padding-bottom: 15px;

      .edetail {
        font-size: $font-size-base;
        line-height: 20px;
      }
    }
  }

  &.notification-drawer {
    transform: translate(0, 0);

    &.ant-drawer {
      .ant-drawer-body {
        overflow: hidden !important;
        transform: translate(0, 0);

        hr {
          margin: 25px 0;
        }
      }

      .ant-drawer-content-wrapper {
        top: 65px;
        max-height: 455px;
      }

      .ant-drawer-content {
        border-radius: 6px;
      }
    }

    .ant-tabs {
      .ant-tabs-nav {
        box-shadow: none;
        border-top: none;
        padding: 0 25px;
        border-bottom: 1px solid #e7e8eb;

        .ant-tabs-tab {
          padding: 0 25px 10px;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $primary !important;
            }
          }
        }
      }

      .ant-tabs-nav-list {
        padding: 0;
      }

      &.ant-tabs-top > .ant-tabs-nav::before,
      &.ant-tabs-bottom > .ant-tabs-nav::before,
      &.ant-tabs-top > div > .ant-tabs-nav::before,
      &.ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: none;
      }
    }

    .ant-drawer-body {
      padding: 25px 0;

      h3 {
        padding-left: 25px;
      }

      .nline {
        padding: 9px 25px;
        border-bottom: 1px solid #e7e8eb;
        text-align: right;

        span,
        a {
          padding: 0 12px;
          font-size: $font-size-base-sm;
          font-weight: bold;
          letter-spacing: 0;
          color: $color-base;
          line-height: 16px;
          cursor: pointer;

          &:hover,
          &:focus,
          &.active {
            color: $primary;
          }
        }
      }
    }
  }
}

.claim-drawer {
  width: 640px !important;
  z-index: 99 !important;
  position: fixed !important;
  top: 0 !important;
  bottom: 0;

  .ant-drawer-wrapper-body {
    position: relative;
    transform: translate(0, 0);
  }

  .ant-drawer-content {
    overflow-x: hidden;
  }

  // &.ant-drawer-right {
  //     .ant-drawer-content-wrapper {
  //         height: calc(100% - 50px);
  //     }
  // }
}

.page-tab-detail {
  .task-drawer {
    .ant-tabs-nav {
      width: 100%;
      overflow: hidden;
      margin-top: 30px;

      .ant-tabs-tab {
        min-width: 135px;
        text-align: center;
        justify-content: center;
        margin-bottom: 8px;

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $primary !important;
            font-weight: 600;
          }
        }
      }
    }

    .notify-ico .ndot {
      right: -10px;
    }
  }
}

.ant-tag {
  color: rgba(17, 23, 62, 0.84);
  border: 1px solid rgba(64, 153, 75, 0.3);
  border-radius: 12px;
  background-color: rgba(192, 219, 198, 0.3);
  &.ant-tag-success {
    border: 1px solid #ced3f9;
    border-radius: 12px;
    background-color: #f3f4fc;
    color: $secondary;
    line-height: 16px;
    font-weight: 500;
    text-transform: capitalize;
  }

  &.ant-tag-green {
    border: 1px solid rgba(64, 153, 75, 0.3);
    border-radius: 12px;
    background-color: rgba(192, 219, 198, 0.3);
    color: rgba(17, 23, 62, 0.84);
    line-height: 16px;
  }

  &.ant-tag-no-access {
    border: 1px solid rgba(208, 208, 208, 1);
    border-radius: 12px;
    background-color: rgba(250, 250, 251, 1);
    color: rgba(17, 23, 62, 0.84);
    line-height: 16px;
  }

  &.ant-tag-closed {
    border: 1px solid rgba(208, 208, 208, 1);
    border-radius: 12px;
    background-color: rgba(250, 250, 251, 1);
    color: rgba(17, 23, 62, 0.84);
    margin-left: 15px;
  }
}

.ant-table-body {
  .ant-tag {
    &.ant-tag-success {
      border: 1px solid #d0d0d0;
      background-color: #fafafb;
    }
  }

  .ant-avatar-string {
    font-size: 12px;
  }
}

.ant-avatar {
  &.md-avatar {
    .ant-avatar-string {
      font-size: 20px !important;
      line-height: 45px !important;
    }
  }

  &:not(.sm-avatar) {
    .ant-avatar-string {
      font-size: 18px !important;
      line-height: 45px !important;
    }
  }

  &.sm-avatar {
    .ant-avatar-string {
      font-size: 12px !important;
    }
  }
}

.ant-avatar-string {
  color: $white !important;
  font-weight: 600 !important;
  line-height: 25px !important;
}

/*Alerts*/
.alert-row {
  .ant-alert,
  &.ant-alert {
    margin-bottom: 10px;
    border: 1px solid #ffeb9b;
    border-radius: 4px;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;

    &.ant-alert-error {
      border-color: $danger;
    }

    .ant-alert-content {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .ant-alert-message {
        margin: 0 25px 0 0 !important;
        color: $color-base;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
      }

      .ant-alert-icon {
        font-size: 16px;
        color: rgba(251, 200, 0, 1);
        margin-top: 3px;
      }

      .ant-alert-description {
        color: rgba(17, 23, 62, 0.74);
        white-space: normal;
        word-break: break-word;
        word-wrap: break-word;
      }
    }

    .ant-alert-action {
      color: $primary;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      text-align: right;
      cursor: pointer;
      margin-right: 15px;
      margin-top: 5px;

      svg {
        margin-left: 15px;
        color: #868994;
        font-size: 16px;
      }

      &:hover,
      &:focus {
        color: $secondary;
      }
    }

    .ant-alert-close-icon .anticon-close {
      background-image: url('../assets/images/close.png');
      height: 15px;
      width: 15px;
      background-repeat: no-repeat;
      background-size: 10px;
      margin-top: 5px;

      svg {
        display: none;
      }
    }
  }

  .ant-alert-with-description {
    .ant-alert-icon {
      font-size: 16px;
    }

    .ant-alert-message {
      font-size: 14px;
    }
  }
}

.claimdetails {
  h2 {
    opacity: 0.9;
    color: #11173e;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
  }

  small {
    opacity: 0.74;
    color: #11173e;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
  }

  p {
    margin-top: 24px;
    opacity: 0.74;
    color: #11173e;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .labels {
    border: none;
    border-radius: 4px;

    .lrow {
      padding: 10px 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .card-tbl {
    margin-top: 0;

    .crow {
      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }
}

.cldetail {
  /* opacity: 0.9; */
  color: $color-base;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

/*Ant modal*/
.ant-modal-confirm {
  width: 600px !important;

  .ant-modal-body {
    padding: 0 !important;

    .ant-modal-confirm-body {
      padding: 0 !important;

      .anticon-exclamation-circle {
        display: none;
      }
    }
  }

  .ant-modal-confirm-btns {
    margin-top: 20px;
    padding-right: 20px;
  }

  .ant-modal-content {
    .ant-alert {
      margin: 0 !important;
    }
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }

  .ant-modal-confirm-title,
  .ant-modal-confirm-content {
    padding: 25px !important;
  }

  .ant-modal-confirm-title {
    border-bottom: 1px solid #e7e8eb;
  }

  .ant-modal-confirm-content {
    .ant-alert {
      align-items: flex-start !important;
      max-width: 100%;
    }

    .ant-alert-icon {
      margin-top: 5px;
    }

    .ant-alert-content {
      padding-right: 0;
      padding-left: 10px;
    }
  }
}

.ant-modal-content,
.ant-modal-confirm {
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);

  .ant-modal-header,
  .ant-modal-confirm-body {
    padding: 30px;
    color: $color-base;
    border-bottom: 1px solid #e7e8eb;
    border-radius: 6px 6px 0 0;

    .ant-modal-title,
    .ant-modal-confirm-title {
      color: rgba(14, 22, 74, 0.9);
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;

      small {
        color: rgba(17, 23, 62, 0.74);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }

  .ant-modal-header {
    h1 {
      margin-bottom: 0 !important;
    }
  }

  .ant-modal-body,
  .ant-modal-confirm-content {
    padding: 30px;

    p {
      color: rgba(9, 13, 41, 0.84);
      font-weight: 500;
      line-height: 20px;
      white-space: normal;
      font-style: normal;
    }

    .btn,
    .ant-btn {
      padding: 10px 15px !important;
      height: 40px;
      font-size: 14px !important;
      line-height: 18px;
      margin: 0 7px !important;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.sm-btn {
        @extend .sm-btn;
      }

      &.smm-btn {
        @extend .smm-btn;
      }
    }

    .ant-radio-wrapper {
      display: flex;
      // align-items: flex-start;
      font-size: 14px;
      font-weight: 500;
    }

    .modal-footer {
      padding: 35px 0 20px;
    }

    .ant-radio-group {
      h5 {
        color: $secondary;
      }
    }

    hr {
      margin: 30px -30px;
      background: rgba(231, 232, 235, 1);
      height: 1px;
      border: none;
    }

    .form-group {
      .ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
          background-color: $grey;
        }
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
      .fcontrol > input[disabled],
      textarea[disabled] {
        background: #e7e8eb !important;
        cursor: not-allowed;
        border: 1px solid #d0d0d0 !important;
      }
    }
  }

  .ant-modal-footer {
    padding: 30px;
  }
}

.edit-modal {
  .ant-radio-group {
    display: flex;
  }
}

.ant-modal-content,
.ant-modal-confirm {
  .ant-modal-header,
  .ant-modal-confirm-body {
    .ant-modal-title,
    .ant-modal-confirm-title {
      font-size: 23px;
    }
  }
}

.foot-pad {
  .ant-modal-content {
    padding-right: 0 !important;

    .ant-modal-footer {
      padding-top: 0;
      border: none;
    }
  }

  .ant-alert .ant-alert-content {
    padding-right: 0 !important;
  }
}

.ant-notification {
  z-index: 9999 !important;
}

/*Page header*/
.info-header {
  background: $white;
  padding: 15px 50px;
}

/*Breadcrumbs*/
.ant-breadcrumb {
  .ant-breadcrumb-link a {
    color: $secondary !important;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    text-transform: capitalize;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;

    &:hover,
    &:focus,
    &.selected {
      color: $primary !important;
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 10px;
    color: $secondary;
  }

  > span:last-child {
    .ant-breadcrumb-link {
      color: $primary;

      &:hover,
      &:focus {
        color: $secondary;
      }
    }
  }
}

.tblrow {
  .tblcell {
    position: relative;
    padding: 0 15px 0 0;
    margin: 0 15px 0 0;

    span {
      color: rgba(17, 23, 62, 0.74);
      font-size: $font-size-base-sm;
      letter-spacing: 0;
      line-height: 16px;
      display: block;
      margin-bottom: 5px;

      &.primary-link {
        @extend .primary-link;

        margin-top: 10px;
        cursor: pointer;
      }
    }

    h5 {
      color: $secondary;
      font-weight: 500 !important;
      line-height: 20px;
      margin-bottom: 0 !important;

      svg {
        color: #868994;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 11px;
        margin-left: 7px;
      }

      span {
        margin-bottom: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: -15px;
      right: auto;
      height: 25px;
      width: 1px;
      background-color: rgba(231, 232, 235, 1);
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-child {
      &:after {
        display: none;
      }
    }
  }
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0 !important;
  width: 100%;
}

.ant-tabs {
  .ant-tabs-tab-btn {
    color: $grey-clr !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: capitalize;

    a {
      color: $grey-clr;

      &:hover,
      &:focus {
        color: $primary;
        background-color: rgba(243, 244, 252, 0.5);
      }
    }
  }

  .ant-tabs-nav-wrap {
    background: $white;
  }

  .ant-tabs-tab-btn {
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 25px;
      text-align: center;

      svg {
        margin-bottom: 6px;
        font-size: 16px;
      }
    }
  }

  .ant-tabs-nav {
    box-shadow: 2px 4px 8px 0 rgba(14, 22, 74, 0.08);
    border-top: 1px solid #e7e8eb;

    .ant-tabs-tab {
      padding: 0;
      margin: 0;

      &.ant-tabs-tab-active {
        a {
          color: $primary;
          background-color: rgba(243, 244, 252, 0.5);
          font-weight: 600;
        }
      }
    }
  }

  .ant-tabs-nav-list {
    padding: 0 45px;
  }
}

.ant-tabs-ink-bar {
  background: $primary !important;
}

/*Form controls*/
.ant-radio-checked {
  .ant-radio-inner {
    border: 1px solid $primary !important;

    &:after {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: $primary;
      top: 2px !important;
      left: 2px !important;
      width: 10px;
      height: 10px;
      transform: scale(1);
      margin-left: 0px !important;
      margin-top: 0 !important;
    }
  }

  &:after {
    border: 1px solid $primary;
  }
}

.ant-radio-wrapper,
.ant-radio,
.ant-radio-input {
  &:hover,
  &:hover .ant-radio-inner,
  &:focus + .ant-radio-inner {
    border-color: $primary !important;
  }
}

.ant-radio-group {
  span {
    line-height: 18px;
    margin-bottom: 0;
  }

  .ant-radio-wrapper {
    margin-bottom: 15px;
  }
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(84, 99, 210, 0.1) !important;
}

/*card*/
.scard {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);

  &.hcard {
    min-height: 100px;
  }

  .ant-card {
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none;
    border: none !important;
  }

  .fa-exclamation-circle {
    font-size: 11px;
    color: #868994;
  }

  @include title-ico-head;
}

.full-row {
  margin-bottom: 25px;
}

.half-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .scard {
    flex: 0 0 49%;
    margin: 0 12px 24px 12px;
    max-width: 49%;
    min-width: 49%;
  }

  .sm-scard {
    flex: 0 0 300px;
    max-width: 300px;
    min-width: 300px;
  }

  .flxcard {
    flex: 1;
    max-width: 100%;
  }

  &.masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    display: inline-block;
    width: 100%;

    .scard {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
      display: inline-block;

      // min-height: 390px;
      &:first-child,
      &:nth-child(2n + 1) {
        margin-left: 12px;
        margin-right: 0;
      }

      &:nth-child(n + 1) {
        margin-right: 12px;
        margin-left: 0;
      }
    }
  }

  &.overview-masonary {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &.hcard {
    .hleftt {
      width: 100%;

      .hrow {
        align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
    }

    .hbox {
      min-width: 250px;
    }

    .etitle,
    .edetail {
      font-size: $font-size-base;
      max-width: 50%;
    }

    .etitle {
      max-width: 50%;
    }

    .espan {
      border-right: 1px solid #e7e8eb;
      padding-right: 10px;
      margin-right: 10px;

      &:last-child {
        border: none;
        margin-right: 0;
        padding-right: 0;
      }

      span {
        color: rgba(17, 23, 62, 0.74);
        font-weight: 400;
      }
    }

    .eflex {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.half-card-row .scard:first-child,
.half-card-row .scard:nth-child(2n + 1) {
  margin-left: 0;
}

.half-card-row .scard:nth-child(n + 1) {
  margin-right: 0;
}

/*Tooltip*/
.ant-popover-inner {
  border-radius: 4px !important;
  background-color: $sub-heading !important;
  box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1) !important;
  color: $white;

  .ant-popover-inner-content {
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.94);
    font-size: $font-size-base-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    max-width: 150px;
  }
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  display: none;
}

.ant-tooltip {
  .lg-tooltip {
    max-width: 600px !important;
  }

  &.large-tooltip {
    max-width: 400px;
  }

  &.md-tooltip {
    max-width: 330px;
  }
}

.lg-tooltip .ant-tooltip-inner {
  width: auto;
  max-height: 200px;
  overflow: auto;
  max-width: 600px;
  min-width: 100px;
  border-radius: 4px;
  min-height: 28px;
}

/*static page*/
.static-page {
  .ant-modal-body {
    p {
      color: rgba(17, 23, 62, 0.74);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    ol.alphabetic {
      counter-reset: list;
    }

    ol.alphabetic > li {
      list-style: none;
      position: relative;
    }

    ol.alphabetic > li:before {
      counter-increment: list;
      content: counter(list, lower-alpha) ') ';
      position: absolute;
      left: -1.4em;
    }
  }
}

.ant-modal-wrap {
  margin: 25px 0;
}

.emptydata {
  text-align: center;
  margin: 84px 0;

  h3 {
    color: $color-base;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
  }

  .btn {
    font-size: 14px !important;
    line-height: 18px;
  }
}

/*Alert*/
.alert-count {
  color: $secondary;
  border: 1px solid #f6c804;
  padding: 3px 8px;
  padding-right: 16px;
  text-align: center;
  border-radius: 12px;
  background: #fefbed;
  min-width: 45px;
  display: flex;
  align-items: center;
  font-size: $font-size-base;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;

  small {
    font-size: $font-size-base;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: 5px;
    color: $secondary;
    text-transform: lowercase;
    text-align: left;
  }

  &.iwarning {
    color: #f6c804;
    border: 1px solid #f6c804;
    background: #fefbed;

    svg {
      font-size: 11px;
      color: #f6c804;
    }
  }

  &.iflag {
    border: 1px solid rgba(255, 202, 202, 1);
    color: $danger;
    background-color: #fcf1f1;

    svg {
      font-size: 11px;
      color: $danger;
    }
  }

  &.ichat {
    border: 1px solid #c9decb;
    color: $success;
    background-color: #eff7f0;

    svg {
      font-size: 11px;
      color: $success;
    }
  }

  &.ireg {
    border: 1px solid #ced3f9;
    background-color: #f3f4fc;

    svg {
      color: $primary;
    }
  }
}

.special-notes {
  margin-left: 25px;
  border: 1px solid #ced3f9;
  background-color: #f3f4fc;
  color: rgba(17, 23, 62, 0.84);
  padding: 3px 8px;
  padding-right: 16px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  width: auto;
  display: inline-block;
}

.movetocontrol {
  position: relative;

  > div {
    &:first-child {
      top: 100% !important;
    }
  }

  .ant-select-dropdown {
    position: static !important;
  }
}

.checkmark {
  display: flex;
  flex-direction: column;

  .checkbox-row {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
}

.ant-checkbox-inner {
  border-radius: 2px !important;

  &:after {
    left: 30%;
    width: 4px;
    height: 8px;
  }
}

.btn-task {
  padding-bottom: 40px !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 2px;
  background-color: $primary !important;
}

.ant-checkbox-checked::after,
.ant-checkbox-indeterminate::after {
  border: 1px solid $primary !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary !important;
}

/*Form controls*/
.form-group {
  input[type='text'],
  input[type='submit'],
  input[type='button'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='search'],
  textarea {
    -webkit-appearance: none;
    outline: none;
    height: 40px;
    line-height: 40px;
    border: 1px solid rgba(231, 232, 235, 1);
    border-radius: 4px !important;
    background-color: rgba(250, 250, 251, 1);
    color: $secondary !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0 12px;
  }

  .fitem {
    padding: 0 0 0;
    display: block;
    margin-bottom: 15px;
  }

  .fitem-half-space {
    margin-bottom: 15px;
  }

  .full-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    margin: 0;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    -o-justify-content: flex-start;
    justify-content: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    width: 100%;

    textarea,
    input,
    select {
      margin: 0;
      width: 100%;
    }
  }

  .txtDescription {
    height: 100px !important;
  }

  .half-fitem {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    .half-fitem {
      width: 50%;
      display: block;
      margin: 0 11px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.flex {
        @extend .flex;
      }

      &.ai-center {
        @extend .ai-center;
      }
    }

    &.w370 {
      max-width: 369px;

      &:nth-child(3),
      &:nth-child(6) {
        margin-right: 0;
      }

      &:nth-child(4),
      &:nth-child(7) {
        margin-left: 0;
      }
    }

    &.w3 {
      max-width: 33.33%;
    }
    &.w33 {
      width: 243px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
    appearance: none;
  }

  .timezone {
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1fhf3k1-control {
      border-color: #e7e8eb !important;
    }

    .css-12jo7m5 {
      color: rgba(17, 23, 62, 0.84);
      font-size: 14px;
      font-weight: 500;
      padding: 0px 3px 1px 6px;
    }

    .css-xb97g8:hover {
      background-color: transparent !important;
      color: #868994 !important;
    }

    .css-1gtu0rj-indicatorContainer {
      padding: 4px 8px !important;
    }

    // height: 38px !important;
    // .css-1pahdxg-control .css-1wa3eu0-placeholder {    top: 22px;}
    .css-1pahdxg-control {
      border-color: $primary !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        border-color: $primary !important;
        box-shadow: none !important;
      }
    }

    // height: 38px !important;
    .css-g1d714-ValueContainer,
    .css-1hwfws3 {
      overflow: hidden !important;
      padding-top: 0;
    }

    // height: 32px !important;
    svg {
      color: #868994 !important;
      margin-right: 0;
      font-size: 18px;
      width: 16px;
      height: 19px;
      font-weight: 700;

      &.fa-sort-down {
        top: -3px !important;
        position: relative;
      }

      &.css-tj5bde-Svg {
        height: 18px !important;
      }
    }

    .css-1hb7zxy-IndicatorsContainer,
    .css-1wy0on6 {
      padding-right: 11px !important;
      font-size: 17px !important;
    }

    .css-tlfecz-indicatorContainer,
    .css-tlfecz-indicatorContainer {
      padding: 2px;
    }

    .css-1uccc91-singleValue {
      color: $secondary !important;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      font-family: $font-family-base;
      margin-top: -2px;
    }

    .css-1okebmr-indicatorSeparator {
      background-color: transparent;
    }

    .css-1wa3eu0-placeholder,
    .css-1wa3eu0-placeholder {
      line-height: 30px;
      color: rgba(17, 23, 62, 0.6) !important;
      opacity: 0.6;
      font-family: $font-family-base;
      margin-top: -1px;
    }

    .css-b8ldur-Input,
    .css-1g6gooi,
    .css-1bwsx9e-Input,
    .css-mry1rc {
      input {
        border: none !important;
        height: 20px;
      }
    }

    .css-1n7v3ny-option,
    .css-9gakcf-option {
      background-color: rgba(84, 99, 210, 0.16) !important;
      font-weight: 600;
      color: rgba(17, 23, 62, 0.84) !important;
      letter-spacing: 0;
      line-height: 20px;
    }

    .css-yt9ioa-option {
      color: rgba(17, 23, 62, 0.84) !important;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }

    .css-2b097c-container:focus .css-1wa3eu0-placeholder {
      top: 22px !important;
    }
  }

  .css-1wy0on6 {
    padding-right: 11px !important;
    font-size: 17px !important;
  }

  .ad-control {
    width: 100%;
    padding: 0 10px;
  }

  .ant-select {
    width: 100%;
    cursor: pointer;

    input {
      border: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }

  .ant-input-affix-wrapper {
    padding: 4px 3px 7px 11px;
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    height: 40px;

    &::before {
      display: none;
    }

    > input.ant-input {
      height: auto;
      padding: 0;
      background: transparent;
      border: none !important;
    }

    &.h-40 {
      @extend .h-40;
    }

    &.ant-searchbtn {
      padding-top: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-arrow,
  .ant-picker-suffix {
    top: 35%;

    svg,
    .fa-sort-down,
    .fa-sort-up {
      font-size: 18px;
      color: #868994;
    }
  }

  .ant-picker-suffix {
    top: 35%;

    svg {
      font-size: 14px;
      color: #868994;

      &.fa-sort-down {
        margin-top: -5px;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-picker-input input {
    color: $secondary !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 40px !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
    padding: 0 11px;
    border: 1px solid rgba(231, 232, 235, 1) !important;
    border-radius: 4px !important;
    background-color: rgba(250, 250, 251, 1) !important;
  }

  .ant-picker {
    border: 1px solid rgba(231, 232, 235, 1);
    border-radius: 4px;
    width: 100%;
    height: 40px;
    background-color: rgba(250, 250, 251, 1);
  }

  label,
  .ant-form label {
    color: rgba(17, 23, 62, 0.74);
    font-size: $font-size-base-sm;
    line-height: 16px;
    margin-bottom: 5px;
    display: block;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px !important;
  }

  &.grey-box {
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 32px !important;

        .ant-select-selection-search-input {
          height: 32px !important;
        }
      }
    }

    .ant-select-single {
      .ant-select-selector {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 32px !important;
        }
      }
    }

    .ant-select {
      .ant-select-selector {
        background-color: $white !important;
        border: 1px solid #e7e8eb !important;
        border-radius: 4px !important;
      }
    }

    .ant-input-number {
      width: 100%;
    }
  }

  .ant-input-affix-wrapper-disabled {
    background-color: #e7e8eb;

    .ant-input-suffix {
      svg {
        color: #868994;
      }
    }
  }

  .ant-input-number {
    width: 100%;

    &:hover {
      border-color: $primary !important;
    }

    &.ant-input-number-focused {
      box-shadow: none !important;
      border-color: $primary !important;
    }
  }

  &.range-block {
    flex: 1;

    .form-row {
      display: flex;
      justify-content: flex-end;
    }

    .half-fitem {
      margin-right: 5px;
      flex-direction: column;
      margin-bottom: 0;
    }

    .ant-input-affix-wrapper {
      padding: 4px 3px 4px 11px;
      border: 1px solid #e7e8eb;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;

      &::before {
        display: none;
      }

      > input.ant-input {
        height: auto;
        padding: 0;
        background: transparent;
        border: none !important;
      }
    }

    .range-btn {
      padding: 3px 8px !important;
      height: 25px;
      width: 25px;
      text-align: center;
      color: $grey-clr;
      background-color: $white;
      border: 1px solid #d0d0d0;
      font-size: 8px !important;
      margin: 0 2px !important;
    }

    .ant-input-suffix {
      margin-left: 0;
      position: relative;
      width: 85px;
      justify-content: flex-end;

      span {
        float: left;
        position: absolute;
        left: 0;
      }
    }
  }

  .ant-select-multiple {
    .ant-select-selection-item,
    .tree-tag,
    .css-1rhbuit-multiValue {
      border: 1px solid #ced3f9;
      border-radius: 12px;
      background-color: #f3f4fc;
      color: $secondary;
      line-height: 20px !important;
    }

    .tree-tag,
    .css-12jo7m5 {
      line-height: 24px !important;
    }
  }

  .tree-tag {
    svg {
      color: $primary;
      margin-right: 5px;
    }

    .ant-tag-close-icon svg {
      color: #868994 !important;
      margin-right: 0;
      margin-left: 5px;
      font-size: 13px;
    }
  }

  .trash-ico {
    margin-top: 27px;
    display: inline-block;
    color: $grey-clr;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $primary;
    }
  }

  &.lg-control {
    .ant-input-number {
      height: 40px;
    }
  }

  &.grey-controls {
    .ant-input-number {
      width: 100%;
      background: #fafafb;
      border: 1px solid #e7e8eb;

      .ant-input-number-input {
        height: 40px;
      }

      input {
        font-weight: 500;
      }
    }

    .ant-input-affix-wrapper,
    .ant-select-multiple .ant-select-selector {
      background: #fafafb;
    }

    .ant-input-affix-wrapper {
      background: #fafafb;
    }

    .ant-input-suffix svg {
      color: #868994;
    }

    .ant-input-affix-wrapper-status-error {
      border-color: $danger;

      .ant-input-suffix {
        height: auto;

        svg {
          @extend .danger;
        }
      }
    }

    .ant-select-item-option-content {
      color: $grey-clr;
    }
  }

  &.sm-control {
    .ant-select-selection-search {
      height: 28px !important;
      line-height: 28px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .ant-input-number,
    input[type='text'],
    input[type='submit'],
    input[type='button'],
    input[type='password'],
    input[type='email'],
    input[type='tel'],
    input[type='search'],
    .ant-input-affix-wrapper,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-selection-overflow-item,
    .timezone .css-yk16xz-control,
    .timezone .css-1pahdxg-control,
    //.ant-select-multiple .ant-select-selector,
    .timezone .css-1fhf3k1-control {
      height: 32px !important;
      line-height: 32px !important;
    }

    .tdown .css-b8ldur-Input input,
    .tdown .css-1bwsx-9e-Input input,
    .tdown .css-1g6gooi input {
      height: 24px !important;
      line-height: 24px !important;
    }

    .ant-tree-select .ant-select-selection-overflow-item {
      height: 30px !important;
      line-height: 30px !important;
    }

    .txtarea-lg {
      height: 105px !important;
    }

    .timezone .css-yk16xz-control,
    .timezone .css-1pahdxg-control,
    .timezone .css-1fhf3k1-control {
      min-height: 32px;
      position: relative;
    }

    //    .timezone .css-g1d714-ValueContainer, .timezone .css-1hwfws3 {height: 32px !important;}
    //    .timezone .css-1uccc91-singleValue {line-height: 27px;}
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px !important;
    }
  }

  .txtarea-lg {
    height: 105px !important;
  }

  textarea {
    resize: none;
    height: 32px !important;
    line-height: 30px !important;
  }

  .lbl-form {
    label {
      font-size: $font-size-base;
      font-weight: 500;
      color: rgba(17, 23, 62, 0.84);
    }
  }

  .overflow-multiselect {
    &.ant-select-multiple .ant-select-selector {
      border: 1px solid #e7e8eb;
      border-radius: 4px;
      padding: 0 5px;

      .ant-select-selection-search {
        margin-top: 0;
      }
    }

    &.ant-select-multiple .ant-select-selection-item {
      height: 24px !important;
    }

    &.ant-select-multiple .ant-select-selection-item {
      margin-top: 3px !important;
    }

    .ant-select-selection-item-remove,
    .css-xb97g8 {
      line-height: 25px !important;
    }

    &.ant-select-multiple .ant-select-selection-item-content,
    .css-12jo7m5 {
      line-height: 25px !important;
    }

    .ant-select-selection-overflow,
    .css-g1d714-ValueContainer,
    .css-1hwfws3 {
      // flex-wrap: nowrap;
      overflow: hidden;
    }

    .ant-select-selector,
    .css-g1d714-ValueContainer,
    .css-1hwfws3 {
      position: relative;

      &:after {
        content: '';
        height: 29px;
        width: 70px;
        background: linear-gradient(
          270deg,
          rgba(250, 250, 251, 1) 0%,
          rgba(250, 250, 251, 1) 41.39%,
          rgba(250, 250, 251, 0) 100%
        );
        position: absolute;
        right: 0;
      }
    }
  }

  .arrow-top {
    .overflow-multiselect {
      .ant-select-selector,
      .css-g1d714-ValueContainer,
      .css-1hwfws3 {
        &:after {
          height: 0;
        }
      }
    }
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    height: 32px;
    padding-right: 7px;
  }
}

.hleftt {
  padding-right: 25px;

  .hrow {
    border-bottom: 1px solid #e7e8eb;
    padding-bottom: 12px;

    // &:last-child {
    //     border-bottom: none;
    // }
  }

  .etitle {
    max-width: 125px;
  }
}

.ghead {
  display: flex;
  flex: 1;
  max-width: 650px;
  width: 650px;
  min-width: 650px;

  h5 {
    margin-bottom: 0;
  }

  span {
    margin-left: 15px;
  }
}

.bg-tbl table,
.ant-table-cell.ant-table-cell-fix-left .alphabet,
.alphabet-row .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  background: #f1f1f3 !important;

  td {
    background: #fff !important;
  }
}

.alphabet-row.ant-table-row:nth-child(even) td {
  background: #f1f1f3 !important;
}

.ant-modal-body {
  .form-group {
    &.grey-box {
      padding: 15px 25px;
    }
  }
}

.hleftt .etitle,
.hleftt .edetail {
  flex: 1;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
}

.hbox {
  width: 250px;
  border: 1px solid #e7e8eb;
  border-radius: 4px;
  background-color: $grey;
  padding: 15px;
}

.hrow {
  margin-bottom: 10px;
}

.etitle {
  color: rgba(17, 23, 62, 0.74);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  padding-right: 5px;
}

.edetail {
  color: $secondary;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;

  svg {
    color: #868994;
    font-size: 11px;
    margin-right: 5px;
  }

  span {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.claim-overview {
  .ant-card-body {
    .primary-link {
      font-size: $font-size-base-sm;
      width: 100%;
      text-align: center;
      display: inline-block;
      cursor: pointer !important;
    }

    .ant-table-column-sorters-with-tooltip {
      height: 40px !important;
    }
  }
}

.LinesEllipsis-ellipsis {
  width: 100%;
  display: block;
  text-align: center;
}

.showmore {
  background-image: url('../assets/images/angle-down-regular-svg.svg');
  background-size: 11px;
  background-size: 9px;
  background-repeat: no-repeat;
  background-position: right center;
  color: $primary;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  line-height: 16px;
  margin-top: 20px;
  display: inline-block;
  width: 85px;
  text-align: left;

  &:hover,
  &:focus {
    color: $secondary;
    background-image: url('../assets/images/angle-down-hover.svg');
  }
}

.hdays {
  border-bottom: 1px solid #e7e8eb;
  margin-bottom: 15px;
  padding-bottom: 15px;

  .edetail {
    color: $color-base;
    font-size: 20px;
    font-weight: 600;
  }

  .etitle {
    width: 60px;
  }
}

.global-loader {
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.global-loader1 {
  position: fixed !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 100%;
  z-index: 9;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
}

.cloader {
  position: fixed !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sload {
  .ant-spin-dot {
    font-size: 21px !important;
  }
}

.notification-grouping {
  position: fixed;
  left: 85px;
  bottom: 25px;
  z-index: 9999;
  max-width: 390px;
  display: inline-flex;
  position: fixed;
  z-index: 1000;
  gap: 0.25rem 0;
  align-items: flex-start;
  flex-wrap: wrap-reverse;

  .notify-sec {
    border-radius: 8px;
    color: $white;
    max-width: 100%;
    height: auto;
    background-color: #1c203c;
    border: 1px solid #e8e8e8;
    padding: 8px 17px;
    margin-top: 8px;
    min-height: 45px;
    display: flex;
    align-items: center;
  }
}

.ant-scrolling-effect .ant-layout-sider {
  z-index: 999 !important;
}

.txtDescription {
  background-color: $grey !important;
  height: 100px !important;
}

.ant-select-drop-list {
  .ant-drop-select {
    padding: 12px 10px;
  }

  .litem {
    padding: 11px 12px;
    margin: 0 !important;
    width: 100%;

    svg {
      color: #868994;
      font-size: 16px;
      margin-left: 10px;
    }

    &:hover,
    &.ant-checkbox-wrapper-checked {
      background-color: rgba(84, 99, 210, 0.16);

      span {
        font-weight: 600;
      }
    }
  }

  hr {
    margin: 10px -24px 0;
  }

  .action-row {
    padding: 10px 10px 5px;
  }
}

/*Ant picker*/
.ant-picker-cell-in-view {
  &.ant-picker-cell-selected,
  &.ant-picker-cell-range-start,
  &.ant-picker-cell-range-end {
    .ant-picker-cell-inner,
    .ant-picker-cell-inner {
      background: $primary !important;
    }
  }
}

.ant-picker-today-btn:active {
  color: $primary !important;
}

.ant-picker-cell {
  .ant-picker-cell-inner {
    font-size: 13px;
    font-weight: 500;
  }
}

.ant-picker-content {
  th {
    color: $secondary !important;
    font-weight: 500;
  }
}

.ant-picker-today-btn {
  color: $primary !important;

  &:hover,
  &:active {
    color: $primary !important;
  }
}

.LinesEllipsis {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.content-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

.cgraph {
  .ant-dropdown-menu-title-content {
    padding-bottom: 15px;
  }
}

/*Grey box*/
.halfitem {
  .grey-box {
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.grey-box {
  border: 1px solid #e7e8eb;
  flex: 1;
  border-radius: 4px;
  padding: 15px;
  background-color: $grey;

  > span {
    color: rgba(17, 23, 62, 0.74);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  h3 {
    color: $color-base;
    line-height: 24px;
    margin: 0 !important;
    font-size: 17px;
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: $white !important;
    }
  }

  .txtDescription,
  textarea,
  .ant-input {
    background-color: $white !important;
  }
}

.white-box {
  border: 1px solid #e7e8eb;
  flex: 1;
  border-radius: 4px;
  padding: 15px;
  background-color: $white;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.blue-box {
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: #f3f4fc;
  padding: 25px;

  p {
    color: rgba(17, 23, 62, 0.74);
  }

  h2 {
    color: $primary !important;
  }

  h3 {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-left: 25px;
  }
}

.cardbody {
  hr {
    background: #e7e8eb;
    outline: 0;
    border: none;
    height: 1px;
    margin: 25px -25px;
    display: block;
  }
}

/*info table*/
.card-tbl {
  margin: 30px 0 0;

  &.mt-0 {
    @extend .mt-0;
  }

  &.sm-tbl {
    .crow,
    .ccell {
      font-size: $font-size-base-sm;
      line-height: 18px;
    }

    .crow {
      position: relative;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }

    .cellprice {
      max-width: 50%;
      padding-left: 5px;

      .fico {
        position: absolute;
        top: -8px;
        right: 5px;
        height: 15px;
        width: 15px;
        border: 1px solid $grey-clr;
        border-radius: 100%;
        text-align: center;
        line-height: 14px;
        font-size: 9px;
        color: $grey-clr;
        background: #fff;
      }
    }
  }

  .chead {
    color: $color-base;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
    line-height: 18px;
  }

  .crow {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e7e8eb;
    font-size: 14px;
    align-items: center;

    &:first-child {
      border-top: 1px solid #e7e8eb;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .cellhead {
      color: rgba(17, 23, 62, 0.74);
      flex: 1;
    }

    .cellprice {
      color: $secondary;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      flex: 1;

      .fa-exclamation-triangle {
        color: #faad14;
      }
    }

    &.totality {
      margin: 0 -25px;
      padding: 20px 25px;
      background: #fafafb;
      border-top: 1px solid $primary;
      border-bottom: none;

      .cellprice {
        font-size: 15px;
        color: $primary;
        font-weight: 600;
      }

      .fico {
        border: 1px solid $primary;
        color: #fff;
        background: $primary;
        right: 30px;
      }
    }
  }
}

/*Header background table*/
.headbg-tbl {
  &.add-budget {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      padding-left: 6px !important;
      padding-right: 6px !important;

      &:first-child {
        padding-left: 15px !important;
      }

      &:last-child {
        padding-right: 15px !important;
      }
    }

    + .add-claim-damage {
      .dcell:first-child {
        padding-left: 0 !important;
      }

      .dcell:last-child {
        text-align: right !important;
      }
    }
  }

  &.ECodeColumns {
    .ant-table-thead,
    .ant-table-tbody {
      > tr {
        > th,
        > td {
          &.amtprice {
            padding-right: 35px !important;
            text-align: right;
          }
        }
      }
    }

    + .add-claim-damage {
      width: 100% !important;

      .drow {
        width: 100% !important;
        display: flex;
        justify-content: space-between;

        .dcell {
          &:first-child {
            padding-left: 60px !important;
          }

          &.sm-cell {
            width: 180px;
            max-width: 125px;
            text-align: right;
          }
        }
      }
    }
  }

  .ant-table-expanded-row > td.ant-table-cell {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-row-expand-icon-cell svg {
    color: $primary;
    cursor: pointer;
  }

  .ant-table-expanded-row {
    box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);

    td {
      background-color: $white;
    }

    p {
      color: rgba(17, 23, 62, 0.74);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      padding-left: 45px !important;
      margin-bottom: 0;
    }
  }

  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ant-table-thead {
    > tr {
      > th {
        background-color: $grey !important;
        color: $color-base;
        font-weight: 600;

        &:first-child {
          background-color: $grey !important;
        }

        &.ant-table-cell {
          padding-top: 20px !important;
        }
      }
    }
  }

  &.claim-score {
    .ant-table-thead > tr > th.ant-table-cell {
      padding-top: 10px !important;
    }
  }

  &.edit-damage-tbl {
    .md-cell {
      width: 225px;
      max-width: 225px;
    }

    .add-claim-damage .drow .dcell:first-child {
      width: 500px;
      max-width: 500px;
      min-width: 500px;
    }

    .ant-table.ant-table-bordered {
      > .ant-table-container {
        > .ant-table-content,
        > .ant-table-header,
        > .ant-table-body {
          > table {
            > thead,
            > tbody,
            > tfoot {
              > tr {
                > th,
                > td {
                  padding-right: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-table.ant-table-bordered {
    > .ant-table-container {
      > .ant-table-content {
        > table {
          > thead,
          > tbody {
            > tr {
              > th,
              > td {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }

  .ant-table.ant-table-bordered {
    > .ant-table-container {
      > .ant-table-content,
      > .ant-table-header,
      > .ant-table-body {
        > table {
          > thead,
          > tbody,
          > tfoot {
            > tr {
              > th,
              > td {
                border-right: none;
                padding-left: 15px !important;
                padding-right: 15px !important;
              }
            }
          }
        }
      }
    }
  }

  .action-ico {
    svg {
      color: #868994;
      margin-left: 8px;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  .ant-table-cell > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: revert !important;
    max-width: 100%;
    display: inline-block;
  }

  td {
    &.lg-cell {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.claim-score-modal {
  .srv-validation-message {
    margin-left: 170px;
  }

  .error-control {
    border: 1px solid $danger !important;
    box-shadow: 0px 0px 2px 2px rgba(216, 53, 53, 0.15);

    .ant-select-arrow {
      color: $danger !important;
      margin-top: -7px;
    }
  }
}

.export-msg-modal {
  .historical-data {
    .ant-checkbox-group {
      label {
        @extend .w-100;
        @extend .mb-10;
      }
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      .ant-radio-wrapper {
        align-items: baseline;
      }
    }
  }
}

.budget-valuation {
  .liens-table .ant-table-expanded-row p {
    padding-left: 40px !important;
  }

  .liens-table {
    .ant-table-thead,
    .ant-table-tbody {
      > tr:first-child {
        > th:first-child,
        > td:first-child {
          text-align: left;
          min-width: 35px;
          width: 35px;
          padding: 16px 0 16px 12px;
        }
      }

      > tr {
        > th,
        > td {
          padding: 16px 10px;

          &.amtprice {
            width: 125px;
            max-width: 125px;
            text-align: right;
          }

          &.lahrs {
            width: 105px;
            max-width: 105px;
          }

          &.ahrs {
            width: 135px;
            max-width: 135px;
          }

          &.phrs {
            width: 125px;
            max-width: 125px;
          }

          &:nth-child(2) {
            padding-left: 0 !important;
          }
        }

        > th {
          font-weight: 600;
        }
      }
    }

    &.ECodeColumns {
      .ant-table-expanded-row {
        p {
          padding-left: 50px !important;
        }
      }
    }
  }
}

.panel-head {
  padding-right: 65px;

  h4 {
    color: $primary !important;
    font-weight: 600 !important;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
  }

  span {
    top: 10px;
    position: relative;
  }
}

/*table cells*/
.md-cell {
  width: 310px;
  max-width: 310px;
}

.lg-cell {
  width: 370px;
  max-width: 370px;
}

.mdd-cell {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}

.sm-cell {
  width: 95px;
  max-width: 95px;
  min-width: 95px;
}

.xs-cell {
  width: 80px !important;
  max-width: 80px !important;
}

.xl-cell {
  min-width: 350px !important;
  width: 350px !important;
  min-width: 350px !important;
}

.smm-cell {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
}

.notes-cell {
  width: 295px;
  max-width: 295px;
}

.task-cell {
  width: 235px;
  max-width: 235px;
}

.amt-cell {
  width: 135px;
  max-width: 135px;

  &.rightdata {
    text-align: right;

    .ant-table-column-sorters {
      justify-content: flex-end;
    }

    > span {
      padding-right: 20px;
    }
  }
}

.cell-120 {
  width: 120px;
  max-width: 120px;
}

/*Information text*/
.infotext {
  color: rgba(17, 23, 62, 0.74);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  max-width: 145px;

  p {
    margin-left: 5px;
  }

  svg {
    color: $grey-clr;
  }
}

.error-text {
  color: $danger !important;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 500 !important;
}

.page-tab-detail {
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-right: -5px !important;
  }

  &.ant-tabs {
    .ant-tabs-nav {
      box-shadow: none;
      border-top: none;
      overflow-x: hidden !important;
      overflow-y: hidden;
    }

    .ant-tabs-nav-wrap {
      background: transparent;
    }

    .ant-tabs-nav-list {
      padding: 0;
    }

    .ant-tabs-tab-btn .tabname {
      color: $grey-clr !important;
      font-size: $font-size-base !important;
      font-weight: 500 !important;
      letter-spacing: 0 !important;
      line-height: 20px !important;
      position: relative;
      padding-left: 15px;
      min-height: 30px;
      display: flex;
      align-items: center;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: $grey-clr;
        border-radius: 0 100px 100px 0;
        opacity: 0;
      }

      .ndot {
        position: absolute;
        top: 0;
        right: 5px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        text-shadow: none !important;

        .tabname {
          color: $primary !important;
          font-weight: 600 !important;

          &:before {
            background-color: $primary;
            opacity: 1;
          }

          small {
            color: $primary;
            font-weight: 500;
          }
        }
      }
    }

    .tabname {
      small {
        display: block;
        font-weight: 300;
        font-size: $font-size-base-sm;

        svg {
          font-weight: 300;
        }
      }
    }

    .total-plantiff {
      border-top: 1px solid #e7e8eb;
      margin-top: 15px;
      padding-top: 15px;

      span {
        color: $secondary;
        font-weight: 500 !important;
        display: block;
      }

      small {
        color: $grey-clr;
      }
    }

    // .ant-tabs-tab {margin-bottom: 15px;}
  }

  &.ant-tabs-left {
    > .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 0;
        text-align: left;
        display: block;
        width: 100%;
      }
    }
  }

  .ant-tabs-nav {
    width: 170px;
  }
}

.add-claim-damage {
  .drow {
    .dcell {
      &:first-child {
        width: 670px;
        max-width: 670px;
        min-width: 670px;
        padding-left: 0;
      }

      &.dmd-cell {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
        font-weight: 500;
      }

      p {
        margin-bottom: 2px;
      }
    }

    &:last-child {
      border-bottom: 1px solid #e7e8eb;
    }
  }

  .range-block {
    width: 450px;
    min-width: 450px;
    max-width: 450px;
  }
}

.total-damages {
  .grey-box {
    padding: 10px 15px;
    min-height: 65px;
    align-items: center;
  }
}

.drow {
  display: flex;
  border-bottom: 1px solid #e7e8eb;
  margin-top: 15px;

  &:not(.grey-box):last-child {
    border-bottom: none;

    .dcell {
      padding-bottom: 0;
    }
  }

  .dcell {
    padding: 0 15px;
    text-align: right;
    flex: 1;

    h5,
    h5 span {
      color: $primary;
      margin-bottom: 0;
      white-space: normal;
      word-break: break-word;
      font-size: 14px;
    }

    span {
      font-size: 12px;
      white-space: normal;
      word-break: break-word;
    }

    &:first-child {
      text-align: left;
      min-width: 265px;
      color: $color-base;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      max-width: 265px;

      svg {
        color: #868994;
      }
    }

    &.dlg-cell {
      width: 450px;
      min-width: 450px;
      max-width: 450px;
    }

    &.dmd-cell {
      width: 300px;
      min-width: 300px;
      max-width: 300px;
    }

    &.cvalue {
      text-align: right;
    }
  }

  &:nth-child(2) .dcell:last-child {
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  .dtotal {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e8eb;
    padding-bottom: 15px;
    margin-bottom: 15px;

    .dtxt {
      flex: 1;
      text-align: left;
      font-size: 12px;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }

    .dval {
      color: $primary;
      font-weight: 600;
    }
  }
}

.ant-table-thead {
  .ant-table-cell.cvalue {
    text-align: right;
  }
}

.edit-damages {
  &.edit-dam-modal {
    .add-claim-damage .drow .dcell:first-child {
      width: 500px;
      max-width: 500px;
      min-width: 500px;
    }

    .sm-cell {
      width: 200px;
      max-width: 200px;
      min-width: 200px;
      padding-right: 0 !important;
    }
  }

  .drow {
    &:last-child {
      .dcell {
        &:last-child {
          max-width: 100%;
          min-width: 150px;
        }
      }
    }

    .dtotal {
      .dtxt {
        max-width: 280px;
      }

      .dval {
        text-align: left;
        flex: 1;
        max-width: 170px;
      }
    }
  }
}

.g2-html-annotation {
  font-size: 9px !important;
  opacity: 0.9;
  color: #11173e;
}

.g2-tooltip {
  color: rgba(255, 255, 255, 0.94) !important;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px !important;
  font-family: $font-family-base !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
  background-color: #1a1d35 !important;
  width: 260px;
}

.g2-tooltip-list-item,
.g2-tooltip-title {
  margin: 3px 0 !important;
}

.g2-tooltip-marker {
  border-radius: 2px !important;
}

.settlement {
  border: 1px solid #e7e8eb;
  border-radius: 4px;
  padding: 25px;

  .g2-tooltip {
    width: 340px;

    .g2-tooltip-list-item {
      .g2-tooltip-value {
        margin-left: 5px !important;
      }
    }
  }

  .labels {
    border: none;
    margin-left: 45px;
  }
}

.tooltip-sm {
  .g2-tooltip {
    width: auto;
  }
}

/*edit claim modal*/
.edit-claim {
  .ant-input-affix-wrapper {
    input {
      color: transparent;
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}

.edit-claim-info {
  .ant-input-number-input {
    height: 40px;
  }
}

/*Tooltip*/
.ant-tooltip-inner {
  padding: 5px 10px;
  color: rgba(255, 255, 255, 0.94) !important;
  font-size: 11px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
  // background-color: #1a1d35 !important;

  a {
    text-align: right;
    color: #fff;
    text-decoration: underline;
    width: 100%;
    display: inline-block;

    &:hover,
    &:focus {
      color: $grey;
    }
  }
}

.ant-tooltip-placement-right .ant-tooltip-inner {
  width: 340px;
}

.ant-modal-body {
  .ant-tooltip-inner {
    width: 330px;
  }
}

.cols {
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}

/*Legends*/
.labels {
  border: 1px solid #e7e8eb;
  border-radius: 4px;

  .lrow {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    + .lrow {
      border-top: 1px solid #e7e8eb;
    }

    .small-box {
      height: 12px;
      width: 12px;
      min-width: 12px;
      border-radius: 2px;
      background-color: $primary;
      display: inline-block;
      margin-right: 15px;
    }

    span {
      color: $secondary;
      font-weight: 500;
      line-height: 15px;
      margin-right: 15px;
    }

    .ltxt {
      font-size: 12px;
      padding-left: 25px;
      color: rgba(17, 23, 62, 0.74);
    }
  }

  svg {
    color: #868994;
    font-size: 11px;
  }

  .leg-val {
    text-align: right;
    line-height: 18px;

    small {
      line-height: 15px;
    }

    span {
      margin-right: 0;
      text-align: right;
    }
  }
}

.lg-tooltip {
  .g2-tooltip {
    width: 300px !important;
  }
}

.chart-tooltip {
  display: flex;
  align-items: center;
  line-height: 18px;

  .small-box {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 5px;
    min-width: 12px;
  }

  span {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 22px;
  }
}

.scrollbar-thumb {
  background-color: $primary !important;
}

.scrollbar-track {
  background: rgba(84, 99, 210, 0.2) !important;
}

.scrollblock {
  overflow-x: visible !important;
  width: 100%;

  .content-wrap {
    padding-right: 20px;
  }

  &.grey-scroll {
    overflow-x: hidden !important;

    .scrollbar-thumb {
      background-color: #d0d0d0 !important;
      border-radius: 2px;
    }

    .scrollbar-track-y {
      width: 3px;
    }

    .scrollbar-track {
      background: transparent !important;
    }

    &.md-scroll {
      .scrollbar-track-y {
        width: 6px;
      }
    }
  }
}

.rc-virtual-list-scrollbar,
.ant-select-tree-list-scrollbar {
  width: 3px !important;
}

.rc-virtual-list-scrollbar-thumb,
.ant-select-tree-list-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
}

input.ant-input:after {
  content: '%';
  font-size: 21px;
  position: absolute;

  /* left: 0; */
  right: 0;
  color: red;
}

.add--face {
  border-top: 1px solid #e7e8eb;
  padding: 12px;
}

.fitem-inner .fitem {
  position: relative;
  height: 56px;
  margin: 0;
  border-top: 1px solid #e7e8eb;
  padding: 12px;

  .ant-input {
    position: absolute !important;
    left: 0;
    height: 36px !important;
    width: 95%;
    border: 1px solid rgba(84, 99, 210, 0.15) !important;
    border-radius: 4px !important;
    right: 0;
    margin: 0 auto;

    &:focus {
      border-color: $primary;
    }
  }

  .ant-btn {
    position: absolute;
    right: 8px;
    top: 15px;
    cursor: pointer;
  }
}

.ant-table-row-expand-icon {
  background: url('../assets/images/angle-down-regular-svg.svg') !important;
  background-repeat: no-repeat !important;
  background-size: 12px !important;
  height: 15px !important;
  width: 15px !important;
  border: none !important;
  background-position: center !important;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

  &.ant-table-row-expand-icon-expanded {
    background: url('../assets/images/angle-down-regular-svg-svg.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 12px !important;
    background-position: center !important;
    height: 15px !important;
    width: 15px !important;
    border: none !important;
  }
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: transparent;
  box-shadow: none;
  border: none;
}

.edit-forrms {
  .ant-form-item {
    width: 100%;
    border-radius: 4px;
    margin: 0;
  }

  textarea {
    width: 100%;
    height: 32px !important;
    border-radius: 4px;
  }

  .ant-input-number {
    width: 100% !important;
    border-radius: 4px;
  }

  .ant-table-column-title {
    margin-right: 10px;
  }
}

.currency {
  text-align: right !important;
  padding-right: 15px !important;

  .ant-table-column-sorters {
    justify-content: flex-end;
  }
}

.ant-table-tbody {
  .currency {
    &.ant-table-cell > div {
      padding-right: 20px;
    }
  }
}

.ant-input-number:hover {
  border-color: $primary !important;
}

/*Authentication*/
.authentication {
  aside {
    width: 360px;
    height: 100%;
    background: linear-gradient(180deg, #202756 0%, #1c203c 100%);
    min-height: 100vh;
    position: sticky;
    top: 0;
  }

  .not-found {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: sticky;
    top: 0;
  }

  // background-image: url('../assets/images/login_screen-background.jpg'); background-repeat: no-repeat; background-size: cover;
  .au-steps {
    flex: 1;
    max-width: calc(100% - 360px);

    .au-step-container {
      max-width: 810px;
      margin: 50px auto;

      .primary-link {
        display: inline-block;
      }
    }

    .anticon {
      svg {
        color: $grey-link;
      }
    }

    .anticon-spin {
      svg {
        color: $primary;
      }
    }
  }

  .form-group {
    textarea {
      line-height: 38px !important;
      padding: 0px 10px !important;
    }
  }

  .ant-form {
    label {
      font-size: $font-size-base-sm;
    }
  }

  .scard {
    padding: 32px 0;

    .primary-link {
      font-weight: 500;
    }
  }

  .ant-input-affix-wrapper {
    height: 40px !important;
    padding: 4px 11px 4px 11px;

    .ant-input-prefix svg {
      color: $grey-clr;
    }

    .ant-input-suffix {
      svg {
        font-size: 17px;
      }
    }
  }

  @include lists;

  .steps-action {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;

    &.flex-end {
      justify-content: flex-end !important;
    }

    .ant-btn {
      &:not(.ant-dropdown-trigger) {
        min-width: 95px;
        height: 40px;
        font-size: 14px !important;
      }

      .fa-arrow-left {
        margin-right: 10px;
      }

      .fa-arrow-right,
      .fa-check {
        margin-left: 10px;
      }
    }
  }

  .ant-steps {
    max-width: 520px;
    margin: 0 auto 30px;

    .ant-steps-item-icon {
      height: 48px;
      width: 48px;
      border: 1px solid #e7e8eb;
      background-color: #fafafb;
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: center;
    }

    .ant-steps-item-wait .ant-steps-item-finish {
      .ant-steps-item-icon {
        > .ant-steps-icon {
          color: $grey-link;
          color: rgba(17, 23, 62, 0.6);
          font-weight: 600;
        }
      }
    }

    .ant-steps-item {
      flex: 1;
      position: relative;
      overflow: visible;

      &:before {
        content: '';
        height: 1px;
        width: 100%;
        top: 35px;
        left: 110px;
        background: #e7e8eb;
        position: absolute;
      }

      &.ant-steps-item-active {
        &:before {
          background: linear-gradient(90deg, #5362d1 0%, #e7e8eb 55%);
        }
      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      &:last-child {
        .ant-steps-item-content {
          .ant-steps-item-title {
            width: 90px !important;
          }
        }
      }
    }

    .ant-steps-item-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .ant-steps-item-content {
        margin-top: 35px;

        > .ant-steps-item-title {
          color: rgba(17, 23, 62, 0.9);
          font-size: $font-size-base-sm;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          width: 115px;
          display: block;
          white-space: normal;

          &:after {
            display: none;
          }
        }
      }

      .ant-steps-item-icon {
        position: relative;
        top: 10px;
      }
    }

    .ant-steps-item-process {
      > .ant-steps-item-container {
        > .ant-steps-item-icon {
          background: $primary;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 28px;
          font-family: $font-family-base;
          position: relative;
          top: 10px;

          &:before {
            content: '';
            height: 65px;
            width: 65px;
            position: absolute;
            left: -10px;
            right: 0;
            top: -9px;
            bottom: 0;
            border: 1px solid $primary;
            border-radius: 100%;
          }
        }

        .ant-steps-item-content {
          > .ant-steps-item-title {
            color: $primary;
          }
        }
      }
    }
  }
}

.title-head {
  padding: 0 32px 25px;
  border-bottom: 1px solid #e7e8eb;

  h5 {
    color: $grey-link !important;
    line-height: 20px;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
  }
}

.scard-body {
  padding: 32px;

  hr {
    margin: 30px -32px;

    @extend hr;
  }

  textarea {
    min-height: 40px !important;
  }

  p {
    color: rgba(17, 23, 62, 0.74);
  }

  .ant-card-meta-title {
    svg {
      font-size: 60px;
      color: $primary;
      margin-bottom: 30px;
    }
  }
}

.terms-cond {
  color: $grey-link;
  font-size: $font-size-base-sm;
  line-height: 16px;
  text-align: center;
  max-width: 525px;
  margin: 25px auto 0;

  .primary-link {
    text-decoration: underline;
    font-weight: 500;
  }

  &.txt-left {
    text-align: left;
  }
}
.hedaer_menu {
  min-width: 180px;
}
.none-bdr-collapse {
  &.ant-collapse {
    background-color: transparent;
    border: none;

    h3 {
      margin-bottom: 5px !important;
    }

    > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      left: auto;
      right: 0;
      top: 5px;
      position: absolute;
    }

    > .ant-collapse-item > .ant-collapse-header {
      padding-left: 0;
    }

    .ant-collapse-arrow {
      height: 32px;
      width: 32px;
      border: 1px solid rgba(28, 32, 60, 0.1);
      border-radius: 4px;
      background-color: #f4f4f6;
      line-height: 32px !important;
      padding-top: 0 !important;

      svg {
        color: #868994;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 11px;
        text-align: center;
      }
    }

    .ant-collapse-item {
      .ant-collapse-header {
        svg {
          transform: rotate(-90deg) !important;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          svg {
            transform: rotate(90deg) !important;
          }
        }
      }
    }

    > .ant-collapse-item > .ant-collapse-header {
      padding: 0;
    }
  }

  .ant-collapse-content {
    border-top: none;

    > .ant-collapse-content-box {
      padding: 0;
    }
  }

  > .ant-collapse-item {
    margin: 30px 0;
    padding: 0 0 30px 0;
  }

  .ant-collapse-content-box {
    p {
      color: rgba(14, 22, 74, 0.84);
    }
  }

  // .drow .dcell {padding: 0 25px !important;}
}

/*Search dropdown*/
.ant-avatar {
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;
  font-weight: 600;
  margin-right: 8px;
  min-width: 25px;
}

.cuname {
  // b, span {font-size: 14px !important;}
  &.ant-checkbox-wrapper {
    margin-left: 0 !important;
    margin-bottom: 15px;
  }

  .ant-avatar {
    margin-right: 12px;
    font-size: 12px !important;
  }
}

.budget-valuation,
.budget-view {
  .card-tbl {
    .cbody {
      .crow {
        &:first-child {
          .cellprice {
            .fico {
              display: none;
            }
          }
        }
      }
    }
  }
}

.card-tbl-head {
  padding: 25px;
  border-bottom: 1px solid #e7e8eb;
}

.budget-valuation {
  .ant-table-thead {
    th {
      padding: 15px 10px 10px 10px !important;
    }
  }

  .xs-cell {
    min-width: 60px !important;
    width: 60px !important;
    max-width: 60px !important;
  }

  .scrollblock {
    overflow-x: hidden !important;
  }
}

.adcomp {
  .grey-box {
    max-width: calc(100% - 30px);
  }

  // .ant-select-selection-item {width: 215px;}
  .ant-select-selection-item {
    max-width: 100%;
    display: inline-block;
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    display: flex;
  }
}

.ant-select-multiple .ant-select-selection-item {
  margin-top: 1px !important;
}

.ant-select-multiple .ant-select-selection-item-remove {
  padding: 0 5px;
}

.ant-select-multiple .ant-select-selection-item-content,
.ant-select-multiple .ant-tag {
  color: $secondary;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px !important;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: left center;
}

.ant-select-selection-item-remove {
  font-size: 13px !important;
  line-height: 26px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: $primary !important;
}

.ant-select-multiple .ant-select-selection-item {
  height: 27px !important;
  display: flex;
}

.ant-select-item-option-active {
  svg {
    color: $primary;
  }
}

.ant-select-multiple,
.tdown {
  &.prefix-ico {
    .ant-select-selection-item-content,
    .css-12jo7m5 {
      background-image: url('../assets/images/gavel.svg');
      padding-left: 20px;
    }
  }

  &.bg-fold {
    .ant-select-selection-item-content {
      background-image: url('../assets/images/folder-solid.png');
      padding-left: 20px;
    }
  }
}

/*Document tab*/
.file-op {
  margin-left: 105px;

  .doc-name {
    color: rgba(17, 23, 62, 0.5);
    font-size: $font-size-base-sm;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-right: 15px;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }

  .css-26l3qy-menu {
    z-index: 99 !important;
  }

  .css-1pahdxg-control {
    border-color: $primary !important;
    box-shadow: none !important;
  }

  .tdown {
    width: 250px;
    margin-right: 10px;
  }

  .btn {
    height: 30px !important;
  }

  .ant-select-selection-search,
  .ant-select {
    input {
      width: 85% !important;
    }

    input {
      width: 85% !important;
    }

    .btn {
      height: 24px !important;
    }

    .ant-select-clear {
      right: 40px;
    }
  }

  .tdown .css-tlfecz-indicatorContainer,
  .tdown .css-1gtu0rj-indicatorContainer,
  .timezone .css-tlfecz-indicatorContainer,
  .timezone .css-1gtu0rj-indicatorContainer {
    margin-top: 10px;
  }
}

.recent-docs {
  margin: 12px 0;
  border-bottom: 1px solid #e7e8eb;
  padding-bottom: 12px;
  padding-left: 10px;

  .doc-row {
    svg {
      color: $grey-clr;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-right: 10px;
      margin-left: 5px;
    }

    span {
      margin-left: 12px;
      color: $primary;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $secondary;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .primary-link {
    text-align: left !important;
  }
}

.dropdown-list {
  .dropdown-head {
    padding: 15px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 235px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .dropdown-head {
    border-bottom: 1px solid #e7e8eb;
    color: $color-base;
    font-weight: 600;

    span {
      padding: 0 10px;
    }

    svg {
      color: $grey-clr;
      font-size: $font-size-base;
    }
  }

  .dropdown-footer {
    border-top: 1px solid #e7e8eb;
    text-align: right;
    padding: 12px;
  }

  .ant-cascader-menu {
    background-color: $white;
    flex: 1;
    max-width: 138px;

    .ant-cascader-menu-item-active,
    .ant-cascader-menu-item-active {
      color: $secondary;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      padding: 6px 12px;

      &:hover,
      &:focus,
      &:not(.ant-cascader-menu-item-disabled),
      &:not(.ant-cascader-menu-item-disabled):hover {
        background: rgba(84, 99, 210, 0.16) !important;
        font-weight: 600;
      }
    }

    .ant-cascader-menu-item {
      &:hover,
      &:focus {
        background: rgba(84, 99, 210, 0.16) !important;
        font-weight: 600;
      }
    }

    .ant-cascader-menu-item-active,
    .ant-cascader-menu-item-active {
      padding: 6px 20px 6px 12px;
    }

    .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-loading-icon {
      right: 5px;
    }
  }
}

.documenttab {
  .card-header .ant-select-dropdown {
    width: 210px !important;
  }

  .ant-cascader-menus {
    width: 360px !important;
  }

  .primary-link {
    font-weight: 500;
  }

  .ant-tree-select .ant-select-arrow {
    font-size: 13px;
    height: 32px;
    width: 28px;
    top: 10px;
    right: 3px;

    .btn.sm-btn {
      padding: 3px 6px !important;
    }
  }
}

// .settle-neg {
//     .ant-card-body {padding-left: 0; padding-right: 0;
//         .crow {padding-left: 24px; padding-right: 24px;
//             &:last-child {border-top: none;}
//         }
//     }
// }
// .settle-neg {
//     .tblpad .ant-card-body {padding-left: 0; padding-right: 0;
//         .crow {padding-left: 24px; padding-right: 24px;
//             &:last-child {border-top: none;}
//         }
//     }
// }
.settle-neg {
  .tblpad {
    .ant-card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .sm-scard .ant-card-body {
    padding-left: 0;
    padding-right: 0;

    .crow {
      padding-left: 24px;
      padding-right: 24px;

      &:last-child {
        border-top: none;
      }
    }
  }

  .card-tbl {
    &.sm-tbl {
      .crow {
        &:last-child {
          .cellprice {
            color: rgba(17, 23, 62, 0.84);
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .ant-select-drop-list {
    .ant-form label,
    label {
      display: flex;
    }
  }
}

.crow {
  .absolute-ccell {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.upload-steps {
  border: 1px dashed $primary;
  background: rgba(84, 99, 210, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 30px;

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .file-box {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 35px 20px;

    input {
      opacity: 0;
    }

    svg {
      font-size: 18px;
    }

    .upload-file {
      align-items: center;
      flex-direction: column;
      display: flex;
      margin: 0 auto;
      font-size: 13px;
      color: $primary;
      font-weight: 500;
    }
  }

  .uploading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: $secondary;
    font-weight: 500;
  }
}

.comma {
  @extend .comma;
}

.ant-select-tree {
  .ant-select-tree-treenode {
    &.ant-select-tree-treenode-switcher-close {
      &.ant-select-tree-treenode-selected {
        background-color: rgba(84, 99, 210, 0.16) !important;

        .ant-select-tree-title {
          font-weight: 600;
        }
      }
    }

    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background-color: transparent;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(84, 99, 210, 0.16) !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent !important;

  .ant-select-tree-title {
    font-weight: 600;
  }
}

.ant-select-tree .ant-select-tree-node:hover,
.ant-select-tree-treenode:hover {
  background-color: rgba(84, 99, 210, 0.16) !important;

  .ant-select-tree-title {
    font-weight: 600;
  }
}

.ant-tree-select-dropdown {
  .ant-select-tree-list-holder-inner {
    .ant-select-tree-treenode {
      padding-bottom: 0;
    }
  }
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 0 !important;
}

span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  display: none;
}

span.ant-select-tree-title {
  padding: 6px 15px;
  color: rgba(17, 23, 62, 0.84) !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  width: 100%;
  display: block;
}

.ant-tree-select-dropdown {
  padding: 8px 0 0 !important;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  margin-top: 0px;
}

.ant-select-tree-switcher {
  padding-left: 18px;
  line-height: 30px !important;

  svg {
    color: $primary;
  }
}

// .ant-tree-select-dropdown {
//     min-width: 310px !important;
// }
.ant-tree-select-dropdown
  .ant-select-tree-list-holder-inner
  .ant-select-tree-treenode
  .ant-select-tree-node-content-wrapper {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  height: 34px;
  line-height: 34px;

  .ant-select-tree-title {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.settlemnt-ddown .ant-switch-small {
  min-width: 18px;
  height: 12px;
  line-height: 12px;
  width: 18px;

  .ant-switch-handle {
    width: 8px;
    height: 8px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 9px - 2px);
  }
}

.ant-switch-checked {
  background-color: $primary !important;
}

.settlemnt-ddown {
  .dragging {
    display: none !important;
  }

  &.column-ddown .action-row {
    padding: 10px 10px !important;
  }

  li {
    padding-bottom: 40px !important;
  }
}

.column-ddown {
  > ul {
    > .ant-dropdown-menu-item:hover {
      background: transparent !important;
    }

    &.ant-dropdown-menu {
      padding-bottom: 0 !important;
    }
  }

  .ant-btn:not(.ant-dropdown-trigger) {
    padding: 3px 8px !important;
    height: 27px;
  }

  .action-row {
    padding: 10px 10px !important;
  }

  .ant-dropdown-menu-item {
    padding: 0;
  }
}

.task-req-tbl {
  .fa-check-circle {
    color: #168f25;
  }

  .fa-times-circle {
    color: $danger;
  }

  .ttime {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    color: $grey-link;
    font-size: 14px;
    margin-top: 30px;
    letter-spacing: 0;
    line-height: 20px;

    b {
      margin-right: 5px;
      color: $color-base;
    }

    .primary-link {
      display: flex;
    }
  }

  .task-row {
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    background: $white;
    padding: 15px 20px;
    margin-bottom: 8px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .ntdot {
      position: absolute;
      left: -27px;
      height: 8px;
      width: 8px;
      background: $danger;
      border-radius: 100%;
    }

    &:hover,
    &:focus,
    &.active {
      border: 1px solid $primary;
      background-color: #e3e6f8;
      box-shadow: 0 4px 10px 0 rgba(14, 22, 74, 0.1);
      cursor: pointer;
    }

    button {
      cursor: pointer;
    }
  }

  .trblock {
    border-radius: 6px;
    background-color: #f1f1f3;
    padding: 10px;
  }

  .checkicon {
    min-width: 20px;
    min-height: 20px;

    svg {
      font-size: 20px;
    }

    .fa-paper-plane,
    .fa-check,
    .fa-minus {
      font-size: 14px;
    }

    &.sentinfo {
      width: 150px;
      justify-content: flex-start;

      .ellipsis-text {
        max-width: 115px;
      }
    }
  }

  .ellipsis-text {
    vertical-align: middle;
  }

  .fa-paper-plane,
  .fa-envelope {
    font-size: 11px;
    color: $grey-clr;
  }

  .trcells {
    line-height: 16px;

    span {
      color: $secondary;
      font-size: $font-size-base-sm;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .alert-count {
      small {
        margin-left: 0;
      }
    }

    &.treqcell {
      .wrap-info {
        .ellipsis-text {
          max-width: 100px;
        }
      }
    }

    // small { color: rgba(17,23,62,0.74); font-size: $font-size-base-sm; font-weight: 500; letter-spacing: 0; line-height: 16px;}
    .ant-avatar {
      min-width: 25px;
    }
  }

  .trinfo {
    color: $grey-link;
    font-size: $font-size-base-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  .traction {
    svg {
      color: #868994;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $primary;
      }
    }

    .acmenu {
      padding: 0 5px 0 10px;

      svg {
        font-size: 14px;
        color: $primary;

        &:hover,
        &:focus {
          color: $secondary;
        }
      }

      .ant-btn {
        background: transparent;
      }
    }

    .ant-btn {
      border: none;
      padding: 0 0 0 10px;
    }
  }

  .notify-ico {
    position: relative;

    .ndot {
      position: absolute;
      top: -2px;
      right: 9px;
    }

    svg {
      color: #868994;
    }
  }

  .treqcell {
    padding: 0 10px;
    flex: auto;
    max-width: 120px;
    line-height: 18px;

    // &:last-child {padding-left: 30px;}
    .alert-count {
      margin: 0 0 0 10px;

      &:first-child {
        margin-left: 0;
      }
    }

    &.info-user {
      max-width: 160px;
    }

    &.trname {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 50%;
      max-width: 400px;

      b {
        color: $color-base;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        margin-right: 10px;
        min-width: 120px;
        @extend .ellipses-text;
      }

      span {
        color: $grey-link;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
      }
    }

    &.alcell {
      width: 250px;
      min-width: 250px;
      text-align: right;
      justify-content: flex-end;
      flex: 1;
    }

    .alertcell {
      display: inline-block;

      svg {
        margin-right: 5px;
      }
    }

    &.md-trcell {
      max-width: 150px;
    }

    &.sm-trcell {
      max-width: 25px;
      padding-right: 0;
    }

    small {
      opacity: 0.74;
      color: #11173e;
    }

    .ellipsis-text {
      display: inline-block !important;
    }

    .alert-count {
      display: inline-flex;
    }
  }

  .flex-cell {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.taskcard {
  .ttime {
    &:first-of-type {
      margin-top: 0;
    }
  }

  .task-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .task-req-tbl {
    .treqcell {
      max-width: 150px;

      &.trname {
        max-width: 350px;
      }
    }
  }
}

.tbl-list {
  .scard {
    padding: 25px;
    transform: translate(0, 0);

    .ant-drawer {
      position: fixed;
      top: 0;
    }
  }

  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }

  .claim-drawer {
    .drawer-head {
      padding-left: 0;
    }
  }

  .card-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.lg-toggle {
  &.ant-switch {
    min-width: 36px !important;
    height: 20px !important;
    line-height: 20px !important;

    .ant-switch-handle {
      width: 16px;
      height: 16px;
    }
  }
}

.ant-switch-checked:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(84, 99, 210, 0.2) !important;
  box-shadow: 0 0 0 2px rgba(84, 99, 210, 0.2) !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 17px - 2px);
}

.wrap-info {
  span {
    line-height: 18px;
  }
}

.notes-list {
  .notes-row {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e7e8eb;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }

    p {
      margin-top: 12px;
      color: $grey-link;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 40px;
      font-weight: 500;
    }
  }
}

/*Notifications*/
.ntlist {
  width: 100%;
  text-align: center;
  display: block;
  align-items: center;
  justify-content: center;
}

.nodatafound {
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 135px 0;
}

.notify-list {
  .nrow {
    padding: 15px 45px 15px 25px !important;
    border: none;
    border-bottom: 1px solid #e7e8eb;
    position: relative;
    margin: 0 !important;
    max-width: 100%;
    background: $white;

    &.read {
      background-color: #f4f4f6;
    }

    &.ant-alert {
      .ant-alert-content {
        padding-right: 0 !important;
        flex: 1;
        min-width: 95%;
      }
    }

    .ant-alert-close-icon {
      right: 20px;
      top: 5px;
      background-image: url('../assets/images/bclose.png');
      height: 11px;
      width: 11px;
      background-repeat: no-repeat;
      background-size: 8px;
      margin-top: 5px;

      .anticon {
        display: none;
      }
    }

    .nread-link {
      .primary-link {
        position: relative;
        right: -15px;
      }
    }
  }

  .bg-ico {
    min-width: 32px;
  }

  .ndetail {
    padding-left: 15px;
    flex: 1;
  }

  .primary-link {
    font-weight: 400;
  }

  .nmsg {
    svg {
      margin-top: 5px;
      color: $primary;
      margin-right: 5px;
    }

    p {
      margin-bottom: 5px;
      margin-right: 30px;
      font-weight: 500;
      color: $secondary;
      text-align: left;
    }
  }

  .ndot {
    top: 21px;
    right: 25px;
    height: 10px;
    width: 10px;
  }

  .ntime {
    color: $grey-link;
    font-size: $font-size-base-sm;
    letter-spacing: 0;
    line-height: 16px;
  }
}

.bg-ico {
  height: 32px;
  width: 32px;
  border: 1px solid #ffeb9b;
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background: #fffbed;

  svg {
    color: #fbc800;
  }
}

/*Messenger*/
.title-cap {
  padding: 0 25px 10px;

  span {
    color: $grey-link;
    font-size: $font-size-base-sm;
    letter-spacing: 0;
    line-height: 16px;
  }

  .ant-btn {
    &.sm-btn {
      padding: 3px 6px !important;
      height: 24px !important;
      font-size: 11px !important;
    }
  }
}

.messenger-block {
  margin-bottom: 0;

  .nav-menu {
    padding: 12px 25px;
    position: relative;

    .cuser {
      color: $grey-clr;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 10px;
      text-transform: capitalize;
    }

    svg {
      color: $primary;
    }

    &:hover,
    &:focus,
    &.active {
      background-color: #e3e6f8;
      cursor: pointer;

      .cuser {
        color: $color-base;
      }
    }

    &:before {
      transition: all 0.1s ease;
      border-radius: 0 100px 100px 0;
      background-color: transparent;
      width: 4px;
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
    }

    &:hover,
    &:focus,
    &.active {
      &:before {
        transition: all 0.1s ease;
        background-color: $primary;
      }
    }
  }

  .userlist {
    .nav-menu {
      &:hover,
      &:focus,
      &.active {
        background-color: transparent;
      }

      &:before {
        display: none;
      }
    }

    .custom-channel-list {
      padding-top: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .chat-detail {
    .ant-card-body {
      display: none;
    }

    .ant-card {
      border-radius: 0 !important;
      box-shadow: none;
    }
  }

  .channel-title {
    color: $color-base;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    span {
      color: $grey-link;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;

      svg {
        color: #868994;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
      }
    }
  }

  .sendbird-conversation__messages {
    overflow-x: hidden;

    .sendbird-msg-hoc {
      display: flex;
      flex-direction: column;

      .sendbird-separator {
        order: 2;
      }

      .sendbird-message {
        order: 1;
      }
    }
  }

  // .channel-title {
  //     .ant-btn {
  //         span {color: $white}
  //     }
  // }
  .sendbird-app__channellist-wrap {
    min-height: 100%;
  }
}

.quick-links {
  height: calc(100vh - 80px);
  min-height: 100%;

  .page-tab-detail.ant-tabs {
    .ant-tabs-tab {
      margin-bottom: 0;
    }

    .ant-tabs-tab-btn {
      .tabname {
        line-height: 35px !important;

        &.lh-18 {
          line-height: 18px !important;
        }
      }
    }
  }

  @include title-ico-head;

  // .fa-plus {
  //   display: none;
  // }
}

.linklist {
  display: flex;
  flex-wrap: wrap;
}

.link-view {
  &.white-box {
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    padding: 15px 25px;
    background-color: #fff;
    margin: 0 24px 24px 0;
    max-width: 230px;
    flex: auto;
    width: calc((100% - 6%) / 5);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  .action-header {
    text-align: right;
    color: #868994;
    font-size: 11px;

    svg {
      margin-left: 10px;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  hr {
    margin: 0 -24px;
    background: rgba(231, 232, 235, 1);
    height: 1px;
    border: none;
  }

  .head-iconn {
    margin-bottom: 15px;

    svg {
      font-size: 14px;
    }
  }

  h3 {
    margin-bottom: 35px !important;
    display: block;
  }

  .action-footer {
    a {
      padding: 15px 0 0;
    }

    svg {
      font-size: 12px;
    }
  }
}

.activity-history-modal {
  padding-bottom: 0;

  .ant-modal-body {
    padding: 30px 0;
  }

  .ant-select-drop-list {
    .litem {
      &:first-child {
        border-bottom: 1px solid #e7e8eb;
      }
    }
  }

  .activity-history {
    margin-top: 32px;
    border-top: 1px solid #e7e8eb;
    display: flex;

    .leftbar {
      max-width: 405px;
      overflow-x: hidden;
      border-right: 1px solid #e7e8eb;
      flex: auto;
      width: 405px;
      min-width: 405px;

      .primary-link {
        padding: 12px;
      }

      .infinite-scroll-component {
        overflow: hidden !important;
      }

      hr {
        margin: 0 -25px 0 auto;
        width: 325px;
      }
    }

    .detailedbar {
      flex: 1;
    }

    .active-line {
      position: relative;

      &:before {
        content: '';
        border-radius: 0 100px 100px 0;
        background-color: rgba(84, 99, 210, 1);
        position: absolute;
        height: 100%;
        width: 4px;
      }
    }

    .msglist-cell {
      padding: 25px;
      display: flex;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 80px;
        width: 2px;
        background: $hr;
        left: 45px;
        top: 72px;
      }
    }

    .msgprofile {
      .ant-avatar {
        width: 40px !important;
        height: 40px !important;
      }
    }

    .msgcell {
      .uesrmsg {
        strong {
          color: $secondary;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 5px;
          font-weight: 600 !important;
        }

        svg {
          color: $grey-clr;
          font-size: 11px;
          letter-spacing: 0;
          line-height: 11px;
          text-align: center;
          margin-right: 5px;
        }

        small {
          font-size: 12px;
        }
      }

      &.active-line {
        strong {
          color: $primary;
        }
      }
    }
  }

  .detailedbar {
    padding: 25px 35px;
    color: rgba(17, 23, 62, 0.84);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;

    h3 {
      text-transform: capitalize;
      display: block;
    }

    .mlabel {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      color: $grey-link;
      margin-bottom: 10px;
      opacity: 0.74;
    }

    .mdetail {
      margin-bottom: 15px;
    }
  }

  .ant-modal-content {
    height: calc(100vh - 80px);
    overflow: hidden;
  }
}

.ant-spin {
  // color: $white !important;
  color: #5160cc !important;

  .anticon-loading {
    font-size: 18px !important;
    margin-right: 2px;
  }
}

.spin-load {
  opacity: 0.7;
}

.pdfshow {
  display: none;
}

.normal-text {
  display: none;
}

.checkbox-cover {
  .d-flex {
    @extend .d-flex;
  }
}

.antd-check {
  label {
    height: 25px;

    .ant-checkbox-wrapper {
      &:after {
        height: auto;
      }
    }
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }
}

.historical-data {
  .litem {
    margin-left: 0 !important;
    width: 100%;
    margin-bottom: 15px;

    .ant-checkbox + span {
      padding-right: 12px;
      padding-left: 12px;
      font-weight: 500;
    }

    .ant-checkbox.ant-checkbox-checked + span {
      font-weight: 600;
    }
  }

  .full-checkbox {
    width: 100%;
    border-bottom: 1px solid #e7e8eb;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  .checkboxlist {
    display: block;
    column-count: 2;
    margin-top: 10px;
  }
}

.noExport {
  .ant-filterbtn.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-filterbtn .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 45px;
  }
}

.ah-pdf {
  .msgprofile {
    .ant-avatar {
      font-size: 24px !important;
      font-weight: bold !important;
    }

    .ant-avatar-string {
      line-height: 25px !important;
      font-weight: bold !important;
      transform: none !important;
      font-size: 13px !important;
      position: relative;
      left: 1px;
      top: -3px;
    }
  }

  .blue-txt {
    color: $primary;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .grey-txt {
    color: $secondary;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .icdetail {
    font-size: 14px;
  }

  .stackcell {
    font-size: 13px !important;
  }

  .stackcell + div {
    font-size: 13px !important;
  }

  h2,
  h3 {
    font-size: 20px !important;
    margin: 0 !important;
  }
}

.stack-data {
  display: inline-block;
  column-count: 2;
  width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid #ccc;

  padding-bottom: 20px;
}

.stackcell {
  margin-top: 10px;
  font-size: 18px !important;
  line-height: 18px !important;
  color: #000;
}

// component loader
.component-loader {
  position: fixed !important;
  display: flex !important;
  margin: 0 auto !important;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}

// .claim-numbers .scard {
//     transform: translate(0,0);
// }
.component-loader-parent {
  transform: translate(0, 0);
}

.component-loader-relative {
  position: relative;

  .component-loader,
  .global-loader {
    position: absolute !important;
  }
}

/*Account Information*/
.info-row {
  border-bottom: 1px solid #e7e8eb;
  padding: 15px 0;
  justify-content: flex-start;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .info-val {
    color: $secondary;
    font-weight: 500;

    img {
      height: 125px;
      width: 125px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .info-lbl {
    flex: 1;
    max-width: 280px;
    color: $grey-link;
  }
}

.flefitem {
  transform: translate(0, 0);

  .upload-container {
    height: 195px;
    width: 245px;
    border: 1px solid rgba(28, 32, 60, 0.1);
    border-radius: 4px;
    background-color: #f4f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    flex-direction: column;
    cursor: pointer;
  }

  // .file-btn {
  //     position: fixed;
  //     left: 0;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     align-items: center;
  //     justify-content: center;
  //     display: flex;
  // }
  .imgbox {
    border-radius: 100%;
    height: 130px;
    width: 130px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      border-radius: 100%;
      height: 130px;
      width: 130px;
      object-position: center;
      object-fit: cover;
    }
  }

  .upload-file {
    &.no-btn {
      color: $primary;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      height: auto;
      width: auto;
      border: none;
    }
  }

  .btncontainer {
    position: absolute;
    right: -255px;
    top: 0;
    display: flex;
    flex-direction: column;
  }
}

.upload-stepss {
  display: flex;
  align-items: center;
  justify-content: center;

  .uploading {
    font-size: $font-size-base-sm;
    color: $primary;
  }

  .file-box {
    height: 18px;

    .upload-file.btn {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 !important;
      height: 32px;
      padding: 6px 10px !important;
      width: 245px;
      font-size: 12px !important;

      svg {
        font-size: 12px !important;
      }
    }
  }

  input {
    position: absolute;
    z-index: 9;
    opacity: 0;
    width: 245px;
    cursor: pointer;
    left: 0;
    right: 0;
    width: 100%;
  }

  .ant-btn.lg-btn {
    height: 32px !important;
    padding: 6px 10px !important;
    width: 245px !important;
    font-size: 12px !important;
    margin-bottom: 15px !important;
  }

  input {
    position: absolute;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.reset-pass {
  @include lists;

  svg {
    color: $grey-clr;
  }
}

.ppage-block {
  display: flex;
}

/*Search dropdown*/
.search-dropdown {
  border: 1px solid #e7e8eb;
  border-radius: 4px;

  .ant-dropdown-menu {
    box-shadow: none !important;
    padding: 0 !important;

    > .ant-dropdown-menu-item {
      &:hover,
      &:focus {
        background-color: transparent !important;
      }
    }
  }

  .search-block {
    border-bottom: 1px solid #e7e8eb;
    padding: 15px 0;

    small {
      margin-bottom: 10px;
      line-height: 18px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .primary-link {
      padding: 15px 15px 0 0;

      svg {
        color: $primary;
        font-size: 12px;
        margin-left: 5px;
      }

      &:hover,
      &:focus {
        svg {
          color: $secondary;
        }
      }
    }

    .search-row .srow .primary-link {
      padding: 15px 15px 0 0;
    }
  }

  .btn-container {
    display: block;
    padding: 15px;
  }

  .search-row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 5px;

    svg {
      color: $primary;
      font-size: 11px;
      margin-right: 10px;
    }

    .srow {
      color: $secondary;
      font-weight: 600;
      width: 100%;
      padding: 2px 15px;
      display: inline-block;
      white-space: normal;
      line-height: 25px;

      span {
        display: inline-block;
        word-break: break-word;
        white-space: normal;
        list-style: 23px;
        vertical-align: middle;
      }

      &:hover,
      &:focus {
        background: #e3e6f8;
      }

      .htext {
        color: $grey-link;
        font-weight: 400;
      }

      .sbind {
        max-width: calc(100% - 50px);
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &.ant-dropdown {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      .ant-btn {
        svg {
          font-size: 13px;
          color: $white;
          margin-left: 10px;
        }

        &:hover,
        &:focus {
          svg {
            color: $primary;
          }
        }
      }
    }
  }

  .scrollbar-track-y {
    &.show {
      display: block !important;
    }
  }
}

.global-search {
  .liens-table {
    .ant-table-thead {
      th,
      td {
        padding: 15px !important;
      }

      th {
        color: $color-base;
        font-weight: 600;
      }
    }
  }
}

.stags {
  padding-left: 35px;
  display: inline-block;
  white-space: normal;
  padding-right: 20px;

  .stag-li {
    border: 1px solid #ced3f9;
    border-radius: 12px;
    background-color: #f3f4fc;
    padding: 0px 8px;
    margin: 5px 5px 5px 0;
    color: $secondary;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
    vertical-align: middle;
  }
}

.ant-table {
  .stags {
    display: inline-block;
    padding: 0;
  }

  .tnme {
    width: 200px !important;
    max-width: 200px;
    min-width: 200px !important;
  }

  .tnum {
    width: 150px !important;
    max-width: 150px;
    min-width: 150px !important;
  }

  td.ant-table-cell.tmatch {
    width: 250px;
    max-width: 250px;
    min-width: 250px;
  }
}

/*404 page*/
.page-not-found {
  text-align: center;

  img {
    margin: 0 auto 30px;
  }

  p {
    font-size: 13px;
  }
}

.LinesEllipsis-canvas {
  position: relative !important;
}

.associated .css-1hb7zxy-IndicatorsContainer > .css-tlfecz-indicatorContainer,
.associated .css-1hb7zxy-IndicatorsContainer > .css-1gtu0rj-indicatorContainer,
.authentication .css-1wy0on6 > .css-tlfecz-indicatorContainer,
.authentication .css-1hb7zxy-IndicatorsContainer > .css-tlfecz-indicatorContainer,
.authentication .css-1wy0on6 > .css-tlfecz-indicatorContainer,
.authentication .css-1wy0on6 .css-1gtu0rj-indicatorContainer > .css-1okebmr-indicatorSeparator,
.authentication .css-1wy0on6 > .css-1gtu0rj-indicatorContainer,
.associated .css-1wy0on6 > .css-1gtu0rj-indicatorContainer,
.associated .css-1wy0on6 > .css-tlfecz-indicatorContainer {
  display: none;
}

.associated
  .css-1hb7zxy-IndicatorsContainer
  > .css-1okebmr-indicatorSeparator
  + .css-tlfecz-indicatorContainer,
.associated
  .css-1hb7zxy-IndicatorsContainer
  > .css-1okebmr-indicatorSeparator
  + .css-1gtu0rj-indicatorContainer,
.associated .css-1hb7zxy-IndicatorsContainer > .css-1okebmr-indicatorSeparator + .css-1wy0on6,
.authentication
  .css-1hb7zxy-IndicatorsContainer
  > .css-1okebmr-indicatorSeparator
  + .css-tlfecz-indicatorContainer,
.authentication
  .css-1hb7zxy-IndicatorsContainer
  > .css-1okebmr-indicatorSeparator
  + .css-1gtu0rj-indicatorContainer,
.associated
  .css-1wy0on6
  > .css-tlfecz-indicatorContainer
  + .css-1okebmr-indicatorSeparator
  + .css-tlfecz-indicatorContainer,
.authentication
  .css-1wy0on6
  > .css-tlfecz-indicatorContainer
  + .css-1okebmr-indicatorSeparator
  + div,
.associated
  .css-1wy0on6
  > .css-1gtu0rj-indicatorContainer
  + .css-1okebmr-indicatorSeparator
  + .css-1gtu0rj-indicatorContainer {
  display: block;
}

.authentication {
  .tdown .css-1fhf3k1-control,
  .tdown .css-yk16xz-control,
  .timezone .css-1fhf3k1-control,
  .timezone .css-yk16xz-control {
    min-height: 40px !important;
  }
}

.form-group .overflow-multiselect.associated .ant-select-selector:after,
.form-group .overflow-multiselect .css-g1d714-ValueContainer:after,
.form-group .overflow-multiselect .css-1hwfws3:after {
  top: 0;
}

.messenger-block {
  .scrollblock {
    max-height: calc(30vh - 50px) !important;

    @media (max-height: 700px) {
      max-height: calc(45vh - 50px);
    }

    @media (max-height: 650px) {
      max-height: calc(34vh - 50px);
    }
  }
}

.neg-summary {
  .ellipsis-text {
    width: 95%;
    vertical-align: middle;
  }
}

.tfoot-head {
  text-align: left;
  color: rgba(17, 23, 62, 0.9);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;

  svg {
    color: #868994;
  }
}

.tfoot-cell {
  span {
    white-space: nowrap;
    font-size: 12px;
    text-align: right;
  }

  h5 {
    text-align: right;
    margin-bottom: 0 !important;

    span {
      color: #5463d2;
    }
  }

  &.ellipsis-text {
    width: unset;
    display: revert;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }
}

.damages-tbl {
  .tfoot-head {
    span {
      // left: -60px;
      position: relative;

      @media (max-width: 1699px) {
        // left: -45px;
      }
    }
  }
}

.txt-editor blockquote,
.txt-editor h1,
.txt-editor h2,
.txt-editor h3,
.txt-editor h4,
.txt-editor h5,
.txt-editor h6,
.txt-editor ol,
.txt-editor p,
.txt-editor pre,
.txt-editor ul {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 1360px) {
  .ant-table-content {
    overflow-x: scroll;
  }
}

.fixed-tooltip {
  .g2-tooltip {
    width: 305px;
  }
}

.syz-head {
  display: inline-block;
  vertical-align: middle;

  img {
    margin-right: 10px;
  }

  img,
  h3 {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0 !important;
  }

  &.normal-text {
    display: none;
  }
}

// create task clear icon
.ant-picker .ant-picker-clear svg {
  font-size: 13px;
}

.ant-picker .ant-picker-clear {
  right: 17px !important;
}

.ant-tabs {
  overflow: unset !important;

  .card-sm-col {
    position: sticky;
    top: 80px;
  }
}

.sort-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-row:nth-child(2) {
  margin-bottom: 0;
}

.ant-notification {
  .ant-notification-notice-close {
    top: 8px;
  }

  .anticon-close {
    background-image: url('../assets/images/close.png');
    height: 12px;
    width: 12px;
    background-repeat: no-repeat;
    background-size: 9px;
    margin-top: 0;

    svg {
      display: none;
    }
  }
}

input[type='text'].plain-input {
  border: none !important;
  background: transparent;
}

.ellipses-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  display: inline-block;
  overflow: hidden;
}

.percentage .g2-html-annotation {
  font-size: 14px !important;
}

.plain-text-input b {
  height: 35px;
  align-items: center;
  display: flex;
}

@media (max-width: 1366px) {
  .damages-tbl .ant-table-tbody > tr > td.cvalue .ellipsis-text,
  .damages-tbl .tfoot-cell .ellipsis-text {
    max-width: 109px;
  }
}

.damages-tbl .ant-table-tbody > tr > td.cvalue {
  padding-left: 5px !important;
}

.ant-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: transparent !important;
}

.action-row {
  .ant-btn {
    svg {
      color: #5463d2;
    }
  }
}

.ant-drawer-header-close-only {
  padding: 0;
  border: none;
}

.ant-drawer .ant-drawer-close {
  top: 0;
  right: 0;
}

.ddown {
  .ant-select-selection-item {
    font-weight: bold;
  }
}

.text-cap {
  text-transform: capitalize;
}

// for ant new version
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block !important;
}

// Inner scroll in modal

.ant-modal-wrap {
  overflow: hidden;

  .ant-modal-body {
    overflow: auto;
    max-height: calc(100vh - 325px);
    // margin-bottom: 20px;
    padding-bottom: 0px;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d0d0d0;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #d0d0d0;
    }
  }

  .activity-history-modal {
    .ant-modal-body {
      max-height: calc(100vh - 155px);
    }
  }

  .ant-modal {
    padding-bottom: 0;
  }
}

[overlayClassName='b-subdropdown'] {
  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }

  .ant-dropdown-menu-item-icon {
    position: absolute !important;
    right: 0 !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  .fa-chevron-right {
    color: #5463d2 !important;
    font-size: 14px !important;
  }
}

.ant-dropdown-menu-title-content {
  font-weight: 500;
}

.page-padding {
  .ant-card-body {
    padding-bottom: 0;
  }
}

.prl-0 {
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.disabled-span {
  cursor: not-allowed;
}

// error page style
.error-main {
  padding: 79px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-img {
  display: inline-block;
  vertical-align: middle;
}

.pointer {
  cursor: pointer;
}

.sidebar-icon-span {
  display: inline-block;
  vertical-align: middle;
}

.edit-person-modal {
  .ant-alert {
    max-width: 100%;
  }
}

.error-filed {
  .ant-select {
    box-shadow: 0px 0px 1px 3px rgba(216, 53, 53, 0.15);
    border: 1px solid rgba(216, 53, 53, 1);
    border-radius: 4px;
  }
}

.side-logos {
  color: #868994 !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding: 60px 20px 10px 20px;
  cursor: pointer;

  .ant-btn {
    padding: 6px 10px !important;

    &:hover {
      background-color: #434fa8;
      color: #fff;
    }

    svg {
      &:hover {
        color: #fff !important;
      }
    }
  }

  .wordplay {
    color: #868994;
    font-weight: 500;
    text-align: right;
    line-height: 20px;
    font-size: 14px;
    padding-right: 15px;
    transition: all 0.3s ease-in-out;
  }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 20px 0 !important;
  padding-left: 24px !important;
}

/*Sidebar*/
.off-sidebar {
  flex: 0 0 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
  width: 70px !important;

  .footer-links {
    display: none;
  }

  .side-logos {
    svg {
      &:hover {
        color: $primary;
      }
    }

    .wordplay {
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }
  }

  .ant-menu-sub {
    display: none;
  }
}

.on-sidebar {
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;

  .ant-menu-root {
    .quicklink {
      .ant-menu-item {
        padding-left: 70px !important;
        padding-right: 10px;
      }
    }
  }

  .side-logos {
    text-align: right;

    svg {
      transform: rotate(-180deg);

      &:hover {
        color: $primary;
      }
    }
  }
}

.tag-hide {
  .ant-select-selection-item {
    opacity: 0;
  }
}

.triangleexclamation-img {
  color: inherit;
  max-width: 12px;
}

.internal-block {
  .ant-card-head,
  .ant-card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-card-head,
  .ant-card-body {
    padding-top: 0 !important;
  }
}

.closing-info {
  .cheading {
    max-width: 80%;
  }
}

.crs {
  .ant-dropdown-trigger {
    position: relative;
    z-index: 11;

    svg {
      + div {
        right: 0 !important;
        top: 100% !important;
        left: auto !important;
        min-width: 266px;
        margin-top: 10px;
      }
    }

    .ant-dropdown {
      left: 0 !important;
      top: 0 !important;
      min-width: 100% !important;
    }
  }
}

.primary-select {
  color: $primary;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  line-height: 16px;
  margin-right: auto;
}

.nodata-space {
  .emptydata {
    margin: 30px 0;
  }
}

#root {
  min-width: 1200px;
}

.lk-btn {
  margin-left: auto;
}

.ant-drawer-body {
  .ant-alert-description {
    > span {
      display: block;
    }
  }
}

@media (max-width: 1280px) {
  .task-req-tbl {
    .treqcell {
      &.alcell {
        width: 150px;
        min-width: 150px;
      }

      &.trname {
        max-width: 167px;
        width: 30%;
      }
    }
  }
}

.ant-filterbtn.white.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  background-color: transparent !important;
}

.card-data {
  .ant-card-body {
    display: none;
  }
}

.crtmain {
  position: relative;
  // margin-top: 5em;
}

.css-14jk2my-container {
  pointer-events: revert !important;
  cursor: not-allowed;

  .css-1fhf3k1-control {
    cursor: not-allowed;
    background: #e7e8eb !important;
  }
}

.quillData {
  height: 10em;
  min-height: 100%;
  overflow-y: auto;
  margin-top: 3em;
}

.quillheight {
  .ql-container {
    .ql-editor {
      max-height: calc(100vh - 441px);
      overflow: auto;
    }
  }
}

.form-group {
  .ant-select-arrow {
    top: 10px !important;
  }
}

#scrollableDiv,
#scrollableDivMatter,
#scrollableDivSystem,
.ant-dropdown-menu .ant-list {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 3px;
    background: transparent;
    opacity: 1;
    cursor: pointer;
    z-index: 99;
    margin: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d0d0d0;
    height: 3px;
    width: 3px;
    margin: 0;
  }
}

.pdf-frndly {
  font-size: 10px;
  color: #a1a1a1;
  margin-left: 4px;
  display: inline-block;
}

.ant-modal-wrap {
  .foot-pad {
    .ant-modal-body {
      margin-bottom: 10px;
    }
  }
}

.matter-sort {
  .ant-col {
    width: 100%;
  }

  .ant-select {
    width: 100% !important;
  }
}

.claimname.flex {
  max-width: 380px;
}

.ant-table-summary {
  td {
    &:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 11;
      background-color: #fff;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: -1px;
        width: 30px;
        transform: translateX(100%);
        transition: box-shadow 0.3s;
        content: '';
        pointer-events: none;
      }
    }
  }
}

.ant-table-ping-left {
  .ant-table-summary {
    td {
      &:first-child {
        &:after {
          box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

.ant-table {
  tfoot.ant-table-summary {
    > tr {
      > td {
        padding: 4px 12px;
        border-top: 1px solid #e7e8eb;

        &:first-child {
          padding-left: 24px !important;
        }
      }
    }
  }
}

.ant-select-dropdown {
  min-width: 150px !important;
}

// document selection closable solution
.ant-select-selection-overflow-item {
  .ant-tag-hidden {
    display: block !important;
  }
}

.w-140 {
  width: 140px;
}

.bg-trans,
.ant-modal-content .ant-modal-body .bg-trans {
  padding: 3px 7px !important;
  height: 24px !important;
  width: 24px !important;
  text-align: center;
  color: #868994 !important;
  background-color: $white !important;
  border: 1px solid #d0d0d0 !important;
  font-size: 10px !important;
  margin: 1px 1px !important;
}

.w-10p {
  min-width: 10% !important;
  width: 10% !important;
  max-width: 10% !important;
}

.w-20p {
  min-width: 20% !important;
  width: 20% !important;
  max-width: 20% !important;
}

.w-30p {
  min-width: 30% !important;
  width: 30% !important;
  max-width: 30% !important;
}

.w-40p {
  min-width: 40% !important;
  width: 40% !important;
  max-width: 40% !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.justify-content-end {
  justify-content: end;
}

// claim score drawer
.border-warning {
  border: 1px solid #fbc800;
  background: #fefbed;
  min-height: 70px;
}

.ant-table-tbody > tr > td:has(div.warn-field) {
  padding: 0px !important;
  line-height: 0px !important;
}

.highlight-switch {
  display: inline-block;
  margin-left: 15px;
  position: relative;
  padding-left: 15px;
}

.highlight-switch:after {
  content: '';
  position: absolute;
  background-color: rgba(1, 24, 98, 0.1);
  left: 0px;
  height: 22px;
  width: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ml-5 {
  margin-left: 5px;
}

.f-20 {
  font-size: 20px;
}

.page-tab-detail .claim-score-drawer .ant-tabs-nav {
  margin-top: 30px;
  margin-left: -24px;
}

.claim-drawer {
  .ant-drawer-body {
    .ant-table-wrapper {
      margin: 0px -24px;
      max-width: initial;
    }
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .claim-score-hr {
    margin: 0px -24px !important;
  }
}

.claim-page-drawer {
  .ant-table-wrapper {
    // margin: 0px -24px;
    max-width: initial;
    margin: -2px -57px -41px -31px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .claim-score-hr {
    // margin: 0px -24px!important;

    margin: 14px -68px 3px -31px;
  }
}

.white-normal {
  white-space: normal !important;
}

.matter-left-cap {
  display: flex;
  align-items: center;
}

.bulk {
  .css-1rhbuit-multiValue {
    border: 1px solid #ced3f9 !important;
    border-radius: 12px !important;
    background-color: #f3f4fc !important;
    color: rgba(17, 23, 62, 0.84) !important;
    line-height: 20px !important;
    font-weight: 500;
    margin-top: 0px !important;
  }

  .css-1hb7zxy-IndicatorsContainer,
  .css-1wy0on6 {
    .css-tlfecz-indicatorContainer
      + .css-1okebmr-indicatorSeparator
      + .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer
      + .css-1okebmr-indicatorSeparator
      + .css-1gtu0rj-indicatorContainer {
      display: none;
    }
  }
}

.bulk-list {
  .ant-select-drop-list .action-row {
    padding: 10px 10px 5px;
    position: absolute;
    bottom: 0;
    background: #fff;
    text-align: right;
    border-top: 1px solid #e7e8eb;
    right: 0;
    left: 0;
    width: 100%;
  }

  .bulk-drop {
    height: 200px;
    overflow-y: scroll;
  }
}

.pr-12 {
  padding-right: 12px !important;
}

.ndot.ncount {
  height: 17px;
  min-width: 16px;
  color: #fff;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  border-radius: 50px;
  padding: 5px;
}

.mh-56 {
  max-height: 56px;
}

/*Claim Score*/
.cnt-days {
  .ant-input-affix-wrapper {
    padding-right: 5px;
    background-color: rgba(17, 23, 62, 0.05);

    > input.ant-input {
      background-color: transparent;
    }
  }
}

/*Trial Expire*/
.trial-expiry {
  background-color: #001529;
  color: #fff;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  padding: 5px 15px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.on_trial {
  .top-notification {
    .header {
      margin-top: 25px;
    }
  }

  .remove-top-space {
    margin-top: 25px !important;
  }

  .ant-layout-sider {
    top: 95px;
  }
}

.docmodal {
  .ellipses-text {
    line-height: 20px;
  }
}
