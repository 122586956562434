$color-base: rgba(17, 23, 62, 0.9);
$primary: #5463d2;
$brand-color: #5463d2;
$secondary: rgba(17, 23, 62, 0.84);
$white: #ffffff;
$sub-heading: rgba(17, 23, 62, 1);
$grey: #fafafb;
$danger: #d83535;
$success: #168f25;
$overlay: rgba(17, 23, 62, 0.3);
$disable: #f4f4f6;
$hr: #e7e8eb;
$grey-link: rgba(17, 23, 62, 0.74);
$grey-clr: #70748b;
$secondary-gray: #868994;
$secondary-white: rgba(255, 255, 255, 0.74);
$font-size-base: 14px;
$font-size-base-sm: 12px;

$font-family-base: 'Montserrat', 'Arial';
$heading-margin-bottom: 15px;
$h1-font-size: 28px;
$h1-line-height: 30px;
$h2-font-size: 24px;
$h2-line-height: 26px;
$h3-font-size: 20px;
$h3-line-height: 22px;
$h4-font-size: 16px;
$h4-line-height: 18px;
$h5-font-size: 14px;
$h5-line-height: 18px;

$btn-font-size: 14px;
$btn-font-size-sm: 12px;
$btn-padding: 6px 12px;
$btn-color: $white;

$btn-brand-bg: $brand-color;
$btn-brand-color: $white;

$btn-danger-bg: $danger;
$btn-danger-color: $white;

$btn-grey-bg: $disable;
$btn-grey-color: $color-base;

$col-count: 2;
