@font-face {
    font-family: "Arial";
    src: url("../assets/fonts/ArialLight.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "Arial";
    src: url("../assets/fonts/Arial.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "Arial";
    src: url("../assets/fonts/Arial.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "Arial";
    src: url("../assets/fonts/Arial.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "Arial";
    src: url("../assets/fonts/ArialBold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "Arial";
    src: url("../assets/fonts/ArialBold.ttf") format("truetype");
    font-weight: 900;
}